import React, { useEffect, useState } from "react"
import Rails from "../util/Rails"
import { TextInput, Select, LabeledInput, FieldSet } from "./Modal"
import { v4 as uuid } from "uuid"
import { CardEditor } from "./CardsEditor"

// fetch(`/rh/sites/${site_id}/decks.json` "POST"
// fetch(`/rh/decks/${section.component_id}.json` "PATCH"

export const getDecks = async ({ siteId }) => {
  const response = await fetch(`/rh/sites/${siteId}/decks.json`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": Rails.csrfToken(),
    },
  })
  if (response.ok) return await response.json()
  return []
}

export const getDeck = async ({ id }) => {
  const response = await fetch(`/rh/decks/${id}.json`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": Rails.csrfToken(),
    },
  })
  if (response.ok) return await response.json()
  return {}
}

export const createDeck = async ({ siteId, body = {} }) => {
  const response = await fetch(`/rh/sites/${siteId}/decks.json`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": Rails.csrfToken(),
    },
    body: JSON.stringify(body),
  })
  if (response.ok) return await response.json()
  return {}
}

export const updateDeck = async ({ id, body = {} }) => {
  const response = await fetch(`/rh/decks/${id}.json`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": Rails.csrfToken(),
    },
    body: JSON.stringify(body),
  })
  console.log(response.ok, response)
  if (response.ok) return await response.json()
  return {}
}

export function ChooseDeck({ setComponent, siteId, setCloseWarning }) {
  const [decks, setDecks] = useState([])
  const [editingDeck, setEditingDeck] = useState({ id: null, name: "" })
  const component = {
    key: uuid(),
    status: "draft",
    base_status: 0,
    component_type: "Deck",
    published_at: null,
    expired_at: null,
    component_id: null,
  }

  const setUp = async () => {
    const decks = await getDecks({ siteId })
    setDecks(decks)
  }
  const onSelectChange = ({ id }) => {
    setEditingDeck({ id, name: decks.find((x) => `${x.id}` === `${id}`)?.name || null })
  }
  const onTextChange = (t) => {
    setEditingDeck((prev) => {
      return { ...prev, name: t }
    })
  }
  const onSubmit = async () => {
    const response = await createDeck({ siteId, body: { name: editingDeck.name } })
    setEditingDeck((prev) => {
      return { ...prev, ...response }
    })
  }

  useEffect(() => {
    if (editingDeck.id) {
      setComponent({
        ...component,
        component_id: editingDeck.id,
        ...(editingDeck.id && { component: { id: editingDeck.id } }),
      })
    } else {
      setComponent(null)
    }
  }, [editingDeck])

  useEffect(() => {
    setUp()
    setCloseWarning("Discard Changes?")
  }, [])

  return (
    <>
      <LabeledInput label="choose a deck">
        <Select
          options={[
            { value: "", label: "Create New" },
            ...decks.map((c) => {
              return { value: c.id, label: c.name || `Deck ${c.id}` }
            }),
          ]}
          onChange={(e) =>
            onSelectChange({
              id: e.currentTarget.value,
            })
          }
        />
      </LabeledInput>
      <div className="flex flex-grow flex-wrap items-end gap-2">
        {editingDeck.id ? (
          <div className="flex flex-grow flex-col">
            <div className="inline-flex items-center gap-2">
              <span className="p-1 font-bold capitalize">Deck name:</span>
            </div>
            <div className="flex flex-nowrap items-center justify-start gap-2 rounded-md bg-black/20 p-2.5 text-center font-mono text-sm uppercase">
              {editingDeck.name || `Deck ${editingDeck.id}`}
            </div>
          </div>
        ) : (
          <>
            <LabeledInput label="deck name" stretch>
              <TextInput
                value={editingDeck.name || ""}
                onChange={(e) => onTextChange(e.currentTarget.value)}
              />
            </LabeledInput>
            <button
              type="button"
              disabled={!editingDeck.name}
              className="button py-2"
              onClick={onSubmit}
            >
              Create
            </button>
          </>
        )}
      </div>
    </>
  )
}

export function AddDeckPropertiesToSection({ properties, setProperties }) {
  const options = [{ value: "left-big-stacker", label: "Left Big Stacker" }]
  useEffect(() => {
    const name = properties?.name || "left-big-stacker"
    setProperties({ name })
  }, [])
  return (
    <FieldSet legend="Section Properties">
      <div className="flex flex-wrap gap-4">
        <LabeledInput label="Renderer Name">
          <Select
            options={options}
            onChange={(e) => {
              setProperties({
                ...properties,
                name: e.currentTarget.value,
              })
            }}
            value={properties?.name || options[0]}
          />
        </LabeledInput>
      </div>
    </FieldSet>
  )
}

export default function DeckEditor({
  deckId,
  imageTags = [],
  setCloseWarning,
  setOnConfirmClickDisabled,
}) {
  const [deck, setDeck] = useState(null)
  const [dirty, setDirty] = useState(false)

  const setUp = async () => {
    const deck = await getDeck({ id: deckId })
    setDeck(deck)
  }
  const onTextChange = (t) => {
    setDeck((prev) => {
      return { ...prev, name: t }
    })
    setDirty(true)
    setCloseWarning("Discard Changes?")
    setOnConfirmClickDisabled(true)
  }
  const onSubmit = async () => {
    const c = await updateDeck({
      id: deckId,
      body: {
        name: deck.name,
        cards_attributes: deck.cards.map(({ key, status, images = [], ...s }) => JSON.stringify(s)),
      },
    })
    if (c) {
      setDeck(c)
      setDirty(false)
      setCloseWarning("Discard Changes?")
      setOnConfirmClickDisabled(false)
    }
  }
  const onChange = (cardState) => {
    if (deck) {
      setDirty(true)
      setCloseWarning("Discard Changes?")
      setOnConfirmClickDisabled(true)
    }
    setDeck((prev) => {
      return {
        ...deck,
        cards: cardState,
      }
    })
  }

  useEffect(() => {
    setUp()
  }, [])

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex gap-4 text-lg font-semibold capitalize">
        <LabeledInput label="deck name" stretch>
          <TextInput
            value={deck?.name || ""}
            onChange={(e) => onTextChange(e.currentTarget.value)}
          />
        </LabeledInput>
      </div>
      <div className="w-full">
        {deck?.cards && <CardEditor cards={deck.cards} imageTags={imageTags} onChange={onChange} />}
      </div>
      <div>
        {dirty ? (
          <button type="button" className="button py-2" onClick={onSubmit}>
            Update Deck
          </button>
        ) : (
          <span className="text-base">Deck up to date!</span>
        )}
      </div>
    </div>
  )
}
