import React, { useState, useEffect, useRef, forwardRef, MutableRefObject } from "react"
import { AnimatePresence, motion, MotionValue, useMotionValue, useTransform } from "framer-motion"
import { ErrorBoundary } from "react-error-boundary"
import { Details } from "./Modal"

export default function ImageCropper({ image, setImage, breakpoint, ...props }) {
  const [source, setSource] = useState<ImageSource>({
    ...image?.sources[breakpoint],
    rect: {
      x: image?.sources[breakpoint]?.rect?.x || image?.sources[breakpoint]?.x || 0,
      y: image?.sources[breakpoint]?.rect?.y || image?.sources[breakpoint]?.y || 0,
      w:
        image?.sources[breakpoint]?.rect?.w || image?.sources[breakpoint]?.w || image?.width || 100,
      h:
        image?.sources[breakpoint]?.rect?.h ||
        image?.sources[breakpoint]?.h ||
        image?.height ||
        100,
    },
  })

  const ref = useRef(null)

  const [scale, setScale] = useState(1)

  const x = useMotionValue(source?.rect?.x)
  const y = useMotionValue(source?.rect?.y)
  const w = useMotionValue(source?.rect?.w)
  const h = useMotionValue(source?.rect?.h)

  const updateSource = (rect: Dimensions) => {
    setSource((prev) => ({
      ...prev,
      rect,
    }))
  }

  useEffect(() => {
    setImage((prev) => ({
      ...prev,
      sources: {
        ...prev?.sources,
        [breakpoint]: source,
      },
    }))
  }, [source])

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-grow gap-4">
        <motion.div className="flex flex-grow flex-col items-center justify-center gap-4 pt-2">
          <motion.div className="checkerboard relative overflow-hidden ring-1 ring-black/30">
            <img
              src={image.url}
              ref={ref}
              style={{
                maxHeight: 500,
                maxWidth: 500,
                width: "100%",
              }}
              alt=""
              className="pointer-events-none object-contain"
              onLoad={() => {
                setScale(ref.current?.getBoundingClientRect()?.width / (image?.width || 1))
              }}
            />
            <div className="absolute inset-0">
              <Panel
                key={breakpoint}
                ref={ref}
                name={breakpoint}
                scale={scale}
                x={x}
                y={y}
                w={w}
                h={h}
                maxDimensions={{
                  x: 0,
                  y: 0,
                  w: image?.width || 500,
                  h: image?.height || 500,
                }}
                initialDimensions={source.rect}
                onChange={updateSource}
              />
            </div>
          </motion.div>
        </motion.div>
        <div className="h-72 flex-none">
          <Details summary="Advanced Options">
            <motion.div
              className="flex flex-col"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
            >
              <motion.div key={breakpoint} className="flex flex-col gap-4">
                <div className="flex gap-2">
                  <div className="font-bold">{breakpoint}px</div>
                  <div>
                    <span>Aspect Ratio: </span>
                    <span className="font-normal">
                      {(Number(source.rect.w / source.rect.h) || 0).toFixed(3)}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <label className="flex items-center gap-2">
                    <span className="block w-4 flex-none">x:</span>{" "}
                    <input
                      type="number"
                      className="rhx-input"
                      value={source.rect.x}
                      onChange={(e) => {
                        return setSource((prev) => ({
                          ...prev,
                          x: Number(e.target.value),
                        }))
                      }}
                    />
                  </label>
                  <label className="flex items-center gap-2">
                    <span className="block w-4 flex-none">y:</span>{" "}
                    <input
                      type="number"
                      className="rhx-input shrink"
                      value={source.rect.y}
                      onChange={(e) => {
                        setSource((prev) => ({
                          ...prev,
                          rect: {
                            ...prev.rect,
                            y: Number(e.target.value),
                          },
                        }))
                      }}
                    />
                  </label>
                  <label className="flex items-center gap-2">
                    <span className="block w-4 flex-none">w:</span>{" "}
                    <input
                      type="number"
                      className="rhx-input shrink"
                      value={source.rect.w}
                      onChange={(e) => {
                        setSource((prev) => ({
                          ...prev,
                          rect: {
                            ...prev.rect,
                            w: Number(e.target.value),
                          },
                        }))
                      }}
                    />
                  </label>
                  <label className="flex items-center gap-2">
                    <span className="block w-4 flex-none">h:</span>{" "}
                    <input
                      type="number"
                      className="rhx-input "
                      value={source.rect.h}
                      onChange={(e) => {
                        setSource((prev) => ({
                          ...prev,
                          rect: {
                            ...prev.rect,
                            h: Number(e.target.value),
                          },
                        }))
                      }}
                    />
                  </label>
                </div>
              </motion.div>
            </motion.div>
          </Details>
        </div>
      </div>
      <input
        className="rhx-input"
        readOnly
        value={`${image.url}${image.url.includes("?") ? "&" : "?"}rect=${[
          image?.sources[breakpoint]?.rect?.x,
          image?.sources[breakpoint]?.rect?.y,
          image?.sources[breakpoint]?.rect?.w,
          image?.sources[breakpoint]?.rect?.h,
        ].join(",")}`}
      />
    </div>
  )
}

const clamp = (value, min, max) => Math.min(Math.max(value, min), max)

const Panel = forwardRef(
  (
    {
      name,
      scale = 1,
      x,
      y,
      w,
      h,
      initialDimensions = { x: 0, y: 0, w: 100, h: 100 },
      maxDimensions = { x: 0, y: 0, w: 500, h: 500 },
      onChange,
      ...props
    }: {
      name: string
      scale: number
      x: MotionValue<number>
      y: MotionValue<number>
      w: MotionValue<number>
      h: MotionValue<number>
      initialDimensions: Dimensions
      maxDimensions: Dimensions
      onChange?: (dimensions: Dimensions) => void
    },
    ref: MutableRefObject<HTMLElement>,
  ) => {
    const smolX = useTransform(x, (x) => x * scale)
    const smolY = useTransform(y, (y) => y * scale)
    const smolW = useTransform(w, (w) => w * scale)
    const smolH = useTransform(h, (h) => h * scale)

    const handleSize = 2

    const [isDragging, setIsDragging] = useState(false)
    const panelRef = useRef<HTMLDivElement>(null)

    const update = () =>
      onChange?.({
        x: clamp(Math.round(smolX.get() / scale), 0, maxDimensions.w),
        y: clamp(Math.round(smolY.get() / scale), 0, maxDimensions.h),
        w: clamp(Math.round(smolW.get() / scale), 0, maxDimensions.w - x.get()),
        h: clamp(Math.round(smolH.get() / scale), 0, maxDimensions.h - y.get()),
      })

    useEffect(() => {
      // definitely not sure about this, but it works for now
      if (x.get() !== initialDimensions.x) setTimeout(() => x.set(initialDimensions.x), 0)
      if (y.get() !== initialDimensions.y) setTimeout(() => y.set(initialDimensions.y), 0)
      if (w.get() !== initialDimensions.w) setTimeout(() => w.set(initialDimensions.w), 0)
      if (h.get() !== initialDimensions.h) setTimeout(() => h.set(initialDimensions.h), 0)
    }, [initialDimensions])

    const xW = useTransform([smolX, smolW], ([nX, nW]) => {
      if (typeof nX === "number" && typeof nW === "number") return nX + nW
    })
    const xDiff = useTransform(xW, (nX) => {
      const width = ref.current?.getBoundingClientRect()?.width
      if (typeof nX === "number") return width - nX
    })
    const yH = useTransform([smolY, smolH], ([nY, nH]) => {
      if (typeof nY === "number" && typeof nH === "number") return nY + nH
    })
    const yDiff = useTransform(yH, (nY) => {
      const height = ref.current?.getBoundingClientRect()?.height
      if (typeof nY === "number") return height - nY
    })

    useEffect(() => {
      const keyPressHandler = (e: KeyboardEvent) => {
        if (!(document.activeElement === panelRef.current)) return
        e.preventDefault()
        const amount = e.altKey ? 10 : 1
        if (e.key === "ArrowUp") {
          y.set(clamp(y.get() - amount, 0, y.get()))
        }
        if (e.key === "ArrowDown") {
          y.set(clamp(y.get() + amount, y.get(), maxDimensions.h - h.get()))
        }
        if (e.key === "ArrowLeft") {
          x.set(clamp(x.get() - amount, 0, x.get()))
        }
        if (e.key === "ArrowRight") {
          x.set(clamp(x.get() + amount, x.get(), maxDimensions.w - w.get()))
        }
      }
      window.addEventListener("keydown", keyPressHandler)
      return () => {
        window.removeEventListener("keydown", keyPressHandler)
      }
    }, [])

    return (
      <ErrorBoundary
        FallbackComponent={({ error }) => {
          console.error(error)
          return <div>error</div>
        }}
      >
        {/* top */}
        <motion.div
          className="top absolute inset-x-0 bg-black/80"
          style={{
            height: smolY,
          }}
        />

        {/* left */}
        <motion.div
          className="absolute left-0 bg-black/80"
          style={{
            width: smolX,
            y: smolY,
            height: smolH,
          }}
        />

        {/* right */}
        <motion.div
          className="absolute right-0 bg-black/80 ring-red-500"
          style={{
            y: smolY,
            height: smolH,
            width: xDiff,
          }}
        />

        {/* bottom */}
        <motion.div
          className="absolute inset-x-0 bg-black/80"
          style={{
            y: yH,
            height: yDiff,
          }}
        />

        {/* main panel */}
        <motion.div
          {...props}
          drag
          dragMomentum={false}
          dragConstraints={ref}
          dragElastic={0}
          style={{
            position: "absolute",
            x: smolX,
            y: smolY,
            width: smolW,
            height: smolH,
            boxSizing: "border-box",
          }}
          onDragStart={() => setIsDragging(true)}
          onDragEnd={(event, info) => {
            update()
            setIsDragging(false)
          }}
          tabIndex={0}
          ref={panelRef}
          className="focus:ring-2 focus:ring-picton-blue/50"
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              position: "relative",
            }}
          >
            {/* west */}
            <motion.div
              drag="x"
              dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
              dragElastic={0}
              dragMomentum={false}
              onDrag={(_, info) => {
                smolX.jump(clamp(Math.round(smolX.get() + info.delta.x), 0, maxDimensions.h))
                if (smolX.get() > 0)
                  smolW.jump(clamp(Math.round(smolW.get() - info.delta.x), 0, maxDimensions.w))
              }}
              onDragEnd={(event, info) => {
                update()
              }}
              style={{
                position: "absolute",
                left: 0,
                top: handleSize,
                bottom: handleSize,
                width: handleSize,
                backgroundColor: "#bada5577",
                cursor: "ew-resize",
              }}
            >
              <div
                className="absolute left-0 top-1/2 -translate-y-1/2 bg-black/80"
                style={{
                  width: handleSize * 2,
                  height: handleSize * 12,
                }}
              />
            </motion.div>

            {/* east */}
            <motion.div
              drag="x"
              dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
              dragElastic={0}
              dragMomentum={false}
              onDrag={(_, info) => {
                smolW.jump(Math.round(smolW.get() + info.delta.x))
              }}
              onDragEnd={(event, info) => {
                update()
              }}
              style={{
                position: "absolute",
                right: 0,
                top: handleSize,
                bottom: handleSize,
                width: handleSize,
                backgroundColor: "#bada5577",
                cursor: "ew-resize",
              }}
            >
              <div
                className="absolute right-0 top-1/2 -translate-y-1/2 bg-black/80"
                style={{
                  width: handleSize * 2,
                  height: handleSize * 12,
                }}
              />
            </motion.div>

            {/* north */}
            <motion.div
              drag="y"
              dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
              dragElastic={0}
              dragMomentum={false}
              onDrag={(_, info) => {
                smolY.jump(Math.round(smolY.get() + info.delta.y))
                smolH.jump(Math.round(smolH.get() - info.delta.y))
              }}
              onDragEnd={(event, info) => {
                update()
              }}
              style={{
                position: "absolute",
                left: handleSize,
                right: handleSize,
                top: 0,
                height: handleSize,
                backgroundColor: "#bada5577",
                cursor: "ns-resize",
              }}
            >
              <div
                className="absolute top-0 right-1/2 translate-x-1/2 bg-black/80"
                style={{
                  width: handleSize * 12,
                  height: handleSize * 2,
                }}
              />
            </motion.div>

            {/* south */}
            <motion.div
              drag="y"
              dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
              dragElastic={0}
              dragMomentum={false}
              onDrag={(_, info) => {
                smolH.jump(Math.round(smolH.get() + info.delta.y))
              }}
              onDragEnd={(event, info) => {
                update()
              }}
              style={{
                position: "absolute",
                left: handleSize,
                right: handleSize,
                bottom: 0,
                height: handleSize,
                backgroundColor: "#bada5577",
                cursor: "ns-resize",
              }}
            >
              <div
                className="absolute bottom-0 right-1/2 translate-x-1/2 bg-black/80"
                style={{
                  width: handleSize * 12,
                  height: handleSize * 2,
                }}
              />
            </motion.div>

            {/* north-west */}
            <motion.div
              drag
              dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
              dragElastic={0}
              dragMomentum={false}
              onDrag={(_, info) => {
                smolX.jump(Math.round(smolX.get() + info.delta.x))
                smolW.jump(Math.round(smolW.get() - info.delta.x))
                smolY.jump(Math.round(smolY.get() + info.delta.y))
                smolH.jump(Math.round(smolH.get() - info.delta.y))
              }}
              onDragEnd={(event, info) => {
                update()
              }}
              className="absolute bg-black/70"
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: handleSize * 4,
                height: handleSize * 4,
                // backgroundColor: "#bada5577",
                cursor: "nwse-resize",
              }}
            />

            {/* north-east */}
            <motion.div
              drag
              dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
              dragElastic={0}
              dragMomentum={false}
              onDrag={(_, info) => {
                smolW.jump(Math.round(smolW.get() + info.delta.x))
                smolY.jump(Math.round(smolY.get() + info.delta.y))
                smolH.jump(Math.round(smolH.get() - info.delta.y))
              }}
              onDragEnd={(event, info) => {
                update()
              }}
              className="absolute bg-black/70"
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                width: handleSize * 4,
                height: handleSize * 4,
                // backgroundColor: "#bada5577",
                cursor: "nesw-resize",
              }}
            />

            {/* south-east */}
            <motion.div
              drag
              dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
              dragElastic={0}
              dragMomentum={false}
              onDrag={(_, info) => {
                smolW.jump(Math.round(smolW.get() + info.delta.x))
                smolH.jump(Math.round(smolH.get() + info.delta.y))
              }}
              onDragEnd={(event, info) => {
                update()
              }}
              className="absolute bg-black/70"
              style={{
                position: "absolute",
                right: 0,
                bottom: 0,
                width: handleSize * 4,
                height: handleSize * 4,
                // backgroundColor: "#bada5577",
                cursor: "nwse-resize",
              }}
            />

            {/* south-west */}
            <motion.div
              drag
              dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
              dragElastic={0}
              dragMomentum={false}
              onDrag={(_, info) => {
                smolX.jump(Math.round(smolX.get() + info.delta.x))
                smolW.jump(Math.round(smolW.get() - info.delta.x))
                smolH.jump(Math.round(smolH.get() + info.delta.y))
              }}
              onDragEnd={(event, info) => {
                update()
              }}
              className="absolute bg-black/70"
              style={{
                position: "absolute",
                left: 0,
                bottom: 0,
                width: handleSize * 4,
                height: handleSize * 4,
                // backgroundColor: "#bada5577",
                cursor: "nesw-resize",
              }}
            />
          </div>
        </motion.div>
      </ErrorBoundary>
    )
  },
)

type Dimensions = {
  x: number
  y: number
  w: number
  h: number
}

type ImageSource = {
  rect: Dimensions
}
