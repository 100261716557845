import React, { forwardRef, HTMLProps, InputHTMLAttributes } from "react"
import { Dialog } from "@headlessui/react"
import FieldExplanationTooltip from "./FieldExplanationTooltip"
import classNames from "classnames"

const Modal = ({
  title,
  open,
  onClose,
  onCancelClick,
  onConfirmClick = () => true,
  onConfirmClickDisabled = false,
  warnOnClose,
  children,
  ...props
}: {
  title: string
  open: boolean
  onClose: () => void
  onCancelClick?: () => void
  onConfirmClick?: () => boolean
  onConfirmClickDisabled?: boolean
  warnOnClose?: string
  children: React.ReactElement | React.ReactElement[]
}) => {
  const localOnClose = () =>
    warnOnClose ? (window.confirm(warnOnClose) ? onClose() : false) : onClose()
  return (
    <Dialog open={open} onClose={localOnClose} className="relative z-50">
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex items-center justify-center overflow-y-auto px-4">
        {/* The actual dialog panel  */}
        <Dialog.Panel as="div" className="max-h-screen w-full max-w-screen-lg">
          <div
            className={classNames([
              "flex flex-col items-stretch gap-4",
              "rounded border border-gray-700 bg-white shadow-lg dark:border-gray-800 dark:bg-gray-700",
            ])}
          >
            <div className="flex-none">
              <Dialog.Title className="border-b border-gray-400 bg-gray-300 px-4 py-3 font-bold capitalize text-gray-700 dark:text-gray-800">
                {title}
              </Dialog.Title>
            </div>
            <div className="p-4">
              <div className="scrollbars-hidden flex min-h-0 flex-col gap-4">{children}</div>
            </div>
            <div className="flex gap-4 p-4">
              <button
                type="button"
                className="button disabled:bg-gray-500 disabled:text-gray-300"
                disabled={onConfirmClickDisabled}
                onClick={() => {
                  if (onConfirmClick?.()) onClose()
                }}
              >
                Confirm
              </button>
              <button
                type="button"
                className="button"
                onClick={() => {
                  onCancelClick?.()
                  localOnClose()
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

const FieldSet = ({ legend, children, ...props }) => (
  <fieldset className="rounded border border-gray-500 p-2">
    {legend && <legend className="font-semibold capitalize">{legend}</legend>}
    {children}
  </fieldset>
)

const Details = ({ summary, children, ...props }) => (
  <details className="p-2">
    {summary && <summary className="font-semibold capitalize">{summary}</summary>}
    {children}
  </details>
)

const LabeledInput = ({
  label,
  children,
  stretch = false,
  inline = false,
  tooltip,
  ...props
}: {
  label: string
  children: React.ReactElement | React.ReactElement[]
  stretch?: boolean
  inline?: boolean
  tooltip?: string
}) => {
  return (
    <div
      className={classNames([
        "flex",
        inline ? "items-center gap-2" : "flex-col",
        stretch && " flex-grow",
      ])}
    >
      <label className="inline-flex items-center gap-2">
        <span className="p-1 font-bold capitalize">{label}:</span>
        {tooltip?.length > 0 && (
          <span>
            <FieldExplanationTooltip label=" Target" description={tooltip} />
          </span>
        )}
      </label>
      <div>{children}</div>
    </div>
  )
}

const HorizontalGroup = ({ children, ...props }: HTMLProps<HTMLDivElement>) => {
  return <div className="flex flex-wrap items-start gap-4">{children}</div>
}

const Select = ({
  options,
  value,
  onChange,
}: {
  options: { label: string; value: string }[]
  value?: string
  onChange: (e: React.FormEvent<HTMLSelectElement>) => void
}) => (
  <select
    className="rounded border border-gray-500 bg-gray-300 px-2 capitalize text-gray-700 dark:bg-gray-500 dark:text-gray-900"
    onChange={(e) => onChange(e)}
    value={value}
  >
    {options.map(({ label, value }) => (
      <option value={value} key={value}>
        {label}
      </option>
    ))}
  </select>
)

const TextInput = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>(
  ({ ...props }, ref) => (
    <input
      {...(ref && { ref })}
      {...props}
      className="w-full rounded border border-gray-400 p-2 placeholder-gray-500 placeholder-opacity-60 shadow-inner focus:border-gray-800 focus:placeholder-opacity-0 focus:outline-none disabled:bg-gray-300 disabled:italic disabled:text-gray-600 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-300 dark:disabled:bg-gray-600 dark:disabled:text-gray-400"
    />
  ),
)

export { TextInput, Select, HorizontalGroup, LabeledInput, Details, FieldSet }
export default Modal
