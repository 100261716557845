import React, { useState } from "react"
import { useEditor, EditorContent, BubbleMenu } from "@tiptap/react"
// import StarterKit from "@tiptap/starter-kit"
import Document from "@tiptap/extension-document"
import Paragraph from "@tiptap/extension-paragraph"
import Text from "@tiptap/extension-text"
import Bold from "@tiptap/extension-bold"
import BulletList from "@tiptap/extension-bullet-list"
import History from "@tiptap/extension-history"
import Italic from "@tiptap/extension-italic"
import ListItem from "@tiptap/extension-list-item"
import OrderedList from "@tiptap/extension-ordered-list"
import Strike from "@tiptap/extension-strike"
import Link from "@tiptap/extension-link"
import Placeholder from "@tiptap/extension-placeholder"
import classNames from "classnames"
import Modal, { HorizontalGroup, LabeledInput, Select, TextInput } from "./Modal"
import { SingleImageUploader } from "./ImageLibrary"

const SimplifiedRichTextEditor = ({
  content = "",
  placeholder = "",
  inherited = "",
  onChange,
  enableInheritanceIndicator = false,
}: {
  content?: string
  placeholder?: string
  inherited?: string
  onChange?: (value: string) => void
  enableInheritanceIndicator?: boolean
}) => {
  const editor = useEditor({
    extensions: [
      Document,
      History,
      Paragraph,
      Text,
      Bold,
      Italic,
      Strike,
      ListItem,
      BulletList,
      OrderedList,
      Link.configure({
        openOnClick: false,
      }),
      Placeholder.configure({
        placeholder,
        showOnlyWhenEditable: false,
        showOnlyCurrent: false,
        includeChildren: true,
      }),
    ],
    content,
    onUpdate: (editorContent) => {
      onChange?.(editorContent.editor.getHTML())
    },
  })

  return (
    <div className="relative flex flex-col">
      <div className="sticky top-0 z-30 bg-white shadow-md dark:bg-gray-700">
        <MenuBar editor={editor} />
      </div>
      <div>
        {enableInheritanceIndicator && inherited && (
          <details className="relative">
            <summary className="text-sm font-semibold">Show Existing</summary>
            <div className="group absolute right-4 top-5">
              <button
                type="button"
                className="flex items-center gap-2 p-2 text-sm hover:text-picton-blue/70 focus:text-picton-blue/70 active:text-picton-blue/100"
                onClick={async () => {
                  try {
                    await navigator.clipboard.write([
                      new ClipboardItem({
                        "text/html": new Blob([inherited], { type: "text/html" }),
                        "text/plain": new Blob([inherited], { type: "text/plain" }),
                      }),
                    ])
                    console.log("Content copied to clipboard")
                  } catch (err) {
                    console.error("Failed to copy: ", err)
                  }
                }}
              >
                <span className="origin-right scale-x-0 transition group-hover:scale-x-100 group-focus:scale-x-100">
                  Copy the original
                </span>
                <span className="">
                  <i className="fa fa-copy" />
                </span>
              </button>
            </div>
            <div
              className="h-full w-full space-y-6 border border-black/30 px-4 py-8 [&_a]:text-picton-blue"
              dangerouslySetInnerHTML={{ __html: inherited }}
            />
          </details>
        )}
        <EditorContent
          editor={editor}
          className="border border-gray-500 p-2 ring-blue-500/30 focus-within:ring"
        />
      </div>
    </div>
  )
}

const Button = ({
  label = "",
  icon = <></>,
  active = false,
  onClick = (e) => {},
  ...props
}: {
  label: string
  icon: React.ReactElement
  active: boolean
  onClick: (e: React.FormEvent<HTMLButtonElement>) => void
}) => {
  return (
    <button
      type="button"
      className={classNames([
        "flex h-6 w-6 items-center justify-center rounded-sm ring-2",
        "bg-gray-300 hover:bg-gray-400 focus:bg-gray-400",
        "dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:bg-gray-700",
        active ? "ring-blue-500" : "ring-black",
      ])}
      name={label}
      onClick={onClick}
    >
      <span className="sr-only">{label}</span>
      {icon}
    </button>
  )
}

const ImageButton = ({ editor }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button
        onClick={(e) => {
          setOpen(true)
        }}
        active={editor.isActive("image")}
        label="image"
        icon={<i className="fa fa-file-image-o" role="presentation" title="image" />}
      />
      {open && <ImageDialog open={open} setOpen={setOpen} editor={editor} />}
    </>
  )
}

const ImageDialog = ({ open, setOpen, editor }) => {
  const el = { "data-size": "", src: "", alt: "" }
  const [element, setElement] = useState(editor.getAttributes("customImage") || el)
  const [updateImage, setUpdateImage] = useState(false)
  const onImageUploaded = (e) => {
    setElement({ ...element, src: e.url })
    setUpdateImage(false)
  }
  const confirm = () => {
    editor.chain().focus().setImage(element).run()
    return true
  }
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setElement(el)
          setOpen(false)
        }}
        title="Add an image"
        onConfirmClick={confirm}
        onConfirmClickDisabled={!element.src}
      >
        {updateImage || !element.src ? (
          <>
            <SingleImageUploader onImageUploaded={onImageUploaded} />
            <>
              {element.src && (
                <div>
                  <button type="button" className="button" onClick={() => setUpdateImage(false)}>
                    Cancel image update
                  </button>
                </div>
              )}
            </>
          </>
        ) : (
          <HorizontalGroup>
            <div>
              <img src={element.src} className="h-auto max-h-96 w-auto max-w-lg" />
            </div>
            <button type="button" className="button" onClick={() => setUpdateImage(true)}>
              Update image
            </button>
          </HorizontalGroup>
        )}
        <HorizontalGroup>
          <LabeledInput label="alt text">
            <TextInput
              value={element.alt}
              onChange={(e) => setElement({ ...element, alt: e.currentTarget.value })}
            />
          </LabeledInput>
          <LabeledInput label="size">
            <Select
              options={[
                { value: "intrinsic", label: "Intrinsic" },
                { value: "fullWidth", label: "Full Width" },
              ]}
              onChange={(e) => {
                setElement({ ...element, "data-size": e.currentTarget.value })
              }}
              value={element["data-size"]}
            />
          </LabeledInput>
        </HorizontalGroup>
      </Modal>
    </>
  )
}

const LinkDialog = ({ editor }) => {
  const el = { href: "", "data-component": "" }
  const [open, setOpen] = useState(false)
  const [element, setElement] = useState(el)
  const getNode = (editor) => {
    return editor.chain().focus().extendMarkRange("link")
  }
  const getAttributes = (editor) => editor.getAttributes("link")

  const confirm = () => {
    const { href } = element
    if (href === "") {
      getNode(editor).unsetLink().run()
      return true
    }
    getNode(editor).setLink(element).run()
    return true
  }

  return (
    <>
      <Button
        onClick={(e) => {
          setElement(getAttributes(editor) || el)
          setOpen(true)
        }}
        active={editor.isActive("link")}
        label="link"
        icon={<i className="fa fa-link" role="presentation" title="link" />}
      />
      <Modal
        open={open}
        onClose={() => {
          setElement(el)
          setOpen(false)
        }}
        title="Add a Link"
        onConfirmClick={confirm}
        onConfirmClickDisabled={false}
      >
        <HorizontalGroup>
          <LabeledInput label="url">
            <TextInput
              value={element.href}
              onChange={(e) => setElement({ ...element, href: e.currentTarget.value })}
            />
          </LabeledInput>
        </HorizontalGroup>
      </Modal>
    </>
  )
}

const MenuBar = ({ editor }) => {
  if (!editor) return null

  return (
    <div className="flex items-center gap-2 p-2">
      <Button
        onClick={(e) => {
          editor.chain().focus().toggleBold().run()
        }}
        active={editor.isActive("bold")}
        label="bold"
        icon={<i className="fa fa-bold" role="presentation" title="bold" />}
      />
      <Button
        onClick={(e) => {
          editor.chain().focus().toggleItalic().run()
        }}
        active={editor.isActive("italic")}
        label="italic"
        icon={<i className="fa fa-italic" role="presentation" title="italic" />}
      />
      <Button
        onClick={(e) => {
          editor.chain().focus().toggleStrike().run()
        }}
        active={editor.isActive("strike")}
        label="strikethrough"
        icon={<i className="fa fa-strikethrough" role="presentation" title="strikethrough" />}
      />
      <Button
        onClick={(e) => editor.chain().focus().toggleBulletList().run()}
        active={editor.isActive("bulletList")}
        label="unordered list"
        icon={<i className="fa fa-list-ul" role="presentation" title="unordered list" />}
      />
      <Button
        onClick={(e) => editor.chain().focus().toggleOrderedList().run()}
        active={editor.isActive("orderedList")}
        label="ordered list"
        icon={<i className="fa fa-list-ol" role="presentation" title="ordered list" />}
      />
      <LinkDialog editor={editor} />

      <Button
        onClick={(e) => editor.chain().focus().undo().run()}
        active={editor.isActive("undo")}
        label="undo"
        icon={<i className="fa fa-undo" role="presentation" title="undo" />}
      />
      <Button
        onClick={(e) => editor.chain().focus().redo().run()}
        active={editor.isActive("redo")}
        label="redo"
        icon={<i className="fa fa-undo scale-x-[-1]" role="presentation" title="redo" />}
      />
    </div>
  )
}

export default SimplifiedRichTextEditor
