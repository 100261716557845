import React, { useState, HTMLAttributes, useEffect } from "react"
import Rails from "../util/Rails"
import Modal, { HorizontalGroup, LabeledInput, TextInput, Select } from "./Modal"
import ImageEditor from "./ImageEditor"
import TagSelector from "./TagSelector"
import classNames from "classnames"
import { isEqual } from "lodash-es"

const Button = ({ ...props }: HTMLAttributes<HTMLButtonElement>) => {
  return <button type="button" className="button" {...props} />
}

const ImageDialog = ({ editingImage, setEditingImage, setImages }) => {
  const [original] = useState({ ...editingImage })
  const [closeWarning, setCloseWarning] = useState(null)
  const warning = "Discard changes?"

  const mallId =
    window.location.pathname.match(/\/malls\/(\d+)/)?.[1] ||
    window.location.search.match(/mall_id=(\d+)/)?.[1]

  useEffect(() => {
    isEqual(original, editingImage) ? setCloseWarning(null) : setCloseWarning(warning)
  }, [editingImage])

  const confirm = () => {
    fetch([`/rh/images/${editingImage.id}.json`, mallId].filter(Boolean).join("?mall_id="), {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      body: JSON.stringify({
        image: { ...editingImage, tag_ids: editingImage.tags.map(({ id }) => id) },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setImages((prev) => prev.map((i) => (i.id === editingImage.id ? data : i)))
      })
      .catch((err) => {
        console.error(err)
      })

    return true
  }
  return (
    <Modal
      open={!!editingImage}
      onClose={() => {
        setEditingImage(false)
      }}
      title="Add an image"
      onConfirmClick={confirm}
      warnOnClose={closeWarning}
    >
      <HorizontalGroup>
        <LabeledInput label="alt">
          <TextInput
            value={editingImage.alt_text || ""}
            onChange={(e) => {
              setEditingImage({ ...editingImage, alt_text: e.currentTarget.value })
            }}
          />
        </LabeledInput>
        <LabeledInput label="object-fit">
          <Select
            options={[
              { value: "cover", label: "cover" },
              { value: "contain", label: "contain" },
            ]}
            onChange={(e) => {
              setEditingImage({ ...editingImage, object_fit: e.currentTarget.value })
            }}
            value={editingImage.object_fit}
          />
        </LabeledInput>
      </HorizontalGroup>
      <LabeledInput
        label="Tags"
        tooltip={[
          `Tags help you categorize or describe your image.`,
          ``,
          `Some tags are automatically added to your image based on the orientation or file type of the image.`,
          `Some tags have special meaning to the site and are used to control how the image is displayed.`,
        ].join("\n")}
      >
        <TagSelector
          ownerType="image"
          initialTags={editingImage.tags || []}
          onChange={(tags) => {
            setEditingImage({ ...editingImage, tags })
          }}
        />
      </LabeledInput>
      <div className="">
        <span className="p-1 font-bold capitalize">objectFit: {editingImage.object_fit}</span>
        {/* <pre>{JSON.stringify(editingImage, null, 2)}</pre> */}
      </div>
      <div>
        <span className="p-1 font-bold capitalize">Image Dimensions: </span>
        <div className="flex items-center gap-2 px-2">
          <div>
            <span className="font-bold">width: </span>
            <span>
              {Number(editingImage?.width) || (
                <>
                  <span className="text-yellow-500">unknown</span>!
                </>
              )}
            </span>
          </div>
          <div>
            <span className="font-bold">height: </span>
            <span>
              {Number(editingImage?.height) || (
                <>
                  <span className="text-yellow-500">unknown</span>!
                </>
              )}
            </span>
          </div>
        </div>
      </div>
      {!editingImage?.width && !editingImage?.height ? (
        <div>
          <p className="text-red-500">
            Image dimensions are unknown. Please try saving this image again (by hitting "Confirm"
            and then reloading the page) or upload a new image.
          </p>
        </div>
      ) : (
        <ImageEditor image={editingImage} setImage={setEditingImage} />
      )}
    </Modal>
  )
}

export default ({
  images: initialImages = [],
  ownerType,
  imagePropertyName = "images",
  selectedImage,
  setSelectedImage,
  onChange,
  thumbnailSize = "lg",
  limit,
}: {
  images: any[]
  ownerType: string
  imagePropertyName?: string
  selectedImage?: string | number
  setSelectedImage?: React.Dispatch<string | number>
  onChange?: (x) => void
  thumbnailSize: string
  limit?: number | undefined
}) => {
  const [images, setImages] = useState(initialImages)
  const [editingImage, setEditingImage] = useState(null)

  const thumbnailSizes = {
    sm: "grid grid-cols-3 gap-2 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8",
    lg: "grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4",
  }

  useEffect(() => {
    if (onChange) onChange(images)
    if (setSelectedImage && images.length === 1) setSelectedImage(images[0].id)
  }, [images])

  return (
    <div>
      <ul className={thumbnailSizes[thumbnailSize]}>
        {images
          .sort((x, y) => x.created_at.localeCompare(y.created_at))
          .sort((x, y) => (x.id === selectedImage ? -1 : y.id === selectedImage ? 1 : 0))
          .map((i) => (
            <li key={i.id}>
              <div className="flex flex-col gap-2 rounded-md border border-gray-600 shadow-md">
                <div>
                  <div
                    className={classNames([
                      "relative aspect-square",
                      setSelectedImage && selectedImage === i.id && "border border-picton-blue",
                    ])}
                  >
                    <div className="absolute inset-2 flex items-center justify-center">
                      <img src={i.url} className="max-h-full max-w-full" />
                    </div>
                    <button
                      type="button"
                      onClick={(e) => {
                        if (
                          window?.confirm(
                            "Are you sure you want to delete this image? Click 'OK' to confirm.",
                          )
                        )
                          setImages(images?.filter((img) => img.id !== i.id))
                      }}
                      className="absolute right-0 top-0 rounded-bl-md bg-gray-800 p-2 text-gray-600"
                    >
                      <i className="fa fa-times-circle fill-current text-gray-600" />
                    </button>
                  </div>
                  <input
                    type="hidden"
                    name={`${ownerType}[${imagePropertyName}_ids][]`}
                    value={i.id}
                  />
                </div>
                <div className="flex justify-between p-2">
                  <button
                    type="button"
                    onClick={(e) => {
                      e?.stopPropagation()
                      setEditingImage({
                        ...i,
                        sources: {
                          0: {
                            rect: {
                              x: 0,
                              y: 0,
                              w: i?.width,
                              h: i?.height,
                            },
                          },
                          ...i?.sources,
                        },
                      })
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                  {setSelectedImage && (
                    <button
                      type="button"
                      onClick={(e) => {
                        setSelectedImage(i.id)
                      }}
                    >
                      {selectedImage === i.id ? (
                        <i className="fa fa-check-square text-picton-blue" />
                      ) : (
                        <i className="fa fa-check-square-o" />
                      )}
                    </button>
                  )}
                </div>
              </div>
            </li>
          ))}
        {images.length === 0 && (
          <input type="hidden" name={`${ownerType}[${imagePropertyName}_ids][]`} />
        )}
      </ul>

      {editingImage && (
        <ImageDialog
          editingImage={editingImage}
          setEditingImage={setEditingImage}
          setImages={setImages}
        />
      )}
      {(limit === undefined || images.length < limit) && (
        <ImageUploader images={images} setImages={setImages} />
      )}
    </div>
  )
}

export const SingleImageUploader = ({ onImageUploaded }) => {
  const [url, setUrl] = useState("")
  const [uploadType, setUploadType] = useState("file")
  const [file, setFile] = useState(null)
  const [preview, setPreview] = useState(null)
  const [processingRequest, setProcessingRequest] = useState(false)

  const mallId =
    window.location.pathname.match(/\/malls\/(\d+)/)?.[1] ||
    window.location.search.match(/mall_id=(\d+)/)?.[1]

  return (
    <div className="new-image flex items-end gap-4 border-b border-gray-500 p-2">
      {preview ? (
        <>
          <img src={preview} className="h-auto max-h-96 w-auto max-w-xs" />
          {(file || url) && (
            <>
              <button
                className="button"
                type="button"
                disabled={processingRequest}
                onClick={() => {
                  setProcessingRequest(true)
                  let body
                  if (uploadType === "file") {
                    body = file
                  }
                  if (uploadType === "url") {
                    body = JSON.stringify({
                      image: {
                        url: url,
                      },
                    })
                  }

                  fetch(["/rh/images.json", mallId].filter(Boolean).join("?mall_id="), {
                    method: "POST",
                    headers: {
                      ...(uploadType === "url" && { "Content-Type": "application/json" }),
                      "X-CSRF-Token": Rails.csrfToken(),
                    },
                    body,
                  })
                    .then((res) => res.json())
                    .then((data) => onImageUploaded(data))
                    .catch((err) => {
                      // TODO: wrap this in a modal or something friendlier
                      alert("There was an error uploading your image. Please try again.")
                      console.error(err)
                    })
                    .finally(() => {
                      setUrl("")
                      setFile(null)
                      setProcessingRequest(false)
                      setPreview(null)
                    })
                }}
              >
                upload!
              </button>
              <button
                className="button"
                type="button"
                disabled={processingRequest}
                onClick={() => {
                  setUrl("")
                  setFile(null)
                  setProcessingRequest(false)
                  setPreview(null)
                }}
              >
                Cancel Upload
              </button>
            </>
          )}
        </>
      ) : (
        <div className="flex-grow">
          <div className="flex items-start gap-8">
            <LabeledInput label="upload a file" inline>
              <input
                type="radio"
                name="uploadType"
                value="file"
                checked={uploadType === "file"}
                onChange={() => {
                  setUploadType("file")
                  setPreview(null)
                }}
              />
            </LabeledInput>
            <LabeledInput label="Upload via URL" inline>
              <input
                type="radio"
                name="uploadType"
                value="url"
                checked={uploadType === "url"}
                onChange={() => {
                  setUploadType("url")
                  setPreview(null)
                }}
              />
            </LabeledInput>
          </div>
          <div className="flex items-end gap-4">
            {uploadType === "file" && (
              <LabeledInput label="File" stretch>
                <input
                  className="w-auto"
                  type="file"
                  onChange={(e) => {
                    const data = new FormData()
                    setPreview(URL.createObjectURL(e.target.files[0]))
                    data.append("image[uploaded_file]", e.target.files[0])
                    return setFile(data)
                  }}
                />
              </LabeledInput>
            )}
            {uploadType === "url" && (
              <div className="flex max-w-md flex-grow items-end gap-2">
                <LabeledInput label="Url" stretch>
                  <TextInput value={url} onChange={(e) => setUrl(e.target.value)} />
                </LabeledInput>
                <Button onClick={() => setPreview(url)}>Preview</Button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export const ImageUploader = ({ images, setImages }) => {
  const [addingAnotherImage, setAddingAnotherImage] = useState(!images?.length)

  const onImageUploaded = (e) => {
    setImages((prev) => [...prev, e])
    setAddingAnotherImage(false)
  }

  return (
    <>
      {addingAnotherImage && <SingleImageUploader onImageUploaded={onImageUploaded} />}
      <div className="py-2">
        {!addingAnotherImage ? (
          <Button onClick={() => setAddingAnotherImage(true)}>Add another image</Button>
        ) : (
          addingAnotherImage &&
          images?.length !== 0 && (
            <Button
              onClick={() => {
                setAddingAnotherImage(false)
              }}
            >
              Cancel new image
            </Button>
          )
        )}
      </div>
    </>
  )
}
