import emmet from "emmet"
import ace from "ace-builds/src-noconflict/ace"
import "ace-builds/src-noconflict/theme-monokai"
import "ace-builds/src-noconflict/mode-liquid"
import "ace-builds/src-noconflict/mode-html"
import "ace-builds/src-noconflict/mode-json"
import "ace-builds/src-noconflict/keybinding-sublime"
import "ace-builds/src-noconflict/ext-language_tools"

import emmet_ext from "ace-builds/src-noconflict/ext-emmet"
emmet_ext.setCore(emmet)

const editors = []

export const setup = () => {
  document.querySelectorAll(".code-editor").forEach((node: HTMLInputElement) => {
    const mode = node.dataset.editorMode || "json"
    const div = document.createElement("div")
    div.classList.add("h-64")
    node.parentElement.insertBefore(div, node)
    const editor = ace.edit(div)

    editor.getSession().setMode(`ace/mode/${mode}`)
    editor.setTheme("ace/theme/monokai")
    editor.setKeyboardHandler("ace/keyboard/sublime")

    editor.setOption("enableBasicAutocompletion", true)
    editor.setOption("autoScrollEditorIntoView", true)
    editor.setOption("showInvisibles", true)
    editor.setOption("enableEmmet", true)

    editor.setValue(node.value)
    editor.getSession().setUseWorker(false)
    editor.session.on("change", (delta) => {
      // delta.start, delta.end, delta.lines, delta.action
      node.value = editor.getValue()
    })

    const button = document.createElement("button")
    button.classList.add("float-right")
    button.innerHTML = '<i class="fa fa-expand" title="Embiggen"></i>'
    node.parentElement.insertBefore(button, div)
    button.addEventListener("click", (e) => {
      div.classList.toggle("h-64")
      div.classList.toggle("h-screen")
      editor.resize()
      e.preventDefault()
      return false
    })

    editors.push({ instance: editor, buttons: [button] })
  })
}

export const teardown = () => {
  while (editors.length) {
    const o = editors.pop()
    o.buttons.forEach((button) => button.parentElement.removeChild(button))
    o.instance.container.parentElement.removeChild(o.instance.container)
    o.instance.destroy()
  }
}

export default { setup, teardown }
