import React, { useState } from "react"
import { toPairs } from "lodash-es"
import ImageCropper from "./ImageCropper"
import { Tab } from "@headlessui/react"
import Modal, { HorizontalGroup, LabeledInput, TextInput, Details } from "./Modal"
import { difference, findKey } from "lodash-es"

export default function ImageEditor({ image, setImage }) {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [addingBreakPoint, setAddingBreakPoint] = useState(false)
  const usedBreakpoints = Object.keys(image.sources).map((x) => parseInt(x))
  const unusedBreakpoints = difference(Object.values(rangeOptions), usedBreakpoints)
  const ranges = usedBreakpoints.map((b, i) => {
    const nextBreakpoint =
      usedBreakpoints[i + 1] || unusedBreakpoints.filter((x) => x > b).sort((a, b) => b - a)[0]
    return [b, nextBreakpoint]
  })
  return (
    <div className="flex gap-4">
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <LabeledInput
          label="breakpoints"
          tooltip={[
            `Control how your image will look on different screen sizes.`,
            ``,
            `We recommend starting with "base" and "large", at least. Some situations might require a little more granularity than others.`,
          ].join("\n")}
        >
          <Tab.List className="flex flex-col gap-2 rounded p-1">
            <button
              type="button"
              className=" text-picton-blue"
              onClick={(e) => {
                setAddingBreakPoint(true)
              }}
            >
              <span className="flex gap-2">
                <span>Add breakpoint</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
              </span>
            </button>
            {ranges.map(([breakpoint, nextBreakpoint], i) => (
              <Tab
                as="span"
                key={breakpoint}
                className={({ selected }) =>
                  `${
                    selected
                      ? "border-picton-blue text-picton-blue dark:bg-dark-gray"
                      : "border-gray-500"
                  } flex items-center justify-between gap-4 rounded border border-opacity-50`
                }
              >
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation()
                    setSelectedIndex(i)
                  }}
                  className="flex items-center justify-between gap-1"
                  title={`${breakpoint}px - ${nextBreakpoint}px`}
                >
                  <span className="flex flex-col items-center py-1 pl-3">
                    <RangeIcon breakpoint={breakpoint} />
                    <span className="text-xs">
                      {findKey(rangeOptions, (v) => String(v) === String(breakpoint))}
                    </span>
                  </span>

                  <svg viewBox="0 0 100 100" className="w-10 stroke-gray-500">
                    <line x1="10" y1="50" x2="90" y2="50" strokeWidth={2} />
                  </svg>

                  <span className="flex flex-col items-center">
                    <RangeIcon breakpoint={nextBreakpoint} />
                    <span className="text-xs">
                      {findKey(rangeOptions, (v) => String(v) === String(nextBreakpoint))}
                    </span>
                  </span>
                </button>
                <button
                  type="button"
                  className="w-8 border-l border-gray-500/30 p-2 hover:text-red-500 focus:text-red-500 disabled:cursor-default disabled:text-gray-500"
                  disabled={i === 0}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (!confirm("Remove this breakpoint?")) return
                    const sources = { ...image?.sources }
                    delete sources[breakpoint]
                    setImage({
                      ...image,
                      sources,
                    })
                    setSelectedIndex(0)
                  }}
                >
                  <span className="sr-only">Remove breakpoint</span>
                  {i > 0 ? (
                    <i className="fa fa-times opacity-50 hover:opacity-100" />
                  ) : (
                    <i className="fa fa-th opacity-50" />
                  )}
                </button>
              </Tab>
            ))}
          </Tab.List>
        </LabeledInput>
        <Tab.Panels className="flex-grow">
          {toPairs(image.sources).map(([k]) => (
            <Tab.Panel key={k}>
              <ImageCropper image={image} setImage={setImage} breakpoint={k} />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
      {addingBreakPoint && (
        <AddBreakpointDialog
          image={image}
          setImage={setImage}
          setSelectedIndex={setSelectedIndex}
          unusedBreakpoints={unusedBreakpoints}
          addingBreakPoint={addingBreakPoint}
          setAddingBreakPoint={setAddingBreakPoint}
        />
      )}
    </div>
  )
}

const AddBreakpointDialog = ({
  image,
  setImage,
  setSelectedIndex,
  unusedBreakpoints,
  addingBreakPoint,
  setAddingBreakPoint,
}) => {
  const [breakpoint, setBreakpoint] = useState(0)

  const confirm = (w: number) => {
    setBreakpoint(w)
    const sources = {
      ...image?.sources,
      [w]: { rect: { x: 0, y: 0, w: image?.width, h: image?.height } },
    }
    const selectedIndex = Object.keys(sources).findIndex((k) => k === String(w))
    setImage({
      ...image,
      sources,
    })
    setAddingBreakPoint(false)
    setSelectedIndex(selectedIndex)
    return true
  }

  return (
    <Modal
      open={addingBreakPoint}
      onClose={() => {
        setAddingBreakPoint(false)
      }}
      title="Add Breakpoint"
      onConfirmClick={() => {
        confirm(breakpoint)
        return true
      }}
      onConfirmClickDisabled={false}
    >
      <HorizontalGroup>
        {Object.entries(screens).map(([screen, size]) => (
          <div className="flex flex-col items-center gap-2">
            <RangeIcon breakpoint={size} />
            <button
              key={screen}
              type="button"
              className="button uppercase"
              disabled={!unusedBreakpoints.includes(size)}
              onClick={() => {
                confirm(screens[screen])
              }}
            >
              {screen}
            </button>
          </div>
        ))}
      </HorizontalGroup>
      <div>
        <Details summary="Advanced">
          <LabeledInput label="Custom Width">
            <TextInput
              type="number"
              value={breakpoint}
              onChange={(e) => setBreakpoint(Number(e.currentTarget.value))}
            />
          </LabeledInput>
        </Details>
      </div>
    </Modal>
  )
}

const screens = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
}

const rangeOptions = {
  base: 0, // base, initial, min, xs, smallest, start...? xs could actually be defined for some sites...
  ...screens,
  "∞": 9999,
}

const rangeIcons = {
  0: (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 496" {...props}>
      <path d="m144,48h-24c-4.42,0-8-3.58-8-8s3.58-8,8-8h24c4.42,0,8,3.58,8,8s-3.58,8-8,8Z" />
      <path d="m184,48h-8c-4.42,0-8-3.58-8-8s3.58-8,8-8h8c4.42,0,8,3.58,8,8s-3.58,8-8,8Z" />
      <path d="m256,0H32C14.36,0,0,14.35,0,32v432c0,17.64,14.36,32,32,32h224c17.64,0,32-14.36,32-32V32c0-17.65-14.36-32-32-32ZM16,81.27h256v340.73H16V81.27ZM32,16h224c8.82,0,16,7.18,16,16v33.27H16v-33.27c0-8.82,7.18-16,16-16Zm224,464H32c-8.82,0-16-7.18-16-16v-26h256v26c0,8.82-7.18,16-16,16Z" />
    </svg>
  ),
  640: (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 496" {...props}>
      <path d="m144,48h-24c-4.42,0-8-3.58-8-8s3.58-8,8-8h24c4.42,0,8,3.58,8,8s-3.58,8-8,8Z" />
      <path d="m184,48h-8c-4.42,0-8-3.58-8-8s3.58-8,8-8h8c4.42,0,8,3.58,8,8s-3.58,8-8,8Z" />
      <path d="m256,0H32C14.36,0,0,14.35,0,32v432c0,17.64,14.36,32,32,32h224c17.64,0,32-14.36,32-32V32c0-17.65-14.36-32-32-32ZM16,81.27h256v340.73H16V81.27ZM32,16h224c8.82,0,16,7.18,16,16v33.27H16v-33.27c0-8.82,7.18-16,16-16Zm224,464H32c-8.82,0-16-7.18-16-16v-26h256v26c0,8.82-7.18,16-16,16Z" />
    </svg>
  ),
  768: (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 368 496" {...props}>
      <path d="m336,496H32c-17.65,0-32-14.36-32-32V32C0,14.35,14.35,0,32,0h304c17.64,0,32,14.35,32,32v432c0,17.64-14.36,32-32,32ZM32,16c-8.82,0-16,7.18-16,16v432c0,8.82,7.18,16,16,16h304c8.82,0,16-7.18,16-16V32c0-8.82-7.18-16-16-16H32Z" />
      <path d="m320,432H48c-8.82,0-16-7.18-16-16V48c0-8.82,7.18-16,16-16h272c8.82,0,16,7.18,16,16v368c0,8.82-7.18,16-16,16ZM48,48v368h272V48H48Z" />
      <path d="m200,464h-32c-4.42,0-8-3.58-8-8s3.58-8,8-8h32c4.42,0,8,3.58,8,8s-3.58,8-8,8Z" />
    </svg>
  ),
  1024: (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496.01 320" {...props}>
      <path d="m462.09,320H33.92c-15.2,0-28.4-10.82-31.38-25.72l-2.23-11.14c-.94-4.72.27-9.56,3.32-13.28,3.05-3.72,7.56-5.86,12.37-5.86h158.24c1.24,0,2.47.29,3.58.84l14.31,7.16h111.74l14.31-7.16c1.11-.56,2.34-.84,3.58-.84h158.24c4.81,0,9.32,2.13,12.37,5.86,3.05,3.72,4.26,8.56,3.32,13.28l-2.23,11.14c-2.98,14.91-16.18,25.72-31.38,25.72ZM16,280l2.23,11.14c1.49,7.45,8.09,12.86,15.69,12.86h428.17c7.6,0,14.2-5.41,15.69-12.86l2.23-11.14h-156.35l-14.31,7.16c-1.11.56-2.34.84-3.58.84h-115.52c-1.24,0-2.47-.29-3.58-.84l-14.31-7.16H16Z" />
      <path d="m448,248c-4.42,0-8-3.58-8-8V32c0-8.82-7.18-16-16-16H72c-8.82,0-16,7.18-16,16v208c0,4.42-3.58,8-8,8s-8-3.58-8-8V32C40,14.35,54.36,0,72,0h352c17.65,0,32,14.35,32,32v208c0,4.42-3.58,8-8,8Z" />
    </svg>
  ),
  1280: (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 536.01 331" {...props}>
      <path d="m520,284c4.81,0,9.32,2.13,12.37,5.86,3.05,3.72,4.26,8.56,3.32,13.28l-2.23,11.14c-2.98,14.91-16.18,16.72-31.38,16.72H33.92c-15.2,0-28.4-1.82-31.38-16.72l-2.23-11.14c-.94-4.72.27-9.56,3.32-13.28,3.05-3.72,7.56-5.86,12.37-5.86m0,16l2.23,11.14c1.49,7.45,8.09,3.86,15.69,3.86h468.17c7.6,0,14.2,3.59,15.69-3.86l2.23-11.14" />
      <path d="m508,268c-4.42,0-8-3.58-8-8V32c0-8.82-7.18-16-16-16H52c-8.82,0-16,7.18-16,16v228c0,4.42-3.58,8-8,8s-8-3.58-8-8V32C20,14.35,34.36,0,52,0h432c17.65,0,32,14.35,32,32v228c0,4.42-3.58,8-8,8Z" />
    </svg>
  ),
  1536: (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 448" {...props}>
      <path d="m464,0H32C14.35,0,0,14.35,0,32v304c0,17.64,14.35,32,32,32h173.25l-19.2,64h-34.05c-4.42,0-8,3.58-8,8s3.58,8,8,8h39.98s.02,0,.03,0c0,0,.02,0,.02,0h111.95s.02,0,.02,0c0,0,.02,0,.03,0h39.98c4.42,0,8-3.58,8-8s-3.58-8-8-8h-34.05l-19.2-64h173.25c17.64,0,32-14.36,32-32V32c0-17.65-14.36-32-32-32ZM32,16h432c8.82,0,16,7.18,16,16v272H16V32c0-8.82,7.18-16,16-16Zm261.25,416h-90.5l19.2-64h52.1l19.2,64Zm170.75-80h-183.87s-.08,0-.11,0h-64.07s-.04,0-.05,0H32c-8.82,0-16-7.18-16-16v-16h464v16c0,8.82-7.18,16-16,16Z" />
    </svg>
  ),
  9999: (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 448" {...props}>
      <path d="m464,0H32C14.35,0,0,14.35,0,32v304c0,17.64,14.35,32,32,32h173.25l-19.2,64h-34.05c-4.42,0-8,3.58-8,8s3.58,8,8,8h39.98s.02,0,.03,0c0,0,.02,0,.02,0h111.95s.02,0,.02,0c0,0,.02,0,.03,0h39.98c4.42,0,8-3.58,8-8s-3.58-8-8-8h-34.05l-19.2-64h173.25c17.64,0,32-14.36,32-32V32c0-17.65-14.36-32-32-32ZM32,16h432c8.82,0,16,7.18,16,16v272H16V32c0-8.82,7.18-16,16-16Zm261.25,416h-90.5l19.2-64h52.1l19.2,64Zm170.75-80h-183.87s-.08,0-.11,0h-64.07s-.04,0-.05,0H32c-8.82,0-16-7.18-16-16v-16h464v16c0,8.82-7.18,16-16,16Z" />
    </svg>
  ),
}
const RangeIcon = ({ breakpoint }) => {
  const Icon = rangeIcons[breakpoint] || (
    <svg>
      <text>{String(breakpoint)}</text>
    </svg>
  )
  return <Icon className="h-6 w-auto fill-current" />
}
