import React, { useEffect, useState } from "react"

export default function HeaderQuickActionsPanel({}) {
  const [buttons, setButtons] = useState([])

  useEffect(
    () =>
      setButtons(
        Array.from(document.querySelectorAll('form .actions [name="commit"]'))
          .map((el) => ({
            text: el.textContent || el["value"] || "Save",
            className: el.className,
            el,
          }))
          .filter(Boolean),
      ),
    [],
  )
  return buttons.length ? (
    <div className="flex items-center gap-2 py-1">
      {buttons.map((btn, i) => (
        <button
          key={`quick-button-${i}`}
          className={`${btn.className || "button"} py-0.5`}
          onClick={() => btn.el?.click()}
        >
          {btn.text}
        </button>
      ))}
    </div>
  ) : null
}
