import { init } from "./tag_editor"
import { log } from "util"

export default function image_managment_container() {
  document
    .querySelectorAll("[data-name=image-management-container]")
    .forEach((image_management_container: HTMLElement) => {
      const owner = image_management_container.dataset.owner
      const field_name = image_management_container.dataset.field_name || "images"

      function create_image_inputs() {
        const time: number = new Date().getTime()
        const image_inputs_section = document.createElement("div")
        image_inputs_section.classList.add("field", "new-image", "border-gray-500", "border-b")

        const radio_set = `
        <div class="radio-set my-3"><input type="radio" id="radio_image_upload_${time}" name="image_type_${time}" value="upload" data-image-id="${time}" checked>
          <label for="radio_image_upload_${time}">Upload an Image File</label>
          <input type="radio" id="radio_image_url_${time}" name="image_type_${time}" value="url" data-image-id="${time}" class="ml-8">
          <label for="radio_image_url_${time}">Enter an Image URL</label>
        </div>`

        image_inputs_section.insertAdjacentHTML("beforeend", radio_set)

        const image_inputs = `
        <input type="file" accept="image/png,image/gif,image/jpeg,image/jpg,image/svg+xml" data-input-id="${time}" data-image-type="file" data-name="image_upload_${time}" name="${owner}[${field_name}_attributes][${time}][uploaded_file]" id="${owner}_${field_name}_attributes_${time}_uploaded_file">
        <input type="text" data-input-id="${time}" data-image-type="url" data-name="image_url_${time}" name="${owner}[${field_name}_attributes][${time}][url]" id="${owner}_${field_name}_attributes_${time}_url" class="hidden inline-block w-3/4 rhx-input rhx-text-input">
        <button type="button" data-name="image_url_${time}" class="button inline-block p-3 ml-2 hidden">Preview</button>`

        image_inputs_section.insertAdjacentHTML("beforeend", image_inputs)
        return image_inputs_section
      }

      const add_another_image_button: HTMLElement = image_management_container.querySelector(
        "[data-name=add-image-button]",
      )

      if (image_management_container.querySelectorAll(".field:not(.new-image)").length) {
        add_another_image_button.classList.remove("hidden")
      } else if (!image_management_container.querySelectorAll(".new-image").length) {
        image_management_container.appendChild(create_image_inputs())
      }

      add_another_image_button.addEventListener("click", (e) => {
        const image_inputs_section = create_image_inputs()
        image_management_container.appendChild(image_inputs_section)
        add_another_image_button.classList.add("hidden")
      })

      image_management_container.addEventListener("change", (e) => {
        const target = e.target as HTMLInputElement

        // toggle opacity on "Remove Image" checkbox click
        if (target.classList.contains("destroy-image")) {
          const image_id = target.dataset.imageId
          const opacity_class = "opacity-25"
          const image_classes = document.querySelector(`img[data-image-id="${image_id}"]`).classList

          if (target.checked) {
            image_classes.add(opacity_class)
          } else {
            image_classes.remove(opacity_class)
          }
        }

        // change image input type based on radio button selection (Upload Image or Enter URL)
        if (target.type === "radio") {
          // if we have an image preview when the radio is clicked, remove that image - and re-hide add another image button
          if (target.parentElement.parentElement.querySelector(".pending-image-container")) {
            target.parentElement.parentElement.querySelector(".pending-image-container").remove()
            add_another_image_button.classList.add("hidden")
          }

          let image_id = target.dataset.imageId

          const image_types = ["url", "upload"]
          image_types.forEach((type) => {
            // clear both inputs on click
            const associated_input: HTMLInputElement = document.querySelector(
              `input[data-name=image_${type}_${image_id}]`,
            )
            associated_input.value = ""

            if (type === target.value) {
              document.querySelectorAll(`[data-name=image_${type}_${image_id}]`).forEach((e) => {
                e.classList.remove("hidden")
              })
            } else {
              document.querySelectorAll(`[data-name=image_${type}_${image_id}]`).forEach((e) => {
                e.classList.add("hidden")
              })
            }
          })
        } // END condition for radio button click

        // if the change event is when a file is selected for upload or a url is entered
        if (target.dataset.imageType === "file" || target.dataset.imageType === "url") {
          if (target.value) {
            // TODO: if url, validate upstream??

            const image_field_div = target.parentElement
            //  create "clear image" button (to clear preview, value, alt text) and bind event listener
            const clear_image_button = document.createElement("button")
            clear_image_button.innerHTML = "Clear Image"
            clear_image_button.classList.add("button", "p-1", "ml-2", "inline-block")
            clear_image_button.type = "button"

            clear_image_button.addEventListener("click", (e) => {
              let pending_image_count = 0
              image_management_container
                .querySelectorAll("[data-image-type=file], [data-image-type=url]")
                .forEach((input: HTMLInputElement) => {
                  if (input.value) {
                    pending_image_count++
                  }
                })

              // if there's more than 1 pending image to upload and the clear button is clicked for one of the images OR if there's a previously uploaded image assoicated with the instance being edited, then just removed that whole image field div
              if (pending_image_count > 1 || image_management_container.querySelector("figure")) {
                image_field_div.remove()
              }
              // otherwise, it's the first image being added for this instance, the we still want to remove the preview, but show the default starting image inputs
              else {
                // hitting the clear button when you have another set of image inputs open, but haven't added any inputs to it yet
                let empty_inputs_count = 0
                image_management_container
                  .querySelectorAll("[data-image-type=file], [data-image-type=url]")
                  .forEach((input: HTMLInputElement) => {
                    if (!input.value.length) {
                      empty_inputs_count++
                    }
                  })

                const current_target = e.currentTarget as HTMLElement

                // since there are two field options per image input sets, if there are more than 2 empty ones, then that means that more than one set has empty inputs, in which case we want to just delete the whole container that contains the radio buttons and inputs
                if (empty_inputs_count > 2) {
                  current_target.parentElement.parentElement.remove()
                } else {
                  add_another_image_button.classList.add("hidden")
                  image_field_div.remove()
                  image_management_container.appendChild(create_image_inputs())
                }
              }
            }) // END clear image click events

            // delete any existing previews in existing pane before appending a preview
            target.parentElement.querySelectorAll(".pending-image-container").forEach((preview) => {
              preview.remove()
            })

            // create dynamic alt text div that's associated with new image upload
            const single_image_container = document.createElement("div")
            single_image_container.classList.add("pending-image-container")

            // create image preview
            let img
            if (target.dataset.imageType === "file") {
              img = `<img src="${URL.createObjectURL(
                target.files[0],
              )}" class="w-32 my-3 inline-block">`
            } else if (target.dataset.imageType === "url") {
              img = `<img src="${target.value}" class="w-32 my-3 inline-block">`
            }

            // add image preview to container
            single_image_container.insertAdjacentHTML("beforeend", img)

            // add "clear image" button to container
            single_image_container.appendChild(clear_image_button)

            // create alt text input for added image
            const input_id = target.dataset.inputId // unique "time" value from dynamic inputname
            const alt_text_div = `
            <div class="alt-text">
              <label for="${owner}_${field_name}_attributes_${input_id}_alt_text">Alt Text</label>
              <input class="rhx-input rhx-text-input" type="text" value="" name="${owner}[${field_name}_attributes][${input_id}][alt_text]"  id="${owner}_${field_name}_attributes_${input_id}_alt_text">
              <label for="${owner}_${field_name}_attributes_${input_id}_object_fit">Object Fit</label>
              <select class="rhx-input rhx-text-input" value="" name="${owner}[${field_name}_attributes][${input_id}][object_fit]"  id="${owner}_${field_name}_attributes_${input_id}_object_fit">
                <option value="contain">Contain</option>
                <option value="cover">Cover</option>
              </select>
              <label for="${owner}_${field_name}_attributes_${input_id}_tag_ids">Tags</label>
              <select class="autocomplete" multiple="multiple" name="${owner}[${field_name}_attributes][${input_id}][tag_ids][]"  id="${owner}_${field_name}_attributes_${input_id}_tag_ids" data-autocomplete-path="/rh/tags.json">
                <option value="">Type to search for tags...</option>
              </select>
            </div>`

            // add alt text input to container
            single_image_container.insertAdjacentHTML("beforeend", alt_text_div)

            // initialize tag editor
            const tag_editor_field = single_image_container.querySelector(
              `#${owner}_${field_name}_attributes_${input_id}_tag_ids`,
            ) as HTMLInputElement
            init(tag_editor_field)

            // add single image container to parent
            target.parentElement.appendChild(single_image_container)

            // once single_image_container has been added to the parent, then we can show the "add another image" button
            add_another_image_button.classList.remove("hidden")
            image_management_container.appendChild(add_another_image_button)
          } // end if target.value
        } // END (target.dataset.imageType === 'file' || target.dataset.imageType === 'url'
      }) // END image_management_container.addEventListener
    })

  // need to remove any images from being submitted if either the input file or URL fields are blank
  document.querySelectorAll("input[type=submit]").forEach((node) => {
    node.addEventListener("click", () => {
      document.querySelectorAll(".new-image").forEach((new_image_container: HTMLElement) => {
        let has_temp_images = false
        new_image_container
          .querySelectorAll("[data-image-type=file], [data-image-type=url]")
          .forEach((input: HTMLInputElement) => {
            if (input.value) {
              has_temp_images = true
            }
          })
        if (!has_temp_images) {
          new_image_container.remove()
        }
      })
    })
  })
}
