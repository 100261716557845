import Turbolinks from "turbolinks"
import "../src/util/Rails"

import image_management_container from "../src/image_management_container"
import job_apply_type_radio_set from "../src/job_apply_type_radio_set"
import url_status_toggler from "../src/url_status_toggler"
import ClipboardJS from "clipboard"
import CodeEditor from "../src/code_editor"
import TagEditor from "../src/tag_editor"
// import ProductSelect from "../src/product_select"
import SelectBox from "../src/select_box"

import LocalTime from "local-time"

import "../stylesheets/application.css"
import ReactOnRails from "react-on-rails"

const context = import.meta.glob("../src/components/**/*.{js,jsx,ts,tsx}", { eager: true })
const components = Object.keys(context).reduce((acc, path) => {
  const name = path.split("/").pop().split(".").shift()
  const mod = context[path]
  if (name) {
    if (typeof mod === "object" && "default" in mod) {
      acc[name] = mod.default
    }
  }
  return acc
}, {})

ReactOnRails.register(components)
Turbolinks.start()
LocalTime.start()

document.addEventListener("submit", () => Turbolinks.clearCache())

document.addEventListener(
  "turbolinks:load",
  () => {
    new ClipboardJS("[data-clipboard-target]")

    CodeEditor.setup()
    TagEditor.setup()
    // ProductSelect.setup()
    SelectBox.setup()

    document.querySelectorAll('.flash_wrapper [data-action="close"]').forEach((el) => {
      el.addEventListener("click", () => {
        el.parentElement.parentElement.classList.add("h-0")
        setTimeout(() => el.parentElement.parentElement.remove(), 100)
      })
      setTimeout(() => {
        el.parentElement.parentElement.classList.add("scale-y-0")
        setTimeout(() => el.parentElement.parentElement.remove(), 100)
      }, 10000)
    })

    document.querySelectorAll("[data-copy-to-clipboard]").forEach((node: HTMLElement) => {
      node.addEventListener("click", () => {
        // const copyText = node.nextSibling as HTMLInputElement
        const id = node.dataset.copyToClipboard
        console.log(id)
        const copyText = document.getElementById(id) as HTMLInputElement
        console.log(copyText)

        /* Select the text field */
        copyText.select()
        // copyText.setSelectionRange(0, 99999) /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand("copy")

        alert("Copied!")
      })
    })

    document.querySelectorAll('[data-name="login-form"]').forEach((form) => {
      form.addEventListener("ajax:before", (e) => {
        console.log("login!", e)
      })
    })

    image_management_container()
    url_status_toggler()
    job_apply_type_radio_set()

    document.addEventListener("click", (e) => {
      const target = e.target as HTMLElement
      document.querySelectorAll("[data-collapse-on-external-click]").forEach((el) => {
        if (!el.contains(target)) {
          const container = el.querySelector("[data-collapsible-content]")
          if (!container.classList.contains("hidden")) {
            container.classList.add("hidden")
          }
        }
      })
    })
    document.querySelectorAll("[data-collapsible]").forEach((node) => {
      node.querySelectorAll("[data-collapsible-click-target]").forEach((button) => {
        button.addEventListener("click", () => {
          const target = node.querySelector("[data-collapsible-content]")
          const collapsed = target.classList.contains("hidden")
          document.querySelectorAll("[data-collapsible-content]").forEach((collapsible) => {
            collapsible.classList.add("hidden")
          })
          if (collapsed) {
            target.classList.remove("hidden")
          }
        })
      })
    })

    document.addEventListener("keypress", (e: KeyboardEvent) => {
      if (e.key === "/") {
        const search_field: HTMLElement = document.querySelector("[data-primary-search]")
        const target = e.target as HTMLElement
        const tag_name = target.tagName
        if (
          target.isContentEditable ||
          tag_name == "INPUT" ||
          tag_name == "SELECT" ||
          tag_name == "TEXTAREA"
        ) {
          return
        }

        if (search_field) {
          search_field.focus()
          e.preventDefault()
        }
      }
    })
  } /*{ once: true }*/,
)

document.addEventListener("turbolinks:before-cache", (e) => {
  CodeEditor.teardown()
  TagEditor.teardown()
  // ProductSelect.teardown()
  SelectBox.teardown()
})
