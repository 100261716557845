import React, { useState, useEffect, useRef } from "react"
import { motion } from "framer-motion"

export default function CarouselSearchField({}) {
  const [value, setValue] = useState("")
  return (
    <motion.div>
      <label>
        <span>Search</span>
        <input type="text" value={value} onChange={(e) => setValue(e.target.value)} />
        {/* step 2: draw the rest of the owl */}
      </label>
    </motion.div>
  )
}
