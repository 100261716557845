import React, { useState, useRef, useEffect } from "react"
import { Popover } from "@headlessui/react"
import startCase from "lodash/startCase"

export default ({
  inputId,
  isOverridable = false,
  isOverridden,
  label,
  description,
  icon = "fa-question-circle",
  children
}: {
  inputId?: string
  isOverridable?: boolean
  isOverridden?: boolean
  label?: string
  description: string
  icon?: string,
  children?: React.ReactNode
}) => {
  label = label || startCase(inputId || "this field")
  const [isOverridingParent, setIsOverridingParent] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    if (!ref.current) ref.current = document.querySelector(`#${inputId}`)
  }, [])

  useEffect(() => {
    if (!ref.current) return
    const listener = (e) => setIsOverridingParent(!!e.target.value.trim())
    ref.current.addEventListener("input", listener)
    setIsOverridingParent(!!ref.current.value.trim())
    return () => ref.current.removeEventListener("input", listener)
  }, [])

  return (
    <Popover className="relative">
      <Popover.Button>
        {children || <>
        <i
          className={`fa ${icon} ${isOverridden || isOverridingParent ? "text-picton-blue" : ""}`}
          role="presentation"
        />
        <span className="sr-only">Detailed information about {label}</span>
        </>}
      </Popover.Button>

      <Popover.Panel className="absolute z-40 w-screen max-w-xl rounded bg-picton-blue p-2 text-gray-700">
        {(isOverridden || isOverridable) && (
          <strong>Currently Overriding: {(isOverridden || isOverridingParent).toString()}</strong>
        )}
        <p className="whitespace-pre-line">{description}</p>
      </Popover.Panel>
    </Popover>
  )
}
