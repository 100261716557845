import React, { Fragment, useEffect, useState } from "react"
import { Combobox } from "@headlessui/react"
import classNames from "classnames"
import { uniq, uniqBy } from "lodash-es"

export default function TagSelector({
  ownerType,
  ownerId,
  fieldName = "tag",
  initialTags = [],
  availableTags: initialAvailableTags = [],
  onChange,
}: {
  ownerType: string
  ownerId?: string | number
  fieldName?: "tag" | "group" | "keyword"
  initialTags?: Tag[]
  availableTags?: Tag[]
  onChange?: (tags: Tag[]) => void
}) {
  const [q, setQ] = useState("")
  const [availableTags, setAvailableTags] = useState([...initialTags, ...initialAvailableTags])
  const [selectedTags, setSelectedTags] = useState(initialTags)
  const qRe = new RegExp(q.replace(/\W+/g, ".*"), "ig")
  const mallId =
    window.location.pathname.match(/\/malls\/(\d+)/)?.[1] ||
    window.location.search.match(/mall_id=(\d+)/)?.[1]
  const filteredTags = uniqBy(
    [
      ...availableTags.filter((t) => t.name.toLowerCase() === q.toLowerCase()),
      ...availableTags.filter((t) =>
        t.name
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .toLowerCase()
          .startsWith(
            q
              .normalize("NFD")
              .replace(/\p{Diacritic}/gu, "")
              .toLowerCase(),
          ),
      ),
      ...availableTags.filter(
        (tag) =>
          tag.name
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, "")
            .toLowerCase()
            .match(qRe) && !selectedTags.find((t) => t.id === tag.id),
      ),
    ],
    "id",
  )

  useEffect(() => {
    if (!q) return
    fetch(
      `/rh/tags.json?q=${encodeURIComponent(q)}&page_size=100${mallId ? `&mall_id=${mallId}` : ""}`,
    ).then((res) => {
      res.json().then((data) => {
        setAvailableTags(
          uniqBy(
            [
              // exact match
              ...data.filter((t) => t.name.toLowerCase() === q.toLowerCase()),
              // diacritic insensitive match
              ...data.filter(
                (t) =>
                  t.name
                    .normalize("NFD")
                    .replace(/\p{Diacritic}/gu, "")
                    .toLowerCase() ===
                  q
                    .normalize("NFD")
                    .replace(/\p{Diacritic}/gu, "")
                    .toLowerCase(),
              ),
              // exact starts with
              ...data.filter((t) => t.name.toLowerCase().startsWith(q.toLowerCase())),
              // diacritic insensitive starts with
              ...data.filter((t) =>
                t.name
                  .normalize("NFD")
                  .replace(/\p{Diacritic}/gu, "")
                  .toLowerCase()
                  .startsWith(
                    q
                      .normalize("NFD")
                      .replace(/\p{Diacritic}/gu, "")
                      .toLowerCase(),
                  ),
              ),
              // exact includes
              ...data.filter((t) => t.name.toLowerCase().includes(q.toLowerCase())),
              // diacritic insensitive includes
              ...data.filter((t) =>
                t.name
                  .normalize("NFD")
                  .replace(/\p{Diacritic}/gu, "")
                  .toLowerCase()
                  .includes(
                    q
                      .normalize("NFD")
                      .replace(/\p{Diacritic}/gu, "")
                      .toLowerCase(),
                  ),
              ),
              // ignore non-word characters
              ...data.filter((t) =>
                t.name
                  .normalize("NFD")
                  .replace(/\p{Diacritic}/gu, "")
                  .toLowerCase()
                  .match(qRe),
              ),
            ],
            "id",
          ),
        )
      })
    })
  }, [q])

  useEffect(() => {
    onChange?.(selectedTags)
  }, [selectedTags])

  return (
    <Combobox<Tag>
      value={selectedTags}
      onChange={(tags) => {
        setSelectedTags(tags)
        setQ("")
      }}
      multiple
    >
      <div
        className={classNames([
          "focus-within:ring-2 focus-within:ring-picton-blue/70",
          "rounded border border-gray-400 p-2 shadow-inner",
          "dark:border-gray-600 dark:bg-gray-800 dark:text-gray-300",
          "relative",
        ])}
      >
        <ul className="flex flex-wrap items-center gap-2 px-2">
          {selectedTags.map((tag) => (
            <li key={tag.id} className="rounded-full bg-picton-blue px-2 py-1 text-sm text-white">
              {tag.name}
              <button
                type="button"
                className="ml-2"
                onClick={() => setSelectedTags(selectedTags.filter((t) => t.id !== tag.id))}
              >
                <span className="sr-only">Remove {tag.name}</span>
                <i className="fa fa-times opacity-50" />
              </button>
            </li>
          ))}
          <li className="inline-flex grow">
            <Combobox.Input
              className={classNames([
                "w-full bg-transparent focus:outline-none",
                "disabled:bg-gray-300 disabled:italic disabled:text-gray-600",
                "dark:disabled:bg-gray-600 dark:disabled:text-gray-400",
                "placeholder-gray-500 placeholder-opacity-60 focus:placeholder-opacity-0",
              ])}
              value={q}
              onChange={(e) => setQ(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Backspace" && !q) {
                  setSelectedTags(selectedTags.slice(0, -1))
                }
              }}
              placeholder="Type to search..."
            />
          </li>
        </ul>
        <Combobox.Options
          className={classNames([
            "scrollbars-hidden z-50 max-h-64 overflow-y-scroll",
            "rounded border border-gray-400 py-2 shadow-inner",
            "dark:border-gray-60 bg-white dark:bg-gray-800 dark:text-gray-300",
            "absolute inset-x-0 top-full",
          ])}
        >
          {filteredTags.map((tag) => (
            <Combobox.Option key={tag.id} value={tag} as={Fragment}>
              {({ active, selected }) => (
                <li
                  title={
                    tag.context
                      ? `Tag Owner: ${tag.context.friendly_name} [${tag.context.id}]`
                      : null
                  }
                  className={classNames([
                    "cursor-pointer px-4 py-2",
                    "flex items-center justify-between",
                    active && "bg-picton-blue/30",
                    selected && "bg-picton-blue/70",
                  ])}
                >
                  <span>
                    {tag.name}
                    {selected && <i className="fa fa-check ml-2" />}
                  </span>
                  {/* <span className="opacity-30 duration-300 hover:opacity-50">
                    {tag.context?.friendly_name}
                  </span> */}
                </li>
              )}
            </Combobox.Option>
          ))}
          {q && !filteredTags.length && (
            <li className="p-2">
              No results for <strong>{q}</strong>.
              {/* <span>
                {" "}
                <a href={`/rh/tags/new?name=${q}`} target="_blank" className="text-picton-blue">
                  Create
                </a>{" "}
                it?
              </span> */}
            </li>
          )}
          {!q && !filteredTags.length && <li className="p-2">Type to search...</li>}
        </Combobox.Options>
      </div>
      {selectedTags.map(({ id }) => (
        <input type="hidden" name={`${ownerType}[${fieldName}_ids][]`} value={id} key={id} />
      ))}
    </Combobox>
  )
}

type Tag = {
  id: number | string
  name: string
  context?: {
    id: number | string
    friendly_name: string
  }
}
