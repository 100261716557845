import React, { Fragment, useEffect, useState } from "react"
import classNames from "classnames"
import { Combobox } from "@headlessui/react"
import { uniqBy } from "lodash-es"
import Rails from "../util/Rails"

function RetailerSelector({
  selectedRetailer: initialRetailer = null,
  ownerType,
  initialRetailers = [],
  formId,
}: Props) {
  const [changed, setChanged] = useState(false)
  const [searching, setSearching] = useState(false)
  const [q, setQ] = useState("")
  const [verified, setVerified] = useState(true)
  const [availableRetailers, setAvailableRetailers] = useState(initialRetailers)
  const [selectedRetailer, setSelectedRetailer] = useState<Retailer | null>(initialRetailer)

  const filteredRetailers = q
    ? availableRetailers.filter((retailer) => retailer.name.toLowerCase().includes(q.toLowerCase()))
    : availableRetailers

  useEffect(() => {
    if (!q) return
    setSearching(true)
    fetch(`/rh/retailers.json?q=${q}${verified ? "&verified=true" : ""}`).then((res) => {
      res.json().then((data) => {
        setAvailableRetailers((prev) =>
          uniqBy(
            [
              ...data.filter((t) => t.name.toLowerCase().startsWith(q.toLowerCase())),
              ...data.filter((t) => t.name.toLowerCase().includes(q.toLowerCase())),
              ...prev,
            ],
            "id",
          ),
        )
        setSearching(false)
      })
    })
  }, [q])

  const formRef = React.useRef<HTMLFormElement>(null)

  useEffect(() => {
    if (formId) {
      formRef.current = document.getElementById(formId) as HTMLFormElement
    }
  }, [formId])

  return (
    <Combobox
      value={selectedRetailer}
      onChange={(retailer: Retailer) => {
        setSelectedRetailer(retailer)
        const form = formRef.current
        if (form) {
          setChanged(true)
        }
      }}
      nullable
    >
      <div
        className={classNames([
          "focus-within:ring-2 focus-within:ring-picton-blue/70",
          "rounded border border-gray-400 p-2 shadow-inner",
          "dark:border-gray-600 dark:bg-gray-800 dark:text-gray-300",
          "relative",
        ])}
      >
        <Combobox.Input
          onChange={(e) => setQ(e.target.value)}
          displayValue={(retailer?: Retailer) => retailer?.name}
          className={classNames([
            "w-full bg-transparent focus:outline-none",
            "disabled:bg-gray-300 disabled:italic disabled:text-gray-600",
            "dark:disabled:bg-gray-600 dark:disabled:text-gray-400",
            "placeholder-gray-500 placeholder-opacity-60 focus:placeholder-opacity-0",
          ])}
          placeholder="Search for a retailer"
        />
        <Combobox.Button className="absolute right-2 top-2">
          <i className="fa fa-chevron-down" />
        </Combobox.Button>

        <Combobox.Options
          className={classNames([
            "scrollbars-hidden z-50 max-h-64 overflow-y-scroll",
            "rounded border border-gray-400 py-2 shadow-inner",
            "dark:border-gray-60 bg-white dark:bg-gray-800 dark:text-gray-300",
            "absolute inset-x-0 top-full",
          ])}
        >
          {filteredRetailers.map((retailer) => (
            <Combobox.Option key={retailer.id} value={retailer} as={Fragment}>
              {({ active, selected }) => (
                <li
                  className={classNames([
                    "cursor-pointer px-4 py-2",
                    "flex items-center justify-between gap-2",
                    active && "bg-picton-blue/30",
                    selected && "bg-picton-blue/70",
                  ])}
                >
                  <span className="inline-flex items-center gap-2">
                    {retailer.name}
                    {retailer.is_verified && (
                      <span className="flex items-center justify-center rounded-full bg-blue-500 p-0.5 text-gray-800">
                        <i className="fa fa-check" />
                      </span>
                    )}
                  </span>
                  {selected && <i className="fa fa-check" />}
                </li>
              )}
            </Combobox.Option>
          ))}

          {q && filteredRetailers.length === 0 && (
            <li className="cursor-pointer px-4 py-2">
              {searching ? `Searching...` : `No results for "${q}"`}
            </li>
          )}
        </Combobox.Options>
      </div>
      <div className="flex items-center justify-end">
        <label>
          <span className="">Verified Retailers</span>
          <input
            type="checkbox"
            className="ml-2"
            checked={verified}
            onChange={(e) => setVerified(e.target.checked)}
          />
        </label>
      </div>
      <input type="hidden" name={`${ownerType}[retailer_id]`} value={selectedRetailer?.id} />
      {changed && (
        <input
          type="submit"
          className="button"
          name="commit"
          value="preview"
          onClick={(e) => {
            Rails.fire(formRef.current, "submit")
          }}
        />
      )}
    </Combobox>
  )
}

export default RetailerSelector

type Props = {
  selectedRetailer?: Retailer
  initialRetailers?: Retailer[]
  ownerType: string
  onChange?: (retailer?: Retailer) => void
  formId?: string
}

type Retailer = {
  id: number | string
  name: string
  is_verified: boolean
}
