import React, { useEffect, useState } from "react"
import Rails from "../util/Rails"
import { useEditor, EditorContent } from "@tiptap/react"
import StarterKit from "@tiptap/starter-kit"
import Image from "./TipTapExtensions/Image"
import Embed from "./TipTapExtensions/Embed"
import Link from "@tiptap/extension-link"
import Placeholder from "@tiptap/extension-placeholder"
import Underline from "@tiptap/extension-underline"
import classNames from "classnames"
import Modal, { HorizontalGroup, LabeledInput, Select, TextInput } from "./Modal"
import { SingleImageUploader } from "./ImageLibrary"

const RichTextEditor = ({
  content = "",
  placeholder = "",
  inherited = "",
  onChange,
  enableInheritanceIndicator = false,
}: {
  content?: string
  placeholder?: string
  inherited?: string
  onChange?: (value: string) => void
  enableInheritanceIndicator?: boolean
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        heading: {
          levels: [1, 2, 3],
        },
      }),
      Image,
      Embed,
      Link.configure({
        openOnClick: false,
      }),
      Placeholder.configure({
        placeholder,
        showOnlyWhenEditable: false,
        showOnlyCurrent: false,
        includeChildren: true,
      }),
      Underline,
    ],
    content,
    onUpdate: (editorContent) => {
      onChange?.(editorContent.editor.getHTML())
    },
    editorProps: {
      handleDrop: (view, event, slice, moved) => {
        const okFormats = [
          "image/jpeg",
          "image/jpg",
          "image/png",
          "image/gif",
          // "image/apng",
          // "image/avif",
          // "image/svg+xml",
          // "image/webp",
        ]
        const maxMB = 10

        if (!moved && event?.dataTransfer?.files?.[0]) {
          Array.from(event.dataTransfer.files).forEach((file) => {
            let filesize = parseFloat((file.size / 1024 / 1024).toFixed(4)) // get the filesize in MB

            if (!okFormats.includes(file.type)) {
              alert(
                `File: "${file.name}" is not a supported filetype (${okFormats
                  .map((x) => x.replace("image/", ""))
                  .join(", ")})`,
              )
              return
            }
            if (filesize > maxMB) {
              alert(`File: "${file.name}" is too large (${maxMB}MB max)`)
              return
            }

            let img = document.createElement("img") /* global Image */
            img.src = URL.createObjectURL(file)
            img.onload = function () {
              const body = new FormData()
              body.append("image[uploaded_file]", file)

              fetch("/rh/images.json", {
                method: "POST",
                headers: {
                  "X-CSRF-Token": Rails.csrfToken(),
                },
                body,
              })
                .then((res) => res.json())
                .then((data) => {
                  const { schema } = view.state
                  const coordinates = view.posAtCoords({
                    left: event.clientX,
                    top: event.clientY,
                  })
                  const node = schema.nodes.customImage.create({ src: data.url }) // creates the image element
                  const transaction = view.state.tr.insert(coordinates.pos, node) // places it in the correct position
                  return view.dispatch(transaction)
                })
                .catch((err) => {
                  console.error(err)
                  alert(`An error occured while attempting to upload file: "${file.name}"`)
                })
                .finally(() => {})
            }
          })
          return true // handled
        }
        return false
      },
    },
  })

  return (
    <div className="relative flex flex-col">
      <div className="sticky top-0 z-30 bg-white shadow-md dark:bg-gray-700">
        <MenuBar editor={editor} />
      </div>
      <div className="">
        {enableInheritanceIndicator && inherited && (
          <details className="relative">
            <summary className="text-sm font-semibold">Show Existing</summary>
            <div className="group absolute right-4 top-5">
              <button
                type="button"
                className="flex items-center gap-2 p-2 text-sm hover:text-picton-blue/70 focus:text-picton-blue/70 active:text-picton-blue/100"
                onClick={async () => {
                  try {
                    await navigator.clipboard.write([
                      new ClipboardItem({
                        "text/html": new Blob([inherited], { type: "text/html" }),
                        "text/plain": new Blob([inherited], { type: "text/plain" }),
                      }),
                    ])
                    console.log("Content copied to clipboard")
                  } catch (err) {
                    console.error("Failed to copy: ", err)
                  }
                }}
              >
                <span className="origin-right scale-x-0 transition group-hover:scale-x-100 group-focus:scale-x-100">
                  Copy the original
                </span>
                <span className="">
                  <i className="fa fa-copy" />
                </span>
              </button>
            </div>
            <div
              className="h-full w-full space-y-6 border border-black/30 px-4 py-8 [&_a]:text-picton-blue"
              dangerouslySetInnerHTML={{ __html: inherited }}
            />
          </details>
        )}
        <EditorContent
          editor={editor}
          className="border border-gray-500 p-2 ring-blue-500/30 focus-within:ring"
        />
      </div>
    </div>
  )
}

const Button = ({
  label = "",
  icon = <></>,
  active = false,
  onClick = (e) => {},
  ...props
}: {
  label: string
  icon: React.ReactElement
  active: boolean
  onClick: (e: React.FormEvent<HTMLButtonElement>) => void
}) => {
  return (
    <button
      type="button"
      className={classNames([
        "flex h-6 w-6 items-center justify-center rounded-sm ring-2",
        "bg-gray-300 hover:bg-gray-400 focus:bg-gray-400",
        "dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:bg-gray-700",
        active ? "ring-blue-500" : "ring-black",
      ])}
      name={label}
      onClick={onClick}
    >
      <span className="sr-only">{label}</span>
      {icon}
    </button>
  )
}

const ImageButton = ({ editor }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button
        onClick={(e) => {
          setOpen(true)
        }}
        active={editor.isActive("customImage")}
        label="image"
        icon={<i className="fa fa-file-image-o" role="presentation" title="image" />}
      />
      {open && <ImageDialog open={open} setOpen={setOpen} editor={editor} />}
    </>
  )
}

const ImageDialog = ({ open, setOpen, editor }) => {
  const el = { "data-size": "", src: "", alt: "" }
  const [element, setElement] = useState(editor.getAttributes("customImage") || el)
  const [updateImage, setUpdateImage] = useState(false)
  const onImageUploaded = (e) => {
    setElement({ ...element, src: e.url })
    setUpdateImage(false)
  }
  const confirm = () => {
    editor.chain().focus().setImage(element).run()
    return true
  }
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setElement(el)
          setOpen(false)
        }}
        title="Add an image"
        onConfirmClick={confirm}
        onConfirmClickDisabled={!element.src}
      >
        {updateImage || !element.src ? (
          <>
            <SingleImageUploader onImageUploaded={onImageUploaded} />
            <>
              {element.src && (
                <div>
                  <button type="button" className="button" onClick={() => setUpdateImage(false)}>
                    Cancel image update
                  </button>
                </div>
              )}
            </>
          </>
        ) : (
          <HorizontalGroup>
            <div>
              <img src={element.src} className="h-auto max-h-96 w-auto max-w-lg" />
            </div>
            <button type="button" className="button" onClick={() => setUpdateImage(true)}>
              Update image
            </button>
          </HorizontalGroup>
        )}
        <HorizontalGroup>
          <LabeledInput label="alt text">
            <TextInput
              value={element.alt}
              onChange={(e) => setElement({ ...element, alt: e.currentTarget.value })}
            />
          </LabeledInput>
          <LabeledInput label="size">
            <Select
              options={[
                { value: "intrinsic", label: "Intrinsic" },
                { value: "fullWidth", label: "Full Width" },
              ]}
              onChange={(e) => {
                setElement({ ...element, "data-size": e.currentTarget.value })
              }}
              value={element["data-size"]}
            />
          </LabeledInput>
        </HorizontalGroup>
      </Modal>
    </>
  )
}

const LinkDialog = ({ editor }) => {
  const el = { href: "", "data-component": "" }
  const [open, setOpen] = useState(false)
  const [element, setElement] = useState(el)
  /*
    isImage, getNode and getAttributes kinda suck and it would be better if we could do this inside Tip Tap rather than around it.
    isImage: determine type
    getNode: alias customImage link setters
    getAttributes: alias customImage attribute externally
  */
  const isImage = (editor) => editor?.view?.state?.selection?.node?.type?.name === "customImage"
  const getNode = (editor) => {
    if (isImage(editor))
      return {
        setLink: (element) => editor.chain().focus().setImageLink(element),
        unsetLink: () => editor.chain().focus().unsetImageLink(),
      }
    return editor.chain().focus().extendMarkRange("link")
  }
  const getAttributes = (editor) =>
    isImage(editor)
      ? { href: editor.getAttributes("customImage")?.["data-href"] }
      : editor.getAttributes("link")

  const confirm = () => {
    const { href } = element
    if (href === "") {
      getNode(editor).unsetLink().run()
      return true
    }
    getNode(editor).setLink(element).run()
    return true
  }

  return (
    <>
      <Button
        onClick={(e) => {
          setElement(getAttributes(editor) || el)
          setOpen(true)
        }}
        active={editor.isActive("link") || editor.isActive("customImage")}
        label="link"
        icon={<i className="fa fa-link" role="presentation" title="link" />}
      />
      <Modal
        open={open}
        onClose={() => {
          setElement(el)
          setOpen(false)
        }}
        title="Add a Link"
        onConfirmClick={confirm}
        onConfirmClickDisabled={false}
      >
        <HorizontalGroup>
          <LabeledInput label="url">
            <TextInput
              value={element.href}
              onChange={(e) => setElement({ ...element, href: e.currentTarget.value })}
            />
          </LabeledInput>
          {/* <LabeledInput label="display as">
            <Select
              options={[
                { value: "default", label: "Default" },
                { value: "button", label: "Button" },
              ]}
              onChange={(e) => {
                setElement({ ...element, "data-component": e.currentTarget.value })
              }}
              value={element["data-component"]}
            />
          </LabeledInput> */}
        </HorizontalGroup>
      </Modal>
    </>
  )
}

const EmbedButton = ({ editor }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button
        onClick={(e) => {
          setOpen(true)
        }}
        active={editor.isActive("customEmbed")}
        label="embed"
        icon={<i className="fa fa-code" role="presentation" title="embed" />}
      />
      {open && <EmbedDialog open={open} setOpen={setOpen} editor={editor} />}
    </>
  )
}

const EmbedDialog = ({ open, setOpen, editor }) => {
  const c = null
  const [config, setConfig] = useState(
    JSON.parse(editor.getAttributes("customEmbed")?.["data-embed"] || "null"),
  )
  const [ready, setReady] = useState(false)

  const confirm = () => {
    editor.chain().focus().setEmbed(config).run()
    return true
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setConfig(c)
          setOpen(false)
        }}
        title="Add an embed"
        onConfirmClick={confirm}
        onConfirmClickDisabled={!ready}
      >
        <HorizontalGroup>
          <LabeledInput label="name">
            <Select
              options={[
                { value: "", label: "Select Embed" },
                { value: "youtube", label: "Youtube" },
                { value: "jotform", label: "JotForm" },
                { value: "issuu", label: "Issuu" },
              ]}
              onChange={(e) => {
                setConfig(
                  e.currentTarget.value
                    ? {
                        name: e.currentTarget.value,
                      }
                    : null,
                )
              }}
              value={config?.name}
            />
          </LabeledInput>
          {config?.name === "youtube" ? (
            <YoutubeConfig config={config} setConfig={setConfig} setReady={setReady} />
          ) : config?.name === "jotform" ? (
            <JotFormConfig config={config} setConfig={setConfig} setReady={setReady} />
          ) : config?.name === "issuu" ? (
            <IssuuConfig config={config} setConfig={setConfig} setReady={setReady} />
          ) : (
            <></>
          )}
        </HorizontalGroup>
      </Modal>
    </>
  )
}

const YoutubeConfig = ({ config, setConfig, setReady }) => {
  useEffect(() => {
    if (
      config?.name === "youtube" &&
      (config.url?.match(/^https:\/\/.*\.?youtube\.com\/watch/) ||
        config.url?.startsWith("https://youtu.be") ||
        config.url?.includes("https://youtube.com/shorts"))
    )
      setReady(true)
    else setReady(false)
  }, [config])
  return (
    <>
      <LabeledInput label="url" stretch>
        <TextInput
          value={config?.url}
          onChange={(e) => setConfig({ ...config, url: e.currentTarget.value })}
          placeholder="https://www.youtube.com/watch?v=XXXXXXXXXXX"
        />
      </LabeledInput>
    </>
  )
}

const JotFormConfig = ({ config, setConfig, setReady }) => {
  const [innerConfig, setInnerConfig] = useState({
    ...config,
    scrolling: config.scrolling || false,
  })
  useEffect(() => {
    if (
      config?.name === "jotform" &&
      (config.url?.startsWith("https://www.jotform.com/form/") ||
        config.url?.startsWith("https://form.jotform.com/"))
    ) {
      setReady(true)
    } else setReady(false)
  }, [config])
  useEffect(() => {
    setConfig(innerConfig)
  }, [innerConfig])
  return (
    <div className="flex flex-grow flex-col gap-4">
      <LabeledInput label="url" stretch>
        <TextInput
          value={innerConfig?.url}
          onChange={(e) => setInnerConfig({ ...innerConfig, url: e.currentTarget.value })}
          placeholder="https://www.jotform.com/form/XXXXXXXXXXX"
        />
      </LabeledInput>
      <HorizontalGroup>
        <details>
          <summary>Advanced Options</summary>
          <div className="flex flex-grow flex-col gap-2 p-4">
            <HorizontalGroup>
              <LabeledInput label="no scrolling" inline>
                <input
                  type="radio"
                  name="scrolling"
                  checked={!innerConfig.scrolling}
                  onChange={(e) => setInnerConfig({ ...innerConfig, scrolling: false })}
                />
              </LabeledInput>
              <LabeledInput label="scrolling" inline>
                <input
                  type="radio"
                  name="scrolling"
                  checked={innerConfig.scrolling}
                  onChange={(e) => setInnerConfig({ ...innerConfig, scrolling: true })}
                />
              </LabeledInput>
            </HorizontalGroup>
          </div>
        </details>
      </HorizontalGroup>
    </div>
  )
}

const IssuuConfig = ({ config, setConfig, setReady }) => {
  //issuu.com/boostcommediaas/docs/issuu-
  useEffect(() => {
    if (config?.name === "issuu" && config.url?.includes("issuu.com/")) setReady(true)
    else setReady(false)
  }, [config])
  return (
    <>
      <LabeledInput label="url" stretch>
        <TextInput
          value={config?.url}
          onChange={(e) => setConfig({ ...config, url: e.currentTarget.value })}
          placeholder="https://issuu.com/XXXXXXXXXXXXXXX/docs/XXXXXX"
        />
      </LabeledInput>
    </>
  )
}

const MenuBar = ({ editor }) => {
  if (!editor) return null

  return (
    <div className="flex items-center gap-2 p-2">
      <Button
        onClick={(e) => {
          editor.chain().focus().toggleBold().run()
        }}
        active={editor.isActive("bold")}
        label="bold"
        icon={<i className="fa fa-bold" role="presentation" title="bold" />}
      />
      <Button
        onClick={(e) => {
          editor.chain().focus().toggleItalic().run()
        }}
        active={editor.isActive("italic")}
        label="italic"
        icon={<i className="fa fa-italic" role="presentation" title="italic" />}
      />
      <Button
        onClick={(e) => {
          editor.chain().focus().toggleUnderline().run()
        }}
        active={editor.isActive("underline")}
        label="underline"
        icon={<i className="fa fa-underline" role="presentation" title="underline" />}
      />
      <Button
        onClick={(e) => {
          editor.chain().focus().toggleStrike().run()
        }}
        active={editor.isActive("strike")}
        label="strikethrough"
        icon={<i className="fa fa-strikethrough" role="presentation" title="strikethrough" />}
      />
      {/* <button type="button"
        className={classNames([
          "ring-2 w-6 h-6 rounded-sm flex items-center justify-center",
          "bg-gray-300 hover:bg-gray-400 focus:bg-gray-400",
          "dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:bg-gray-700",
          editor.isActive("code") ? "ring-blue-500" : "ring-black",
        ])}
        onClick={() => editor.chain().focus().toggleCode().run()}
      >
        <span className="sr-only">code</span>
        <i className="fa fa-code" role="presentation" title="code" />
      </button> */}
      <Button
        onClick={(e) => {
          editor.chain().focus().setParagraph().run()
        }}
        active={editor.isActive("paragraph")}
        label="paragraph"
        icon={<i className="fa fa-paragraph" role="presentation" title="paragraph" />}
      />
      <Button
        onClick={(e) => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        active={editor.isActive("heading", { level: 1 })}
        label="lg heading"
        icon={<i className="fa fa-header" role="presentation" title="lg heading" />}
      />
      <Button
        onClick={(e) => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        active={editor.isActive("heading", { level: 2 })}
        label="md heading"
        icon={
          <i
            className="fa fa-header origin-bottom-left scale-90"
            role="presentation"
            title="md heading"
          />
        }
      />
      <Button
        onClick={(e) => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        active={editor.isActive("heading", { level: 3 })}
        label="sm heading"
        icon={
          <i
            className="fa fa-header origin-bottom-left scale-75"
            role="presentation"
            title="sm heading"
          />
        }
      />
      <Button
        onClick={(e) => editor.chain().focus().toggleBulletList().run()}
        active={editor.isActive("bulletList")}
        label="unordered list"
        icon={<i className="fa fa-list-ul" role="presentation" title="unordered list" />}
      />
      <Button
        onClick={(e) => editor.chain().focus().toggleOrderedList().run()}
        active={editor.isActive("orderedList")}
        label="ordered list"
        icon={<i className="fa fa-list-ol" role="presentation" title="ordered list" />}
      />

      {/* <button type="button"
        className={classNames([
          "ring-2 w-6 h-6 rounded-sm flex items-center justify-center",
          "bg-gray-300 hover:bg-gray-400 focus:bg-gray-400",
          "dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:bg-gray-700",
          editor.isActive("codeBlock") ? "ring-blue-500" : "ring-black",
        ])}
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
      >
        <span className="sr-only">code block</span>
        <i className="fa fa-code" role="presentation" title="code block" />
      </button> */}
      <Button
        onClick={(e) => editor.chain().focus().toggleBlockquote().run()}
        active={editor.isActive("blockquote")}
        label="block quote"
        icon={<i className="fa fa-quote-left" role="presentation" title="block quote" />}
      />
      <Button
        onClick={(e) => editor.chain().focus().setHorizontalRule().run()}
        active={editor.isActive("horizontalRule")}
        label="horizontal rule"
        icon={<i className="fa fa-minus" role="presentation" title="horizontal rule" />}
      />
      <LinkDialog editor={editor} />
      <ImageButton editor={editor} />
      <EmbedButton editor={editor} />
      <Button
        onClick={(e) => editor.chain().focus().undo().run()}
        active={editor.isActive("undo")}
        label="undo"
        icon={<i className="fa fa-undo" role="presentation" title="undo" />}
      />
      <Button
        onClick={(e) => editor.chain().focus().redo().run()}
        active={editor.isActive("redo")}
        label="redo"
        icon={<i className="fa fa-undo scale-x-[-1]" role="presentation" title="redo" />}
      />
      {/* <button type="button" onClick={() => editor.chain().focus().unsetAllMarks().run()}>clear marks</button> */}
      {/* <button type="button" onClick={() => editor.chain().focus().clearNodes().run()}>clear nodes</button> */}
      {/* <button type="button" onClick={() => editor.chain().focus().setHardBreak().run()}>hard break</button> */}
    </div>
  )
}

export default RichTextEditor
