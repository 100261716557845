import Turbolinks from "turbolinks"

export default function url_status_toggler() {
  const default_status_values: string[] = []
  let url_status_values = []
  let display_default_statuses = false
  const url_status_param = new URLSearchParams(location.search).getAll("status")[0]

  if (url_status_param) {
    url_status_values = url_status_param.split(",")
  } else {
    display_default_statuses = true
    url_status_values = default_status_values
  }

  document
    .querySelectorAll("[data-name=toggle_status_button]")
    .forEach((toggle_status_button: HTMLElement) => {
      if (toggle_status_button.dataset.defaultStatus === "true") {
        default_status_values.push(toggle_status_button.dataset.status)
        if (display_default_statuses) {
          toggle_status_button.classList.remove("inactive")
        }
      }

      if (
        !display_default_statuses &&
        url_status_values.includes(toggle_status_button.dataset.status)
      ) {
        toggle_status_button.classList.remove("inactive")
      }

      toggle_status_button.addEventListener("click", (e) => {
        update_filter_params((e.target as HTMLElement).dataset.status)
      })
    })

  const update_filter_params = (status_to_toggle: string) => {
    if (url_status_values.includes(status_to_toggle)) {
      url_status_values.splice(url_status_values.indexOf(status_to_toggle), 1)
    } else {
      url_status_values.push(status_to_toggle)
    }
    update_page(url_status_values)
  }

  const update_page = (statuses_to_show: string[]) => {
    const url_params = new URLSearchParams(new URL(location.href).search)
    url_params.delete("status")
    if (statuses_to_show.length) {
      url_params.append("status", statuses_to_show.join(","))
    }
    Turbolinks.visit(`?${decodeURIComponent(url_params.toString())}`)
  }
}
