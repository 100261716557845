import React, { useState, useEffect, createContext, useContext, ComponentProps } from "react"
import { Reorder } from "framer-motion"
import { v4 as uuid } from "uuid"
import dayjs from "dayjs"
import { DatePicker, LocalDateTime } from "./DatePicker"
import { I18nRichTextFieldTabs } from "./I18nRichTextFieldTabs"
import { I18nFieldTabs } from "./I18nFieldTabs"
import Modal, { TextInput, Select, HorizontalGroup, LabeledInput } from "./Modal"
import { isEqual } from "lodash-es"
import CarouselEditor, { ChooseCarousel } from "./CarouselEditor"
import DeckEditor, { ChooseDeck } from "./DeckEditor"
import FieldExplanationTooltip from "./FieldExplanationTooltip"
import classNames from "classnames"

type Section = {
  id?: number
  name?: string
  key: string
  component_type?: string
  component: Record<string, any>
}

const PageContext = createContext<{
  sections: Section[]
  setSections: React.Dispatch<React.SetStateAction<Section[]>>
} | null>(null)
export const usePageContext = () => useContext(PageContext)

export default function SectionsEditor({
  componentTypes = [],
  sections = [],
  siteId,
  colorPalette,
  imageTags = [],
  reorderable = true,
  locales = ["en"],
}) {
  const [sectionsState, setSectionsState] = useState(
    sections.map((section) => ({ ...section, key: uuid() })),
  )
  const [creatingSection, setCreatingSection] = useState(false)
  const [editingSection, setEditingSection] = useState(null)
  const editSection = (section) => setEditingSection(section)
  const [editingSectionContent, setEditingSectionContent] = useState(null)
  const editSectionContent = (section) => setEditingSectionContent(section)

  return (
    <>
      {sectionsState
        .filter((s) => (s._destroy && s.id) || !s._destroy)
        .map(({ key, status, images = [], ...s }, i) => (
          <React.Fragment key={key}>
            <input
              type="hidden"
              name={`page[sections_attributes][]`}
              value={JSON.stringify({ ...s, position: i + 1 })}
            />
          </React.Fragment>
        ))}
      <PageContext.Provider
        value={{
          sections: sectionsState,
          setSections: setSectionsState,
        }}
      >
        <div className="flex flex-col items-stretch gap-y-2">
          <div className="flex items-center gap-1 px-2">
            {!reorderable ? (
              <FieldExplanationTooltip description="You may edit content for existing sections, but reordering, adding, and deleting sections is locked for this page.">
                <span className="inline-flex items-center gap-2">
                  <i className="fa fa-lock" />
                  <span className="cursor-default font-semibold opacity-50">Add new section +</span>
                </span>
              </FieldExplanationTooltip>
            ) : (
              <button
                type="button"
                className={classNames([
                  "inline-flex items-center gap-2 self-start px-2",
                  !reorderable && "opacity-50",
                ])}
                onClick={(e) => {
                  e.preventDefault()
                  setCreatingSection(true)
                }}
                disabled={!reorderable}
              >
                <span className="font-semibold">Add new section +</span>
              </button>
            )}
          </div>
          {!reorderable ? (
            <div className="px-2 text-sm opacity-70">
              Adding, removing, and reordering is disabled for generated pages.
            </div>
          ) : null}
          <Reorder.Group
            axis="y"
            values={sectionsState}
            onReorder={setSectionsState}
            className="space-y-2"
          >
            {sectionsState.map((section) => (
              <SectionItem
                key={section.key}
                value={section}
                whileDrag={{ zIndex: 9999, scale: 1.01 }}
                reorderable={reorderable}
              >
                <SectionPlaceholder
                  section={section}
                  editSection={editSection}
                  editSectionContent={editSectionContent}
                  sections={sectionsState}
                  setSections={setSectionsState}
                  reorderable={reorderable}
                  locales={locales}
                />
              </SectionItem>
            ))}
          </Reorder.Group>
        </div>
        {creatingSection && (
          <ComponentSelectionDialog
            open={creatingSection}
            setOpen={setCreatingSection}
            setSectionsState={setSectionsState}
            siteId={siteId}
            locales={locales}
            componentTypes={componentTypes}
          />
        )}
        {editingSection && (
          <EditorDialog section={editingSection} setEditingSection={setEditingSection} />
        )}
        {editingSectionContent && (
          <ContentEditorDialog
            section={editingSectionContent}
            setEditingSection={setEditingSectionContent}
            colorPalette={colorPalette}
            imageTags={imageTags}
            locales={locales}
          />
        )}
      </PageContext.Provider>
    </>
  )
}

const SectionPlaceholder = ({
  section,
  editSection,
  editSectionContent,
  sections,
  setSections,
  reorderable = true,
  locales,
}) => {
  return (
    <>
      {section.component_type === "PageHeading" ? (
        <PageHeadingSection
          section={section}
          sections={sections}
          setSections={setSections}
          locales={locales}
        />
      ) : (
        <StandardSectionPlaceholder
          section={section}
          editSection={editSection}
          editSectionContent={editSectionContent}
          sections={sections}
          setSections={setSections}
          reorderable={reorderable}
        />
      )}
    </>
  )
}

const StandardSectionPlaceholder = ({
  section,
  editSection,
  editSectionContent,
  sections,
  setSections,
  reorderable = true,
}) => {
  const [status, setStatus] = useState(section.status)
  useEffect(() => {
    let newStatus = section.status
    if (section.base_status.toString() === "0") newStatus = "draft"
    if (section.base_status.toString() === "1") newStatus = "live"
    if (section.base_status.toString() === "1" && dayjs(section.published_at).isAfter(dayjs()))
      newStatus = "scheduled"
    if (
      section.base_status.toString() === "1" &&
      section.expired_at &&
      dayjs(section.expired_at).isBefore(dayjs())
    )
      newStatus = "expired"

    setStatus(newStatus)
  }, [section])

  if (section._destroy) return null

  return (
    <div className="rounded-sm border border-blue-500/70 bg-white px-4 py-2 dark:bg-gray-700">
      <div className="flex justify-between">
        <div className="flex gap-4">
          <div className="flex w-48 flex-col justify-around">
            <button
              type="button"
              disabled={!reorderable}
              className="overflow-hidden text-ellipsis capitalize"
              onClick={() => editSection(section)}
            >
              {section.name || `Section ${section.id || "[new]"}`}
            </button>
            <button
              type="button"
              className="flex flex-nowrap items-center justify-center gap-2 rounded-md bg-black/20 p-1 text-center font-mono text-xs"
              onClick={() => editSectionContent(section)}
            >
              {section.component_type === "RichTextBlock" ? (
                <i className="fa fa-code" />
              ) : section.component_type === "Carousel" ? (
                <i className="fa fa-picture-o" />
              ) : (
                <i className="fa fa-cube" />
              )}
              <span>{section.component_type}</span>
            </button>
          </div>
          <div className="flex flex-col items-start justify-around">
            <button
              disabled={!reorderable}
              className={classNames(["flex items-center gap-1", !reorderable && "opacity-50"])}
              type="button"
              onClick={(e) => {
                editSection(section)
              }}
            >
              <span>Edit Settings</span>
              <i className="fa fa-cog" />
            </button>
            <button
              className="flex items-center gap-1"
              type="button"
              onClick={(e) => {
                editSectionContent(section)
              }}
            >
              <span>Edit Content</span>
              <i className="fa fa-edit" />
            </button>
          </div>
        </div>
        <div className="flex space-x-2">
          <div className="flex items-center">
            <button
              type="button"
              className={`status-badge ${status}`}
              disabled={!reorderable}
              onClick={(e) => {
                e.preventDefault()
                editSection(section)
              }}
            >
              {status}
            </button>
          </div>
          <div className="hidden w-64 flex-col justify-around lg:flex">
            <div>
              <span>Display: </span>
              <span className="font-normal">
                {section.published_at ? (
                  <LocalDateTime date={section.published_at} />
                ) : (
                  "unpublished"
                )}
              </span>
            </div>
            <div>
              <span>Expires: </span>
              <span className="font-normal">
                {section.expired_at ? <LocalDateTime date={section.expired_at} /> : "never"}
              </span>
            </div>
          </div>
          <div className="flex gap-1">
            <button
              type="button"
              disabled={!reorderable}
              onClick={(e) => {
                e.preventDefault()
                if (confirm("you sure?"))
                  setSections(
                    sections.map((s) => (s.key === section.key ? { ...s, _destroy: "1" } : s)),
                  )
              }}
            >
              {reorderable ? <i className="fa fa-trash" /> : <i className="fa fa-lock" />}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const PageHeadingSection = ({ section, sections, setSections, locales }) => {
  const fieldName = "text"
  const tabs = locales.map((locale) => {
    return {
      locale: locale,
      fields: [
        {
          label: fieldName,
          name: [fieldName, locale].join("_"),
          value: section.component.translations?.[locale]?.[fieldName] || "",
        },
      ],
    }
  })
  const onChange = (values) => {
    const translations = Object.fromEntries(
      Object.keys(values)
        .filter((k) => values[k] && k)
        .map((k) => {
          return [k.replace(`${fieldName}_`, ""), { [fieldName]: values[k] }]
        }),
    )
    setSections(
      sections.map((s) =>
        s.key === section.key
          ? {
              ...section,
              component: {
                ...section.component,
                translations,
                [fieldName]: translations[section.component?.default_locale]?.[fieldName] || "",
              },
            }
          : s,
      ),
    )
  }
  return (
    <div className="rounded-sm border border-blue-500/70 bg-white py-3 dark:bg-gray-700">
      <div className="flex flex-col justify-between gap-3 lg:flex-row lg:gap-4">
        <div className="flex flex-col justify-around px-3 lg:w-48">
          <span className="flex flex-nowrap items-center justify-center gap-2 rounded-md bg-black/20 p-1 text-center font-mono text-xs">
            <i className="fa fa-header" />
            <span>{section.component_type}</span>
          </span>
        </div>
        <div className="flex flex-grow flex-col gap-2 lg:flex-row">
          <div className="flex flex-grow flex-col pt-3">
            <I18nFieldTabs tabs={tabs} onChange={onChange} omitLabels={true} />
          </div>
          <label className="flex items-center gap-2" style={{ padding: "0px 1em" }}>
            <span className="align-top capitalize">screen reader only</span>
            <input
              type="checkbox"
              checked={section.component.is_screen_reader_only}
              onChange={(e) => onChange({ is_screen_reader_only: e.currentTarget.checked })}
            />
          </label>
        </div>
      </div>
    </div>
  )
}

const SectionItem = ({
  reorderable = true,
  ...props
}: ComponentProps<typeof Reorder.Item> & { reorderable?: boolean }) =>
  reorderable ? <Reorder.Item {...props} /> : <li>{props.children}</li>

const ComponentSelectionDialog = ({
  open,
  componentTypes = [],
  setOpen,
  setSectionsState,
  siteId,
  locales,
  ...props
}) => {
  const [component, setComponent] = useState(null)
  const [selection, setSelection] = useState("")
  const [closeWarning, setCloseWarning] = useState(null)

  const confirm = () => {
    setSectionsState((prev) => [component, ...prev])
    return true
  }

  const candidates = [
    { value: "Carousel", label: "Carousel" },
    { value: "RichTextBlock", label: "Rich Text" },
    { value: "Deck", label: "Deck of Cards" },
  ]

  const options = [{ value: "", label: "Select Content Type" }].concat(
    candidates.filter((o) => componentTypes.includes(o.value)),
  )

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      title="Set up this section content"
      onConfirmClick={confirm}
      onConfirmClickDisabled={!component}
      warnOnClose={closeWarning}
    >
      <HorizontalGroup>
        <LabeledInput label="content type">
          <Select
            options={options}
            onChange={(e) => {
              setSelection(e.currentTarget.value)
            }}
            value={selection}
          />
        </LabeledInput>
        {selection === "Carousel" ? (
          <ChooseCarousel
            setComponent={setComponent}
            siteId={siteId}
            setCloseWarning={setCloseWarning}
          />
        ) : selection === "RichTextBlock" ? (
          <ChooseRichText
            setComponent={setComponent}
            setCloseWarning={setCloseWarning}
            locales={locales}
          />
        ) : selection === "Deck" ? (
          <ChooseDeck
            setComponent={setComponent}
            siteId={siteId}
            setCloseWarning={setCloseWarning}
          />
        ) : (
          <ChooseNone setCloseWarning={setCloseWarning} setComponent={setComponent} />
        )}
      </HorizontalGroup>
    </Modal>
  )
}

const ChooseNone = ({ setComponent, setCloseWarning }) => {
  useEffect(() => {
    setComponent(null)
    setCloseWarning(null)
  }, [])
  return <></>
}

const ChooseRichText = ({ setComponent, setCloseWarning, locales }) => {
  const translations = Object.fromEntries(locales.map((k) => [k, { body: "" }]))
  useEffect(() => {
    setComponent({
      key: uuid(),
      status: "draft",
      base_status: 0,
      component_type: "RichTextBlock",
      published_at: null,
      expired_at: null,
      component_id: null,
      component: { translations },
    })
    setCloseWarning("Discard Changes?")
  }, [])
  return <></>
}

const EditorDialog = ({ section, setEditingSection }) => {
  const [internalSection, setInternalSection] = useState(section)
  const { sections, setSections } = usePageContext()

  const [closeWarning, setCloseWarning] = useState(null)
  const warning = "Discard changes?"

  useEffect(() => {
    isEqual(section, internalSection) ? setCloseWarning(null) : setCloseWarning(warning)
  }, [internalSection])

  const [status, setStatus] = useState(null)
  useEffect(() => {
    if (!section) return
    let newStatus = section.status
    if (section.base_status.toString() === "0") newStatus = "draft"
    if (section.base_status.toString() === "1") newStatus = "live"
    if (section.base_status.toString() === "1" && dayjs(section.published_at).isAfter(dayjs()))
      newStatus = "scheduled"
    if (
      section.base_status.toString() === "1" &&
      section.expired_at &&
      dayjs(section.expired_at).isBefore(dayjs())
    )
      newStatus = "expired"

    setStatus(newStatus)
    setInternalSection(section)
  }, [section])

  const confirm = () => {
    const errors = validate(internalSection)
    if (Object.keys(errors).length) {
      alert(
        `Please fill in all required fields.\n${Object.values(errors)
          .map((e) => `• ${e}`)
          .join("\n")}`,
      )
      return false
    } else {
      setSections(sections.map((s) => (s.key === internalSection.key ? internalSection : s)))
      setEditingSection(null)
      setInternalSection(null)
      return true
    }
  }

  if (!internalSection) return null

  const onClose = () => {
    setEditingSection(null)
    setInternalSection(null)
  }

  return (
    <Modal
      open={!!section}
      onClose={onClose}
      title="Set up this section content"
      onConfirmClick={confirm}
      warnOnClose={closeWarning}
    >
      <HorizontalGroup>
        <LabeledInput label="name">
          <TextInput
            value={internalSection.name}
            onChange={(e) =>
              setInternalSection({ ...internalSection, name: e.currentTarget.value })
            }
          />
        </LabeledInput>
      </HorizontalGroup>
      <HorizontalGroup>
        <LabeledInput label="display at">
          <DatePicker
            selected={internalSection.published_at}
            onChange={(date) =>
              setInternalSection({
                ...internalSection,
                published_at: date,
                base_status: date ? "1" : "0",
              })
            }
          />
        </LabeledInput>
        <LabeledInput label="expire at">
          <DatePicker
            selected={internalSection.expired_at}
            onChange={(date) =>
              setInternalSection({
                ...internalSection,
                expired_at: date,
              })
            }
            placeholder="Never expire"
          />
        </LabeledInput>
        <LabeledInput label="status">
          <Select
            options={[
              { value: "0", label: "Draft" },
              { value: "1", label: "Published" },
            ]}
            onChange={(e) => {
              const published_at =
                e.currentTarget.value === "1"
                  ? new Date().toISOString()
                  : internalSection.published_at
              setInternalSection({
                ...internalSection,
                base_status: e.currentTarget.value,
                published_at,
              })
            }}
            value={internalSection.base_status}
          />
        </LabeledInput>
      </HorizontalGroup>
    </Modal>
  )
}
const ContentEditorDialog = ({ section, setEditingSection, colorPalette, imageTags, locales }) => {
  const [internalSection, setInternalSection] = useState(section)
  const { sections, setSections } = usePageContext()
  const [closeWarning, setCloseWarning] = useState(null)
  const [onConfirmClickDisabled, setOnConfirmClickDisabled] = useState(null)

  const SectionContentForm = sectionForms[internalSection?.component_type || "RichTextBlock"]

  useEffect(() => {
    setInternalSection(section)
  }, [section])
  if (!internalSection) return null

  const confirm = () => {
    const errors = validate(internalSection)
    if (Object.keys(errors).length) {
      alert(
        `Please fill in all required fields.\n${Object.values(errors)
          .map((e) => `• ${e}`)
          .join("\n")}`,
      )
      return false
    } else {
      setSections(sections.map((s) => (s.key === internalSection.key ? internalSection : s)))
      setEditingSection(null)
      return true
    }
  }

  return (
    <Modal
      open={!!section}
      onClose={() => setEditingSection(null)}
      title="Edit your Content"
      onConfirmClick={confirm}
      onConfirmClickDisabled={onConfirmClickDisabled}
      warnOnClose={closeWarning}
    >
      <SectionContentForm
        section={internalSection}
        setSection={setInternalSection}
        colorPalette={colorPalette}
        setCloseWarning={setCloseWarning}
        setOnConfirmClickDisabled={setOnConfirmClickDisabled}
        imageTags={imageTags}
        locales={locales}
      />
    </Modal>
  )
}

const CarouselForm = ({
  section,
  setCloseWarning,
  setOnConfirmClickDisabled,
  colorPalette,
  imageTags = [],
}) => (
  <CarouselEditor
    carouselId={section.component.id}
    setCloseWarning={setCloseWarning}
    setOnConfirmClickDisabled={setOnConfirmClickDisabled}
    colorPalette={colorPalette}
    imageTags={imageTags}
  />
)

const DeckForm = ({ section, setCloseWarning, setOnConfirmClickDisabled, imageTags = [] }) => (
  <DeckEditor
    deckId={section.component.id}
    setCloseWarning={setCloseWarning}
    setOnConfirmClickDisabled={setOnConfirmClickDisabled}
    imageTags={imageTags}
  />
)

const RichTextBlockForm = ({
  section,
  setSection,
  setCloseWarning,
  setOnConfirmClickDisabled,
  locales,
}) => {
  const fieldName = "body"
  const tabs = locales.map((locale) => {
    return {
      locale: locale,
      fields: [
        {
          label: fieldName,
          name: [fieldName, locale].join("_"),
          value: section.component.translations?.[locale]?.[fieldName] || "",
        },
      ],
    }
  })
  const onChange = (values) => {
    const translations = Object.fromEntries(
      Object.keys(values)
        .filter((k) => values[k] && k)
        .map((k) => {
          return [k.replace(`${fieldName}_`, ""), { [fieldName]: values[k] }]
        }),
    )
    setSection({
      ...section,
      component: {
        ...section.component,
        translations,
        body: translations[section.component?.default_locale]?.[fieldName] || "",
      },
    })
    setOnConfirmClickDisabled(false)
    setCloseWarning("Discard changes?")
  }
  useEffect(() => {
    setOnConfirmClickDisabled(true)
  }, [])
  return <I18nRichTextFieldTabs tabs={tabs} onChange={onChange} />
}

const sectionForms = {
  Carousel: CarouselForm,
  Deck: DeckForm,
  RichTextBlock: RichTextBlockForm,
}
const ComponentPaths = {
  Carousel: "/rh/carousels",
  RichTextBlock: "/rh/rich-text-blocks",
}

const validate = (section) => {
  const errors = {}
  switch (section?.component_type) {
    case "":
      // // background image
      // if (!section?.data?.backgroundImage) {
      //   errors["backgroundImage"] = "Background Image is required."
      // }
      break
    default:
      break
  }
  return errors
}
