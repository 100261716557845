import React, { Fragment, useEffect, useState } from "react"
import classNames from "classnames"
import { Combobox } from "@headlessui/react"
import { uniqBy } from "lodash-es"

function MallSelector({ selectedMall: initialMall, ownerType, initialMalls = [] }: Props) {
  const [q, setQ] = useState("")
  const [availableMalls, setAvailableMalls] = useState(initialMalls)
  const [selectedMall, setSelectedMall] = useState<Mall | null>(initialMall)

  const filteredMalls = q
    ? availableMalls.filter((mall) => mall.name.toLowerCase().includes(q.toLowerCase()))
    : availableMalls

  useEffect(() => {
    if (!q) return
    fetch(`/rh/malls.json?q=${q}`).then((res) => {
      res.json().then((data) => {
        setAvailableMalls(
          uniqBy(
            [
              ...data.filter((t) => t.name.toLowerCase().startsWith(q.toLowerCase())),
              ...data.filter((t) => t.name.toLowerCase().includes(q.toLowerCase())),
              ...availableMalls,
            ],
            "id",
          ),
        )
      })
    })
  }, [q])

  return (
    <Combobox value={selectedMall} onChange={setSelectedMall} nullable>
      <div
        className={classNames([
          "focus-within:ring-2 focus-within:ring-picton-blue/70",
          "rounded border border-gray-400 p-2 shadow-inner",
          "dark:border-gray-600 dark:bg-gray-800 dark:text-gray-300",
          "relative",
        ])}
      >
        <Combobox.Input
          onChange={(e) => setQ(e.target.value)}
          displayValue={(mall?: Mall) => mall?.name}
          className={classNames([
            "w-full bg-transparent focus:outline-none",
            "disabled:bg-gray-300 disabled:italic disabled:text-gray-600",
            "dark:disabled:bg-gray-600 dark:disabled:text-gray-400",
            "placeholder-gray-500 placeholder-opacity-60 focus:placeholder-opacity-0",
          ])}
          placeholder="Search for a mall"
        />
        <Combobox.Button className="absolute right-2 top-2">
          <i className="fa fa-chevron-down" />
        </Combobox.Button>

        <Combobox.Options
          className={classNames([
            "scrollbars-hidden z-50 max-h-64 overflow-y-scroll",
            "rounded border border-gray-400 py-2 shadow-inner",
            "dark:border-gray-60 bg-white dark:bg-gray-800 dark:text-gray-300",
            "absolute inset-x-0 top-full",
          ])}
        >
          {filteredMalls.map((mall) => (
            <Combobox.Option key={mall.id} value={mall} as={Fragment}>
              {({ active, selected }) => (
                <li
                  className={classNames([
                    "cursor-pointer py-2 px-4",
                    active && "bg-picton-blue/30",
                    selected && "bg-picton-blue/70",
                  ])}
                >
                  {mall.name}
                  {selected && <i className="fa fa-check" />}
                </li>
              )}
            </Combobox.Option>
          ))}
          {q && filteredMalls.length === 0 && (
            <li className="cursor-pointer py-2 px-4">No results for "{q}"</li>
          )}
        </Combobox.Options>
      </div>
      <input type="hidden" name={`${ownerType}[mall_id]`} value={selectedMall?.id} />
    </Combobox>
  )
}

export default MallSelector

type Props = {
  selectedMall?: Mall
  initialMalls?: Mall[]
  ownerType: string
  onChange?: (mall?: Mall) => void
}

type Mall = {
  id: number | string
  name: string
}
