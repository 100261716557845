import React, { useState, createRef } from "react"
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { TextInput } from "./Modal"
import LocalDateTime, {
  pretendZonedDateTimeIsLocal,
  useServerDateTimeFormat,
  useTimezone,
} from "./LocalDateTime"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
dayjs.extend(utc)
dayjs.extend(timezone)

type DatePickerOptions = {
  onChange: (e: any) => void
  selected: Date | ""
  placeholder?: string
  disabled?: boolean
  showTimeSelect?: boolean
  maxDate?: string
  format?: "date" | "dateTime"
  defaultHour?: number | undefined
  defaultMinute?: number | undefined
  defaultSecond?: number | undefined
}

type DatePickerForRailsOptions = {
  propertyPath?: string
  value: Date | ""
  onChange: (e: any) => void
  placeholder?: string
  disabled?: boolean
  showTimeSelect?: boolean
  maxDate?: string
  format?: "date" | "dateTime"
  defaultHour?: number | undefined
  defaultMinute?: number | undefined
  defaultSecond?: number | undefined
}

const DatePicker = ({
  onChange,
  selected,
  placeholder = "",
  disabled = false,
  showTimeSelect = true,
  maxDate,
  format: f = "dateTime",
  defaultHour,
  defaultMinute,
  defaultSecond,
}: DatePickerOptions) => {
  const format = useServerDateTimeFormat()
  const timezone = useTimezone()

  const datePickerRef = createRef<{
    setOpen: (e: boolean) => void
    setFocus: (e: boolean) => void
  }>()

  const innerOnChange = (date) => {
    let d = dayjs(date)
    if (Number.isInteger(defaultHour)) d = d.set("hour", defaultHour)
    if (Number.isInteger(defaultMinute)) d = d.set("minute", defaultMinute)
    if (Number.isInteger(defaultSecond)) d = d.set("second", defaultSecond)
    const stripped = d.format("YYYY-MM-DD H:mm")
    const zoned = dayjs.tz(stripped, timezone).toISOString()
    onChange(zoned)
  }
  return (
    <>
      {timezone && (
        <span className="flex">
          <ReactDatePicker
            ref={datePickerRef}
            selected={
              selected ? new Date(pretendZonedDateTimeIsLocal({ date: selected, timezone })) : ""
            }
            maxDate={
              maxDate ? new Date(pretendZonedDateTimeIsLocal({ date: maxDate, timezone })) : ""
            }
            onChange={innerOnChange}
            customInput={<TextInput />}
            showTimeSelect={showTimeSelect}
            timeIntervals={30}
            showMonthDropdown
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={10}
            placeholderText={disabled ? "disabled" : placeholder}
            dateFormat={format[`picker${f.charAt(0).toUpperCase()}${f.slice(1)}`]}
            timeFormat={format.pickerTime}
            disabled={disabled}
          />
          <span className="flex">
            <button
              type="button"
              disabled={disabled}
              onClick={() => {
                datePickerRef.current.setOpen(true)
                datePickerRef.current.setFocus(true)
              }}
            >
              <i className="fa fa-calendar-check-o ml-2 rounded-l border border-gray-400 bg-gray-300 p-3 hover:bg-gray-400" />
            </button>
            <button
              type="button"
              disabled={disabled}
              onClick={() => {
                onChange("")
              }}
            >
              <i className="fa fa-times rounded-r border border-gray-400 bg-gray-300 p-3 hover:bg-gray-400" />
            </button>
          </span>
        </span>
      )}
    </>
  )
}

const DatePickerForRails = ({
  propertyPath,
  value: initialValue,
  placeholder = "",
  disabled = false,
  showTimeSelect = true,
  maxDate,
  format: f = "dateTime",
  defaultHour,
  defaultMinute,
  defaultSecond,
  onChange,
}: DatePickerForRailsOptions) => {
  const [selected, setSelected] = useState(initialValue)
  const onChangeInternal = (date) => {
    onChange?.(date)
    setSelected(date)
  }
  return (
    <React.Fragment>
      <DatePicker
        onChange={onChangeInternal}
        selected={selected}
        placeholder={placeholder}
        disabled={disabled}
        showTimeSelect={showTimeSelect}
        maxDate={maxDate}
        format={f}
        defaultHour={defaultHour}
        defaultMinute={defaultMinute}
        defaultSecond={defaultSecond}
      />
      {propertyPath && (
        <input
          type="hidden"
          name={propertyPath}
          value={dayjs(selected).isValid() ? selected : ""}
        />
      )}
    </React.Fragment>
  )
}

export default DatePickerForRails
export { DatePicker, LocalDateTime }
