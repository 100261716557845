import React, { useState, useEffect, createContext, useContext, HtmlHTMLAttributes } from "react"
import { Reorder } from "framer-motion"
import { v4 as uuid } from "uuid"
import Modal, { TextInput, Select, HorizontalGroup, LabeledInput, Details, FieldSet } from "./Modal"
import dayjs from "dayjs"
import { DatePicker, LocalDateTime } from "./DatePicker"
import ImageLibrary from "./ImageLibrary"
import { isEqual } from "lodash-es"

export const CarouselContext = createContext({})
export const useCarouselContext = () => useContext(CarouselContext)

export default function ({ slides = [], colorPalette = {}, imageTags = [] }) {
  const [data, setData] = useState([])
  const onChange = (slideState) => {
    setData(slideState)
  }

  return (
    <>
      {data
        .filter((s) => (s._destroy && s.id) || !s._destroy)
        .map(({ key, status, images = [], ...s }) => (
          <React.Fragment key={key}>
            <input type="hidden" name={`carousel[slides_attributes][]`} value={JSON.stringify(s)} />
          </React.Fragment>
        ))}
      <SlideEditor
        slides={slides}
        colorPalette={colorPalette}
        imageTags={imageTags}
        onChange={onChange}
      />
    </>
  )
}

export function SlideEditor({
  slides = [],
  colorPalette = {},
  imageTags = [],
  onChange = (x) => {},
}) {
  const [slidesState, setSlidesState] = useState(slides.map((slide) => ({ ...slide, key: uuid() })))
  const [editingSlide, setEditingSlide] = useState(null)
  const editSlide = (slide) => setEditingSlide(slide)

  useEffect(() => {
    onChange(
      slidesState.map((s, i) => {
        return { ...s, position: i + 1 }
      }),
    )
  }, [slidesState])

  return (
    <CarouselContext.Provider
      value={{
        slides: slidesState,
        setSlides: setSlidesState,
        colorPalette,
        imageTags,
      }}
    >
      <div className="flex flex-col items-stretch space-y-2">
        <button
          className="self-start px-2 font-semibold"
          onClick={(e) => {
            e.preventDefault()
            const newSlide = {
              key: uuid(),
              status: "draft",
              base_status: 0,
              layout: "full-a",
              display_at: null,
              expired_at: null,
              data: {},
            }
            setSlidesState([newSlide, ...slidesState])
            setEditingSlide(newSlide)
          }}
        >
          Add new slide +
        </button>
        <Reorder.Group
          axis="y"
          values={slidesState}
          onReorder={setSlidesState}
          className="space-y-2"
        >
          {slidesState.map((slide) => (
            <Reorder.Item key={slide.key} value={slide}>
              <SlidePlaceholder
                key={slide.key}
                slide={slide}
                editSlide={editSlide}
                slides={slidesState}
                setSlides={setSlidesState}
              />
            </Reorder.Item>
          ))}
        </Reorder.Group>
      </div>
      {editingSlide && (
        <EditorDialog
          slide={editingSlide}
          // setSlides={setSlidesState}
          setEditingSlide={setEditingSlide}
        />
      )}
    </CarouselContext.Provider>
  )
}

export const SlidePlaceholder = ({ slide, editSlide, slides, setSlides }) => {
  const [status, setStatus] = useState(slide.status)
  useEffect(() => {
    let newStatus = slide.status
    if (slide.base_status.toString() === "0") newStatus = "draft"
    if (slide.base_status.toString() === "1") newStatus = "live"
    if (slide.base_status.toString() === "1" && dayjs(slide.display_at).isAfter(dayjs()))
      newStatus = "scheduled"
    if (
      slide.base_status.toString() === "1" &&
      slide.expired_at &&
      dayjs(slide.expired_at).isBefore(dayjs())
    )
      newStatus = "expired"

    setStatus(newStatus)
  }, [slide])

  if (slide._destroy) return null

  return (
    <div className="border border-blue-500 p-2">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          {slide.images?.length > 0 ? (
            <img src={slide.images[0].url} className="h-12 w-12 rounded-full" />
          ) : (
            <div className="flex h-12 items-center">
              <span>slide {slide.id}</span>
            </div>
          )}
          <div className="flex flex-col gap-1">
            <button
              onClick={(e) => {
                e.preventDefault()
                editSlide(slide)
              }}
            >
              Edit Slide
            </button>
            <button
              type="button"
              className="flex flex-nowrap items-center justify-center gap-2 rounded-md bg-black/20 p-1 text-center font-mono text-xs"
              onClick={() => editSlide(slide)}
            >
              <span>{slide.layout}</span>
            </button>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <button
            type="button"
            className={`status-badge ${status}`}
            onClick={() => editSlide(slide)}
          >
            {status}
          </button>
          <div className="hidden w-64 flex-col justify-around lg:flex">
            <div>
              <span>Display: </span>
              <span className="font-normal">
                {slide.display_at ? <LocalDateTime date={slide.display_at} /> : "unpublished"}
              </span>
            </div>
            <div>
              <span>Expires: </span>
              <span className="font-normal">
                {slide.expired_at ? <LocalDateTime date={slide.expired_at} /> : "never"}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-1">
            <button
              onClick={(e) => {
                e.preventDefault()
                if (confirm("you sure?"))
                  setSlides(slides.map((s) => (s.key === slide.key ? { ...s, _destroy: "1" } : s)))
              }}
            >
              <i className="fa fa-trash" />
            </button>
            {/* <button
              onClick={(e) => {
                e.preventDefault()
                setSlides([{ ...slide, id: null, key: uuid() }, ...slides])
              }}
            >
              <i className="fa fa-clone" />
            </button> */}
          </div>
        </div>
      </div>
    </div>
  )
}

const ColorPalette = ({ selected, onChange }) => {
  const { colorPalette } = useCarouselContext()
  return (
    <div className="flex flex-col space-y-2">
      <div className="flex flex-wrap gap-2 p-4">
        {Object.keys(colorPalette).map((color) => {
          const colorValue = colorPalette[color]
          return (
            <button
              key={color}
              className={`h-8 w-8 border border-black ${
                color === selected ? "ring ring-black" : ""
              }`}
              style={{ backgroundColor: colorValue }}
              onClick={() => onChange({ name: color, value: colorValue })}
            >
              <span className="sr-only" title={color}>
                {color}
              </span>
            </button>
          )
        })}
        <button
          className={`h-8 w-8 border border-black ${!selected ? "ring ring-black" : ""}`}
          style={{
            backgroundColor: "white",
            background: `linear-gradient(to top right,
             rgba(255,0,0,0) 0%,
             rgba(255,0,0,0) calc(50% - 2px),
             rgba(255,0,0,1) 50%,
             rgba(255,0,0,0) calc(50% + 2px),
             rgba(255,0,0,0) 100%)`,
          }}
          onClick={() => onChange(null)}
        >
          <span className="sr-only">Clear color</span>
        </button>
      </div>
    </div>
  )
}

export const EditorDialog = ({ slide, setEditingSlide }) => {
  const [internalSlide, setInternalSlide] = useState(slide)
  const { slides, setSlides } = useCarouselContext()
  const [closeWarning, setCloseWarning] = useState(null)
  const warning = "Discard changes?"

  useEffect(() => {
    isEqual(slide, internalSlide) ? setCloseWarning(null) : setCloseWarning(warning)
  }, [internalSlide])

  const [status, setStatus] = useState(null)
  useEffect(() => {
    if (!slide) return
    let newStatus = slide.status
    if (slide.base_status.toString() === "0") newStatus = "draft"
    if (slide.base_status.toString() === "1") newStatus = "live"
    if (slide.base_status.toString() === "1" && dayjs(slide.display_at).isAfter(dayjs()))
      newStatus = "scheduled"
    if (
      slide.base_status.toString() === "1" &&
      slide.expired_at &&
      dayjs(slide.expired_at).isBefore(dayjs())
    )
      newStatus = "expired"

    setStatus(newStatus)
    setInternalSlide(slide)
    setCloseWarning(null)
  }, [slide])

  const SlideForm = slideForms[internalSlide?.layout || "full-a"]

  if (!internalSlide) return null

  return (
    <Modal
      title="Edit Slide"
      open={!!slide}
      onClose={() => setEditingSlide(null)}
      warnOnClose={closeWarning}
      onConfirmClick={() => {
        const errors = validate(internalSlide)

        if (Object.keys(errors).length) {
          alert(
            `Please fill in all required fields.\n${Object.values(errors)
              .map((e) => `• ${e}`)
              .join("\n")}`,
          )
          return false
        } else {
          setSlides(slides.map((s) => (s.key === internalSlide.key ? internalSlide : s)))
          setEditingSlide(null)
          return true
        }
      }}
    >
      <div className="flex h-full flex-col items-stretch gap-2">
        <div className="flex-none">
          <div className="flex flex-col overflow-y-scroll">
            <div className="flex items-center justify-between gap-4 py-2">
              <LabeledInput label="Layout">
                <Select
                  options={[
                    { value: "full-a", label: "Full A" },
                    { value: "split-a", label: "Split A" },
                  ]}
                  value={internalSlide.layout || "full-a"}
                  onChange={(e) => {
                    setInternalSlide({ ...internalSlide, layout: e.currentTarget.value })
                  }}
                />
              </LabeledInput>
              <LabeledInput label="Status">
                <Select
                  options={[
                    { value: "0", label: "Draft" },
                    { value: "1", label: "Published" },
                  ]}
                  value={internalSlide.base_status}
                  onChange={(e) => {
                    const display_at =
                      e.currentTarget.value === "1"
                        ? new Date().toISOString()
                        : internalSlide.display_at
                    setInternalSlide({
                      ...internalSlide,
                      base_status: e.currentTarget.value,
                      display_at,
                    })
                  }}
                />
              </LabeledInput>
            </div>
            <HorizontalGroup>
              <LabeledInput label="Display At">
                <DatePicker
                  selected={internalSlide?.display_at}
                  onChange={(date) =>
                    setInternalSlide({
                      ...internalSlide,
                      display_at: date,
                      base_status: date ? "1" : "0",
                    })
                  }
                />
              </LabeledInput>
              <LabeledInput label="Expires At">
                <DatePicker
                  selected={internalSlide?.expired_at}
                  onChange={(date) =>
                    setInternalSlide({
                      ...internalSlide,
                      expired_at: date,
                    })
                  }
                  placeholder="Never expire"
                />
              </LabeledInput>
            </HorizontalGroup>
          </div>
        </div>
        <div className="min-h-0 flex-auto overflow-y-scroll">
          <SlideForm slide={internalSlide} setSlide={setInternalSlide} />
        </div>
      </div>
    </Modal>
  )
}

const SlideFormSplitA = ({ slide, setSlide }) => {
  return (
    <div className="flex flex-col gap-4">
      <SlideImageEdit slide={slide} setSlide={setSlide} />
      {/* <fieldset className="rounded border border-gray-500 p-2">
        <label>
          Background Image: {slide?.data?.backgroundImage}
          <ImageSelector
            selected={slide?.data?.backgroundImage}
            images={slide?.images || []}
            onChange={(id) => {
              return setSlide({ ...slide, data: { ...slide.data, backgroundImage: id } })
            }}
          />
          <Images
            images={slide?.images || []}
            onChange={(images) => {
              setSlide({ ...slide, image_ids: images.map((i) => i.id), images })
            }}
          />
        </label>

        <label>
          Background Color:
          <ColorPalette
            selected={slide?.data?.backgroundColor?.name}
            onChange={(color) =>
              setSlide({ ...slide, data: { ...slide.data, backgroundColor: color } })
            }
          />
        </label>
      </fieldset> */}
      <FieldSet legend="Messaging">
        <LabeledInput label="Title">
          <TextArea
            defaultValue={slide?.data?.title}
            onChange={(e) =>
              setSlide({ ...slide, data: { ...slide.data, title: e.currentTarget.value } })
            }
            className="w-full rounded border border-gray-400 p-2 placeholder-gray-500 placeholder-opacity-60 shadow-inner focus:border-gray-800 focus:placeholder-opacity-0 focus:outline-none disabled:bg-gray-300 disabled:italic disabled:text-gray-600 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-300 dark:disabled:bg-gray-600 dark:disabled:text-gray-400"
          />
        </LabeledInput>
        <LabeledInput label="Title Color">
          <ColorPalette
            selected={slide?.data?.titleColor?.name}
            onChange={(color) => setSlide({ ...slide, data: { ...slide.data, titleColor: color } })}
          />
        </LabeledInput>
        <LabeledInput label="Title Stinger">
          <TextArea
            defaultValue={slide?.data?.titleStinger}
            onChange={(e) =>
              setSlide({ ...slide, data: { ...slide.data, titleStinger: e.currentTarget.value } })
            }
            className="w-full rounded border border-gray-400 p-2 placeholder-gray-500 placeholder-opacity-60 shadow-inner focus:border-gray-800 focus:placeholder-opacity-0 focus:outline-none disabled:bg-gray-300 disabled:italic disabled:text-gray-600 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-300 dark:disabled:bg-gray-600 dark:disabled:text-gray-400"
          />
        </LabeledInput>
        <LabeledInput label="Title Stinger Color">
          <ColorPalette
            selected={slide?.data?.titleStingerColor?.name}
            onChange={(color) =>
              setSlide({ ...slide, data: { ...slide.data, titleStingerColor: color } })
            }
          />
        </LabeledInput>
        <LabeledInput label="Subtitle">
          <TextArea
            defaultValue={slide?.data?.subtitle}
            onChange={(e) =>
              setSlide({ ...slide, data: { ...slide.data, subtitle: e.currentTarget.value } })
            }
            className="w-full rounded border border-gray-400 p-2 placeholder-gray-500 placeholder-opacity-60 shadow-inner focus:border-gray-800 focus:placeholder-opacity-0 focus:outline-none disabled:bg-gray-300 disabled:italic disabled:text-gray-600 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-300 dark:disabled:bg-gray-600 dark:disabled:text-gray-400"
          />
        </LabeledInput>
        <LabeledInput label="Subtitle Color">
          <ColorPalette
            selected={slide?.data?.subtitleColor?.name}
            onChange={(color) =>
              setSlide({ ...slide, data: { ...slide.data, subtitleColor: color } })
            }
          />
        </LabeledInput>
      </FieldSet>
      <SlideLinkEdit slide={slide} setSlide={setSlide} />
      <FieldSet legend="Content Positioning">
        <LabeledInput label="Alignment">
          <Select
            options={[
              { value: "start", label: "Start" },
              { value: "center", label: "Center" },
              { value: "end", label: "End" },
            ]}
            value={slide?.data?.alignItems}
            onChange={(e) =>
              setSlide({ ...slide, data: { ...slide.data, alignItems: e.currentTarget.value } })
            }
          />
        </LabeledInput>
      </FieldSet>
    </div>
  )
}

const SlideFormFullA = ({ slide, setSlide }) => {
  return (
    <div className="flex flex-col gap-4">
      <SlideImageEdit slide={slide} setSlide={setSlide} />
      <FieldSet legend="Messaging">
        <LabeledInput label="Title">
          <TextArea
            defaultValue={slide?.data?.title}
            onChange={(e) =>
              setSlide({ ...slide, data: { ...slide.data, title: e.currentTarget.value } })
            }
            className="w-full rounded border border-gray-400 p-2 placeholder-gray-500 placeholder-opacity-60 shadow-inner focus:border-gray-800 focus:placeholder-opacity-0 focus:outline-none disabled:bg-gray-300 disabled:italic disabled:text-gray-600 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-300 dark:disabled:bg-gray-600 dark:disabled:text-gray-400"
          />
        </LabeledInput>
        <LabeledInput label="Title Color">
          <ColorPalette
            selected={slide?.data?.titleColor?.name}
            onChange={(color) => setSlide({ ...slide, data: { ...slide.data, titleColor: color } })}
          />
        </LabeledInput>
        <LabeledInput label="Subtitle">
          <TextArea
            defaultValue={slide?.data?.subtitle}
            onChange={(e) =>
              setSlide({ ...slide, data: { ...slide.data, subtitle: e.currentTarget.value } })
            }
            className="w-full rounded border border-gray-400 p-2 placeholder-gray-500 placeholder-opacity-60 shadow-inner focus:border-gray-800 focus:placeholder-opacity-0 focus:outline-none disabled:bg-gray-300 disabled:italic disabled:text-gray-600 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-300 dark:disabled:bg-gray-600 dark:disabled:text-gray-400"
          />
        </LabeledInput>
        <LabeledInput label="Subtitle Color">
          <ColorPalette
            selected={slide?.data?.subtitleColor?.name}
            onChange={(color) =>
              setSlide({ ...slide, data: { ...slide.data, subtitleColor: color } })
            }
          />
        </LabeledInput>
      </FieldSet>
      <SlideLinkEdit slide={slide} setSlide={setSlide} />
      <FieldSet legend="Content Positioning">
        <LabeledInput label="Position">
          <Select
            options={[
              { value: "left", label: "Left" },
              { value: "top-left", label: "Top Left" },
              { value: "top", label: "Top" },
              { value: "top-right", label: "Top Right" },
              { value: "right", label: "Right" },
              { value: "bottom-right", label: "Bottom Right" },
              { value: "bottom", label: "Bottom" },
              { value: "bottom-left", label: "Bottom Left" },
              { value: "center", label: "Center" },
            ]}
            value={slide?.data?.position}
            onChange={(e) =>
              setSlide({ ...slide, data: { ...slide.data, position: e.currentTarget.value } })
            }
          />
        </LabeledInput>
      </FieldSet>
    </div>
  )
}

const SlideImageEdit = ({ slide, setSlide }) => {
  const displayedImage = slide?.images?.find((i) => i.id === slide?.data?.backgroundImage)
  return (
    <FieldSet legend="Images">
      <LabeledInput
        label={`Background Image (${
          slide?.data?.backgroundImage ? slide?.data?.backgroundImage : "Unselected"
        })`}
      >
        {displayedImage && (
          <div className="flex w-full justify-center">
            <div className="flex max-h-72 w-full max-w-3xl justify-center">
              <img src={displayedImage.url} className="max-h-full" />
            </div>
          </div>
        )}
      </LabeledInput>
      <div className="flex flex-col">
        <span className="p-1 font-bold capitalize">Image Gallery:</span>
        <div>
          <ImageLibrary
            images={slide?.images}
            onChange={(images) => {
              setSlide((prev) => ({ ...prev, images, image_ids: images.map((i) => i.id) }))
            }}
            ownerType="slide"
            thumbnailSize="sm"
            selectedImage={slide?.data?.backgroundImage}
            setSelectedImage={(id) =>
              setSlide((prev) => ({ ...prev, data: { ...prev.data, backgroundImage: id } }))
            }
          />
        </div>
      </div>
      <LabeledInput label="Background Color">
        <ColorPalette
          selected={slide?.data?.backgroundColor?.name}
          onChange={(color) =>
            setSlide({ ...slide, data: { ...slide.data, backgroundColor: color } })
          }
        />
      </LabeledInput>
    </FieldSet>
  )
}

const SlideLinkEdit = ({ slide, setSlide }) => {
  return (
    <FieldSet legend="Call to Action">
      <LabeledInput label="Href">
        <TextInput
          value={slide?.data?.callToAction?.href}
          onChange={(e) =>
            setSlide({
              ...slide,
              data: {
                ...slide.data,
                callToAction: { ...slide.data.callToAction, href: e.currentTarget.value },
              },
            })
          }
        />
      </LabeledInput>
      <LabeledInput label="text">
        <TextInput
          value={slide?.data?.callToAction?.text}
          onChange={(e) =>
            setSlide({
              ...slide,
              data: {
                ...slide.data,
                callToAction: { ...slide.data.callToAction, text: e.currentTarget.value },
              },
            })
          }
        />
      </LabeledInput>
      <Details summary="Advanced Options">
        <LabeledInput
          label="Target"
          tooltip={`Default behavior:
              Links to a page on your site open in the same tab.
              Links to another site open a new tab.`}
        >
          <Select
            options={[
              { value: "", label: "Default" },
              { value: "_blank", label: "Open in new tab" },
              { value: "_self", label: "Open in same tab" },
            ]}
            value={slide?.data?.callToAction?.openSelf}
            onChange={(e) =>
              setSlide({
                ...slide,
                data: {
                  ...slide.data,
                  callToAction: { ...slide.data.callToAction, openSelf: e.currentTarget.value },
                },
              })
            }
          />
        </LabeledInput>
      </Details>
    </FieldSet>
  )
}

const TextArea = ({
  onChange,
  maxRows = 3,
  ...props
}: { maxRows?: number } & HtmlHTMLAttributes<HTMLTextAreaElement>) => {
  const [rows, setRows] = useState(1)
  const updateRows = (value: string = "") => {
    setRows(Math.max(1, Math.min(maxRows, value.split("\n").length)))
  }
  useEffect(() => {
    updateRows(String(props.defaultValue))
  }, [props.defaultValue])

  return (
    <textarea
      onChange={(e) => {
        updateRows(e.target.value)
        onChange?.(e)
      }}
      rows={rows}
      {...props}
    />
  )
}

const slideForms = {
  "split-a": SlideFormSplitA,
  "full-a": SlideFormFullA,
}

const validate = (slide) => {
  const errors = {}
  switch (slide?.layout) {
    case "split-a":
      // title color
      if (slide?.data?.title && !slide?.data?.titleColor?.value) {
        errors["titleColor"] = "Title Color is required."
      }
      // background image
      if (!slide?.data?.backgroundImage) {
        errors["backgroundImage"] = "Background Image is required."
      }
      // background color
      if (!slide?.data?.backgroundColor?.value) {
        errors["backgroundColor"] = "Background Color is required."
      }
      // call to action
      if (slide?.data?.callToAction?.text && !slide?.data?.callToAction?.href) {
        errors["callToAction"] = "Call To Action with Text requires a corresponding Href."
      }
      break
    case "full-a":
      // title color
      if (slide?.data?.title && !slide?.data?.titleColor?.value) {
        errors["titleColor"] = "Title Color is required."
      }
      // background image
      if (!slide?.data?.backgroundImage) {
        errors["backgroundImage"] = "Background Image is required."
      }
      // call to action
      if (slide?.data?.callToAction?.text && !slide?.data?.callToAction?.href) {
        errors["callToAction"] = "Call To Action with Text requires a corresponding Href."
      }
      break
    default:
      break
  }
  return errors
}
