import React, { useState, useRef, useEffect } from "react"
import { Popover } from "@headlessui/react"
import startCase from "lodash/startCase"
import classNames from "classnames"

export default function FieldOverrideTooltip({
  inputId,
  isOverridden,
  label,
  description = "Entering a value in this field will override the default value inherited from its parent",
}: {
  inputId?: string
  isOverridden?: boolean
  label?: string
  description?: string
}) {
  label = label || startCase(inputId || "this field")
  const [isOverridingParent, setIsOverridingParent] = useState(false)
  const ref = useRef(null)
  const usingVerified = !isOverridden && !isOverridingParent

  useEffect(() => {
    if (!ref.current) ref.current = document.querySelector(`#${inputId}`)
  }, [])

  useEffect(() => {
    if (!ref.current) return
    const listener = (e) => setIsOverridingParent(!!e.target.value.trim())
    ref.current.addEventListener("input", listener)
    setIsOverridingParent(!!ref.current.value.trim())
    return () => ref.current.removeEventListener("input", listener)
  }, [])

  return (
    <Popover className="relative">
      <Popover.Button className="rounded-full focus:outline-none focus:ring-2 focus:ring-picton-blue/50">
        <i
          className={classNames([
            "fa fa-check-circle rounded-full", //
            usingVerified ? "text-blue-500" : "opacity-50 saturate-50",
          ])}
          role="presentation"
        />

        <span className="sr-only">Use the verified content for {label}</span>
      </Popover.Button>

      <Popover.Panel className="absolute z-40 w-screen max-w-xl rounded bg-picton-blue p-2 text-gray-700">
        <strong>{usingVerified ? "Using Verified" : "Using Custom"}</strong>
        <div className="whitespace-pre-line">{description}</div>
      </Popover.Panel>
    </Popover>
  )
}
