export default function job_apply_type_radio_set() {
  document
    .querySelectorAll("[data-name=job_apply_type_radio_set]")
    .forEach((job_apply_type_radio_set: HTMLElement) => {
      const radio_set = document.createElement("div")
      radio_set.classList.add("radio-set", "my-3")

      const initial_url = job_apply_type_radio_set.dataset.applicationUrl // mailto:first.last@placewise.com |  https://www.placewise.com | tel:303-296-2413

      let initial_url_type = "url"
      let initial_url_value = ""

      if (initial_url) {
        const split_url = initial_url.split(":")
        if (split_url[0].startsWith("http")) {
          initial_url_type = split_url[0]
        } else {
          initial_url_type = split_url.shift()
        }
        initial_url_value = split_url.join(":")
      }

      const remove_error_message = function () {
        if (document.getElementById("application_url_error_message")) {
          document.getElementById("application_url_error_message").remove()
        }
      }

      const application_url_input = document.createElement("input")
      application_url_input.name = "job_listing[application_url]"
      application_url_input.id = "job_listing_application_url"
      application_url_input.classList.add("rhx-input", "rhx-text-input", "w-3/4", "hidden")
      application_url_input.dataset.validate = "true"
      application_url_input.value = initial_url_value

      const clear_selection_button = document.createElement("button")
      clear_selection_button.innerHTML = "Clear Selection"
      clear_selection_button.id = "clear_application_url_button"
      clear_selection_button.type = "button"
      clear_selection_button.classList.add("button", "p-1", "ml-2", "inline-block", "hidden")
      clear_selection_button.addEventListener("mousedown", (e) => {
        remove_error_message()
        job_apply_type_radio_set
          .querySelectorAll("[name=job_apply_type]")
          .forEach((radio_button: HTMLInputElement) => {
            radio_button.checked = false
          })
        // remove radio button selection
        initial_url_value = ""
        application_url_input.value = ""
        clear_selection_button.classList.add("hidden")
        application_url_input.classList.add("hidden")
      })

      const radio_inputs = `
      <span class="block">How to apply</span>
      <input type="radio" id="job_apply_type_url" name="job_apply_type" value="url" ${
        initial_url_type.startsWith("http") ? "checked" : ""
      }>
      <label for="job_apply_type_url">URL</label>
      <input type="radio" id="job_apply_type_email" name="job_apply_type" value="email" class="ml-8" ${
        initial_url_type === "mailto" ? "checked" : ""
      }>
      <label for="job_apply_type_email">Email</label>
      <input type="radio" id="job_apply_type_tel" name="job_apply_type" value="tel" class="ml-8" ${
        initial_url_type === "tel" ? "checked" : ""
      }>
      <label for="job_apply_type_tel">Phone</label>
    `
      radio_set.insertAdjacentHTML("beforeend", radio_inputs)
      job_apply_type_radio_set.appendChild(radio_set)

      let initial_apply_type = ""
      let selected_apply_type = ""

      const add_url_input_and_clear_button = function () {
        remove_error_message()
        clear_selection_button.classList.remove("hidden")
        application_url_input.classList.remove("hidden")
        job_apply_type_radio_set.appendChild(application_url_input)
        job_apply_type_radio_set.appendChild(clear_selection_button)
      }

      // if editing form and we have an existing application_url value
      if (initial_url_value.length) {
        initial_apply_type = selected_apply_type = (
          document.querySelector('input[name="job_apply_type"]:checked') as HTMLInputElement
        ).value
        application_url_input.type = initial_apply_type
        add_url_input_and_clear_button()
      }

      radio_set.addEventListener("change", (e) => {
        const target = e.target as HTMLInputElement
        selected_apply_type = target.value
        remove_error_message()
        application_url_input.value = ""
        application_url_input.type = selected_apply_type

        if (initial_url_value.length && initial_apply_type === application_url_input.type) {
          application_url_input.value = initial_url_value
        }

        add_url_input_and_clear_button()

        if (application_url_input.type === "url" && application_url_input.value.length) {
          is_valid_url(application_url_input.value)
        }
      })

      // check for valid URL, and display error message if not valid
      const is_valid_url = function (url_string, onsubmit = false) {
        const pattern = new RegExp(
          "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$", // fragment locator
          "i",
        )

        if (!pattern.test(url_string)) {
          const application_url_error_message_div = `The URL you entered is not formatted correctly.  Please enter a valid URL that begins with "http://" or "https://" before submitting.  `
          if (onsubmit) {
            alert(application_url_error_message_div)
            return false
          } else {
            clear_selection_button.insertAdjacentHTML(
              "afterend",
              `<div id="application_url_error_message" class="text-sm text-red-700 my-3"><i class="fa fa-exclamation-triangle"></i> ${application_url_error_message_div}</div>`,
            )
          }
        }
      }

      application_url_input.addEventListener("focus", (e) => {
        remove_error_message()
      })

      application_url_input.addEventListener("blur", (e) => {
        if (selected_apply_type === "url" && application_url_input.value.length) {
          is_valid_url(application_url_input.value)
        }
      })

      // handle on submit url types
      const form = job_apply_type_radio_set.closest("form")
      form.onsubmit = function (e) {
        let any_checked = false
        job_apply_type_radio_set
          .querySelectorAll("[name=job_apply_type]")
          .forEach((radio_button: HTMLInputElement) => {
            if (radio_button.checked) {
              any_checked = true
            }
          })

        if (any_checked && application_url_input.value.length) {
          if (application_url_input.type === "email") {
            application_url_input.value = `mailto:${application_url_input.value}`
          } else if (application_url_input.type === "tel") {
            application_url_input.value = `tel:${application_url_input.value}`
          } else if (application_url_input.type === "url") {
            return is_valid_url(application_url_input.value, true)
          }
        }
        return true
      }
    })
}
