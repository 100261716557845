import React, { useEffect, useState } from "react"
import Rails from "../util/Rails"
import { TextInput, Select, LabeledInput } from "./Modal"
import { v4 as uuid } from "uuid"
import { SlideEditor } from "./SlidesEditor"

// fetch(`/rh/sites/${site_id}/carousels.json` "POST"
// fetch(`/rh/carousels/${section.component_id}.json` "PATCH"

export const getCarousels = async ({ siteId }) => {
  const response = await fetch(`/rh/sites/${siteId}/carousels.json`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": Rails.csrfToken(),
    },
  })
  if (response.ok) return await response.json()
  return []
}

export const getCarousel = async ({ id }) => {
  const response = await fetch(`/rh/carousels/${id}.json`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": Rails.csrfToken(),
    },
  })
  if (response.ok) return await response.json()
  return {}
}

export const createCarousel = async ({ siteId, body = {} }) => {
  const response = await fetch(`/rh/sites/${siteId}/carousels.json`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": Rails.csrfToken(),
    },
    body: JSON.stringify(body),
  })
  if (response.ok) return await response.json()
  return {}
}

export const updateCarousel = async ({ id, body = {} }) => {
  const response = await fetch(`/rh/carousels/${id}.json`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": Rails.csrfToken(),
    },
    body: JSON.stringify(body),
  })
  if (response.ok) return await response.json()
  return {}
}

export function ChooseCarousel({ setComponent, siteId, setCloseWarning }) {
  const [carousels, setCarousels] = useState([])
  const [editingCarousel, setEditingCarousel] = useState({ id: null, name: "" })
  const component = {
    key: uuid(),
    status: "draft",
    base_status: 0,
    component_type: "Carousel",
    published_at: null,
    expired_at: null,
    component_id: null,
  }

  const setUp = async () => {
    const carousels = await getCarousels({ siteId })
    setCarousels(carousels)
  }
  const onSelectChange = ({ id }) => {
    setEditingCarousel({ id, name: carousels.find((x) => `${x.id}` === `${id}`)?.name || null })
  }
  const onTextChange = (t) => {
    setEditingCarousel((prev) => {
      return { ...prev, name: t }
    })
  }
  const onSubmit = async () => {
    const response = await createCarousel({ siteId, body: { name: editingCarousel.name } })
    setEditingCarousel((prev) => {
      return { ...prev, ...response }
    })
  }

  useEffect(() => {
    if (editingCarousel.id) {
      setComponent({
        ...component,
        component_id: editingCarousel.id,
        ...(editingCarousel.id && { component: { id: editingCarousel.id } }),
      })
    } else {
      setComponent(null)
    }
  }, [editingCarousel])

  useEffect(() => {
    setUp()
    setCloseWarning("Discard Changes?")
  }, [])

  return (
    <>
      <LabeledInput label="choose a carousel">
        <Select
          options={[
            { value: "", label: "Create New" },
            ...carousels.map((c) => {
              return { value: c.id, label: c.name || `Carousel ${c.id}` }
            }),
          ]}
          onChange={(e) =>
            onSelectChange({
              id: e.currentTarget.value,
            })
          }
        />
      </LabeledInput>
      {editingCarousel.id ? (
        <div className="flex flex-grow flex-wrap items-end gap-2">
          <div className="flex flex-grow flex-col">
            <div className="inline-flex items-center gap-2">
              <span className="p-1 font-bold capitalize">Carousel name:</span>
            </div>
            <div className="flex flex-nowrap items-center justify-start gap-2 rounded-md bg-black/20 p-2.5 text-center font-mono text-sm uppercase">
              {editingCarousel.name || `Carousel ${editingCarousel.id}`}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-grow flex-wrap items-end gap-2">
          <LabeledInput label="carousel name" stretch>
            <TextInput
              value={editingCarousel.name || ""}
              onChange={(e) => onTextChange(e.currentTarget.value)}
            />
          </LabeledInput>
          <button
            type="button"
            disabled={!editingCarousel.name}
            className="button py-2"
            onClick={onSubmit}
          >
            Create
          </button>
        </div>
      )}
    </>
  )
}

export default function CarouselEditor({
  carouselId,
  colorPalette = {},
  imageTags = [],
  setCloseWarning,
  setOnConfirmClickDisabled,
}) {
  const [carousel, setCarousel] = useState(null)
  const [dirty, setDirty] = useState(false)

  const setUp = async () => {
    const carousel = await getCarousel({ id: carouselId })
    setCarousel(carousel)
  }
  const onTextChange = (t) => {
    setCarousel((prev) => {
      return { ...prev, name: t }
    })
    setDirty(true)
    setCloseWarning("Discard Changes?")
    setOnConfirmClickDisabled(true)
  }
  const onSubmit = async () => {
    const c = await updateCarousel({
      id: carouselId,
      body: {
        name: carousel.name,
        slides_attributes: carousel.slides.map(({ key, status, images = [], ...s }) =>
          JSON.stringify(s),
        ),
      },
    })
    if (c) {
      setCarousel(c)
      setDirty(false)
      setCloseWarning("Discard Changes?")
      setOnConfirmClickDisabled(false)
    }
  }
  const onChange = (slideState) => {
    if (carousel) {
      setDirty(true)
      setCloseWarning("Discard Changes?")
      setOnConfirmClickDisabled(true)
    }
    setCarousel((prev) => {
      return {
        ...carousel,
        slides: slideState,
      }
    })
  }

  useEffect(() => {
    setUp()
  }, [])

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex gap-4 text-lg font-semibold capitalize">
        <LabeledInput label="carousel name" stretch>
          <TextInput
            value={carousel?.name || ""}
            onChange={(e) => onTextChange(e.currentTarget.value)}
          />
        </LabeledInput>
      </div>
      <div className="w-full">
        {carousel?.slides && (
          <SlideEditor
            slides={carousel.slides}
            colorPalette={colorPalette}
            imageTags={imageTags}
            onChange={onChange}
          />
        )}
      </div>
      <div>
        {dirty ? (
          <button type="button" className="button py-2" onClick={onSubmit}>
            Update Carousel
          </button>
        ) : (
          <span className="text-base">Carousel up to date!</span>
        )}
      </div>
    </div>
  )
}
