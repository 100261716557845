import React, { useState, useEffect } from "react"
import { Switch } from "@headlessui/react"
import { motion, AnimatePresence } from "framer-motion"
import SearchBox from "./SearchBox"
import MultiSearchBox from "./MultiSearchBox"

const extractStoreIds = (items) => items.map((item) => item.id)

export default (props) => {
  const [enabled, setEnabled] = useState(props.national)
  const toggle = () => setEnabled(!enabled)
  const [retailer, setRetailer] = useState(props.retailer)
  const [retailerId, setRetailerId] = useState(props.retailer?.id)
  const [storeIds, setStoreIds] = useState(extractStoreIds(props.stores))

  useEffect(() => setRetailerId(retailer?.id), [retailer])

  return (
    <>
      {retailerId && (
        <input
          type="hidden"
          id="product_retailer_id"
          name="product[retailer_id]"
          value={retailerId}
        />
      )}
      <input type="hidden" id="product_is_national" name="product[is_national]" value={enabled} />
      {storeIds.map(
        (id) =>
          id && (
            <input
              key={id}
              type="hidden"
              id="product_store_ids"
              name="product[store_ids][]"
              value={id}
            />
          ),
      )}
      <div className="flex justify-end">
        <SearchBox
          label="Retailer"
          searchPath="rh/retailers.json"
          initialItem={retailer}
          onChange={(item) => setRetailer(item || null)}
        />
      </div>

      <AnimatePresence initial="false">
        {retailerId && (
          <>
            <motion.div
              className="flex justify-end pt-4"
              initial={{ scaleY: 0 }}
              animate={{ scaleY: 1 }}
              exit={{ scaleY: 0, transition: { duration: 0.2 } }}
            >
              <Switch.Group>
                <Switch.Label>{enabled ? "National" : "Local"}</Switch.Label>
                <Switch
                  checked={enabled}
                  onChange={toggle}
                  className={`${
                    enabled ? "bg-blue-600" : "bg-gray-200 dark:bg-gray-500"
                  } relative box-content inline-flex h-6 w-12 rounded-full border border-gray-500 shadow-inner`}
                >
                  <span
                    className={`${
                      enabled ? "translate-x-6" : "translate-x-0"
                    } box-border inline-block h-6 w-6 rounded-full border border-gray-500 border-opacity-50 bg-gray-100 shadow transition duration-150`}
                  />
                </Switch>
              </Switch.Group>
            </motion.div>
            <AnimatePresence initial="false">
              {!enabled && (
                <motion.div
                  initial={{ scaleY: 0 }}
                  animate={{ scaleY: 1 }}
                  exit={{ scaleY: 0, transition: { duration: 0.2 } }}
                >
                  <MultiSearchBox
                    label="Stores (by Mall)"
                    searchPath="rh/stores.json"
                    searchParams={`?filter[retailer_id]=${retailerId}`}
                    initialItems={props.stores}
                    onChange={(items) => setStoreIds(extractStoreIds(items))}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </>
        )}
      </AnimatePresence>
    </>
  )
}
