import React, { useEffect, useState } from "react"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
dayjs.extend(utc)
dayjs.extend(timezone)

const dateTimeFormat = {
  US: {
    pickerDateTime: "yyyy-MM-dd h:mmaa",
    pickerDate: "yyyy-MM-dd",
    pickerTime: "h:mm aa",
    dateTime: "YYYY-MM-DD h:mma",
    date: "YYYY-MM-DD",
  },
  EU: {
    pickerDateTime: "dd/MM/yyyy H:mm",
    pickerDate: "dd/MM/yyyy",
    pickerTime: "H:mm",
    dateTime: "DD/MM/YYYY H:mm",
    date: "DD/MM/YYYY",
  },
}

const useServerDateTimeFormat = () => {
  const [server, setServer] = useState("US")
  useEffect(() => {
    setServer(document.body.dataset.server)
  }, [])

  return dateTimeFormat[server]
}

const useTimezone = () => {
  const [timezone, setTimezone] = useState("UTC")
  useEffect(() => {
    setTimezone(document.body.dataset.timezone)
  }, [])

  return timezone
}

const pretendZonedDateTimeIsLocal = ({ date, timezone }) => {
  const offset = dayjs(date).tz(timezone).utcOffset()
  const stripped = dayjs.tz(date, timezone).add(offset, "minute").format("YYYY-MM-DD H:mm")
  return dayjs(stripped).toISOString()
}

type LocalDateTime = { date: string; format?: "dateTime" | "date" }

const LocalDateTime = ({ date, format: f = "dateTime" }: LocalDateTime) => {
  const format = useServerDateTimeFormat()
  const timezone = useTimezone()
  const shiftedDate = pretendZonedDateTimeIsLocal({ date, timezone })
  const formatedDate = dayjs(shiftedDate).format(format[f])
  return <React.Fragment>{formatedDate}</React.Fragment>
}

export default LocalDateTime
export { pretendZonedDateTimeIsLocal, useServerDateTimeFormat, useTimezone }
