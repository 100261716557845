import "choices.js/public/assets/styles/choices.css"
import Choices from "choices.js/public/assets/scripts/choices"
import uuidv4 from "./util/uuidv4"

const choices = []
const defaults = {
  noChoicesText: "Begin typing to search available options...",
}

export const setup = () => {
  document.querySelectorAll(".autocomplete").forEach((node: HTMLSelectElement) => {
    // Choices seems to be doing the wrong thing when calling the `destroy` method
    //
    // see: https://github.com/jshjohnson/Choices/pull/574
    //
    // it's a little ham-fisted, but the workaround we have for now is to tag our
    // select elements with a unique identifier, which we store in the array of
    // active instances. during the teardown, we use the uuid to find and replace
    // them with copies of the original nodes. not ideal, but it seems to work for
    // now.
    const uuid = uuidv4()
    const options = Array.from(node.options)
    node.dataset.uuid = uuid
    const config = { ...defaults, ...JSON.parse(node.dataset.config || "{}") }
    const instance = new Choices(node, config)

    if (node.dataset.autocompletePath) {
      const name = node.dataset.autocompleteName || "name"

      node.addEventListener("search", (event: any) => {
        if (event.detail.value) {
          const current_url = new URL(node.dataset.autocompletePath, window.location.origin)
          const base_search_url = new URL(current_url.pathname, current_url.origin)
          let url_params = new URLSearchParams(current_url.search)
          url_params.append("q", event.detail.value)

          fetch(`${base_search_url}?${url_params.toString()}`)
            .then((response) => {
              response.json().then((data) => {
                instance.setChoices(data, "id", name, true)
              })
            })
            .catch((e) => console.error("Failed to fetch data!", e))
        }
      })
    }

    choices.push({ instance, original: node.value, uuid, options })
  })
}

export const teardown = () => {
  while (choices.length) {
    const o = choices.pop()
    o.instance.destroy()
    const select = document.querySelector(`[data-uuid="${o.uuid}"]`)
    if (select) {
      select.innerHTML = ""
      o.options.forEach((opt) => select.appendChild(opt))
    }
  }
}

export default { setup, teardown }
