import React, { useState, useEffect } from "react"
import { Tab } from "@headlessui/react"

export default function ({ tabs, multiline = false, ...props }) {
  const [values, setValues] = useState({})
  const onChange = (values) => setValues(values)
  return (
    <>
      {tabs.flatMap((tab) =>
        tab.fields.map((field) => (
          <input key={field.name} name={field.name} value={values[field.name]} type="hidden" />
        )),
      )}
      <I18nFieldTabs tabs={tabs} multiline={multiline} onChange={onChange} />
    </>
  )
}

export function I18nFieldTabs({ tabs, onChange, multiline = false, omitLabels = false, ...props }) {
  const Input = multiline ? "textarea" : "input"
  const [values, setValues] = useState(
    Object.fromEntries(
      tabs.flatMap((tab) => tab.fields.map((field) => [field.name, field.value || ""])),
    ),
  )
  useEffect(() => {
    onChange(values)
  }, [values])
  return (
    <>
      <Tab.Group>
        <Tab.List className="mx-4 rounded border border-gray-900 px-4">
          {tabs.map((tab) => {
            return (
              <Tab key={tab.locale}>
                {({ selected }) => (
                  <span
                    className={`p-2 capitalize ${selected && "text-picton-blue"} ${
                      tab.fields[0].value ? "font-black text-opacity-100" : "text-opacity-60"
                    }`}
                  >
                    {tab.locale}
                  </span>
                )}
              </Tab>
            )
          })}
        </Tab.List>
        <Tab.Panels>
          {tabs.map((tab) => (
            <Tab.Panel key={tab.locale}>
              {tab.fields.map((field) => (
                <div key={field.name} className="field">
                  {!omitLabels && <label>{field.label}</label>}
                  <Input
                    value={values[field.name]}
                    placeholder={field.placeholder}
                    onChange={(e) =>
                      setValues((prev) => ({
                        ...prev,
                        [field.name]: e.target.value,
                      }))
                    }
                    className="rhx-input rhx-text-input"
                  />
                </div>
              ))}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </>
  )
}
