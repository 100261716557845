import React, { useState } from "react"
import { useCombobox, useMultipleSelection } from "downshift"
import { motion } from "framer-motion"
import { uniqBy } from "lodash-es"

export default ({
  label = "Choose some elements",
  initialItems = [],
  searchPath,
  searchParams = "?",
  onChange = () => {},
}) => {
  const [items, setItems] = useState(initialItems)
  const [inputValue, setInputValue] = useState("")

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
  } = useMultipleSelection({
    initialSelectedItems: initialItems,
    onSelectedItemsChange: ({ selectedItems }) => onChange(selectedItems),
  })

  const getFilteredItems = () =>
    items.filter(
      (item) =>
        selectedItems.indexOf(item) < 0 &&
        item.name.toLowerCase().startsWith(inputValue.toLowerCase()),
    )

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    inputValue,
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    items: getFilteredItems(),
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
          }
      }
      return changes
    },
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          fetch(`/${searchPath}${searchParams}&q=${inputValue}`)
            .then((res) => res.json())
            .then((data) => {
              setItems(
                uniqBy([...items, ...data.map((d) => ({ name: d.mall_name, id: d.id }))], "id"),
              )
            })
          setInputValue(inputValue)
          break
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            setInputValue("")
            addSelectedItem(selectedItem)
          }
          break
        default:
          break
      }
    },
  })

  return (
    <div className="block w-full">
      <label {...getLabelProps()}>{label}</label>
      <div className="rounded border p-2 shadow-inner focus-within:border-gray-500 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-100">
        <div className="flex w-full flex-col space-y-2">
          {selectedItems.map((selectedItem, index) => (
            <span
              className="inline-flex whitespace-nowrap rounded-full bg-blue-500 px-3 text-white"
              key={`selected-item-${index}`}
              {...getSelectedItemProps({ selectedItem, index })}
            >
              {selectedItem.name}
              <span
                className="pl-2 text-blue-800"
                onClick={(e) => {
                  e.stopPropagation()
                  removeSelectedItem(selectedItem)
                }}
              >
                &#10005;
              </span>
            </span>
          ))}
        </div>
        <div {...getComboboxProps()} className="flex">
          <input
            {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
            className="flex-grow outline-none dark:bg-gray-800 dark:text-gray-100"
          />
          <span {...getToggleButtonProps()} aria-label={"toggle menu"}>
            &#8595;
          </span>
        </div>
      </div>

      <motion.ul
        {...getMenuProps()}
        animate={{ scaleY: isOpen ? 1 : 0 }}
        className={`${
          isOpen && "h-32 border"
        } divide-y divide-gray-500 overflow-y-scroll rounded border-gray-500 shadow-lg`}
      >
        {isOpen &&
          getFilteredItems(items).map((item, index) => (
            <li
              className={`p-4 ${highlightedIndex === index ? "bg-blue-500 text-white" : ""}`}
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {item.name}
            </li>
          ))}
      </motion.ul>
    </div>
  )
}
