import React, { useState, useEffect } from "react"
import { Tab } from "@headlessui/react"
import RichTextEditor from "./RichTextEditor"
import SimplifiedRichTextEditor from "./SimplifiedRichTextEditor"
import FieldExplanationTooltip from "./FieldExplanationTooltip"
import FieldOverrideTooltip from "./FieldOverrideTooltip"

export default function ({ tabs, mode = "full", enableInheritanceIndicator = false, ...props }) {
  const [values, setValues] = useState({})
  const onChange = (values) => setValues(values)
  return (
    <>
      {tabs.flatMap((tab) =>
        tab.fields.map((field) => (
          <input key={field.name} name={field.name} value={values[field.name]} type="hidden" />
        )),
      )}
      <I18nRichTextFieldTabs
        tabs={tabs}
        mode={mode}
        enableInheritanceIndicator={enableInheritanceIndicator}
        onChange={onChange}
      />
    </>
  )
}

export function I18nRichTextFieldTabs({
  tabs,
  onChange,
  mode = "full",
  enableInheritanceIndicator = false,
  ...props
}) {
  const Editor = mode === "simple" ? SimplifiedRichTextEditor : RichTextEditor
  const [values, setValues] = useState(
    Object.fromEntries(
      tabs.flatMap((tab) => tab.fields.map((field) => [field.name, field.value || ""])),
    ),
  )
  useEffect(() => {
    onChange(values)
  }, [values])
  return (
    <>
      <Tab.Group>
        <Tab.List className="mx-4 rounded border border-gray-900 px-4">
          {tabs.map((tab) => (
            <Tab key={tab.locale}>
              {({ selected }) => (
                <span
                  className={`p-2 capitalize ${selected && "text-picton-blue"} ${
                    tab.fields[0].value ? "font-black text-opacity-100" : "text-opacity-60"
                  }`}
                >
                  {tab.locale}
                </span>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          {tabs.map((tab) => (
            <Tab.Panel key={tab.locale}>
              {tab.fields.map(({ label, name, description, inherited, ...props }) => {
                const isOverridden = values[name] && values[name] !== "<p></p>"
                return (
                  <div key={name} className="field">
                    <div className="flex items-center gap-1">
                      <label>{label}</label>
                      {description && (
                        <FieldExplanationTooltip label={name} description={description} />
                      )}
                      {enableInheritanceIndicator && inherited && (
                        <FieldOverrideTooltip isOverridden={isOverridden} />
                      )}
                    </div>
                    <Editor
                      inherited={inherited}
                      enableInheritanceIndicator={enableInheritanceIndicator}
                      content={values[name]}
                      onChange={(value) =>
                        setValues((prev) => {
                          return {
                            ...prev,
                            [name]: value,
                          }
                        })
                      }
                      {...props}
                    />
                  </div>
                )
              })}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </>
  )
}
