import React, { useState, useEffect } from "react"

export default ({ selected, images = [], onChange }) => {
  const findImage = () => images.find((i) => i.id === selected)

  const [image, setImage] = useState(findImage())

  useEffect(() => {
    setImage(findImage())
  }, [selected])

  return (
    <>
      <div className="flex items-center justify-center py-2">
        {image ? (
          <img src={image.url} className="w-48 h-48 object-contain" />
        ) : (
          <span>No image selected</span>
        )}
      </div>

      {images.length ? (
        <div className="flex gap-4 items-stretch p-4 bg-gray-400 dark:bg-gray-800 shadow-inner">
          {images.map((img, i) => (
            <div key={img.url} className="w-20 h-20">
              <button
                onClick={() => {
                  onChange(img.id)
                  setImage(img)
                }}
              >
                <img
                  src={img.url}
                  className={`w-20 h-20 object-contain ${
                    image && image.id === img.id ? "ring-4 ring-black" : ""
                  }`}
                />
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center">Upload an image to start...</div>
      )}
    </>
  )
}
