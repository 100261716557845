import { mergeAttributes, Node, nodeInputRule } from "@tiptap/core"

export interface EmbedOptions {
  inline: boolean
  HTMLAttributes: Record<string, any>
}

type youtube = {
  name: "youtube"
  url: string
}

type jotform = {
  name: "jotform"
  url: string
}

type issuu = {
  name: "issuu"
  url: string
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    embed: {
      setEmbed: (options: { "data-embed": youtube | jotform | issuu | null }) => ReturnType
    }
  }
}

export default Node.create<EmbedOptions>({
  name: "customEmbed",

  addOptions() {
    return {
      inline: false,
      HTMLAttributes: {},
    }
  },

  inline() {
    return this.options.inline
  },

  group() {
    return "block"
  },

  draggable: true,
  selectable: true,

  addAttributes() {
    return {
      "data-embed": {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: "div[data-embed]",
        priority: 51,
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const { name, ...config } = JSON.parse(HTMLAttributes["data-embed"])

    if (name === "youtube") {
      HTMLAttributes["class"] = "bg-white flex flex-col items-center"
      return [
        "div",
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
        ["p", { class: "uppercase text-3xl text-black font-black text-center my-8" }, "youtube"],
        [
          "pre",
          { class: "text-black text-xs bg-neutral-400 p-1 w-full" },
          ["code", JSON.stringify(config, null, 2)],
        ],
      ]
    } else if (name === "jotform") {
      HTMLAttributes["class"] = "bg-white flex flex-col items-center"
      return [
        "div",
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
        ["p", { class: "uppercase text-3xl text-black font-black text-center my-8" }, "jotform"],
        [
          "pre",
          { class: "text-black text-xs bg-neutral-400 p-1 w-full" },
          ["code", JSON.stringify(config, null, 2)],
        ],
      ]
    } else if (name === "issuu") {
      HTMLAttributes["class"] = "bg-white flex flex-col items-center"
      return [
        "div",
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
        ["p", { class: "uppercase text-3xl text-black font-black text-center my-8" }, "issuu"],
        [
          "pre",
          { class: "text-black text-xs bg-neutral-400 p-1 w-full" },
          ["code", JSON.stringify(config, null, 2)],
        ],
      ]
    }
    return ["div", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },

  addCommands() {
    return {
      setEmbed:
        (options) =>
        ({ commands }) => {
          const opts = { "data-embed": JSON.stringify(options) }
          return commands.insertContent({
            type: this.name,
            attrs: opts,
          })
        },
    }
  },
})
