import React, { useState, useEffect } from "react"
import { Switch } from "@headlessui/react"
import startCase from "lodash/startCase"

export default ({ input_id, label }) => {
  label = label || startCase(input_id)
  const [enabled, setEnabled] = useState(false)

  useEffect(() => {
    setEnabled(document.getElementById(input_id).value)
  }, [input_id])

  const toggle = () => {
    document.getElementById(input_id).value = !enabled
    setEnabled(!enabled)
  }

  return (
    <Switch.Group>
      <Switch.Label>{label}</Switch.Label>
      <Switch
        checked={enabled}
        onChange={toggle}
        className={`${
          enabled ? "bg-blue-600" : "bg-gray-200 dark:bg-gray-500"
        } relative inline-flex h-6 w-8 rounded-full`}
      >
        <span
          className={`${
            enabled ? "translate-x-4" : "translate-x-0"
          } inline-block h-6 w-6 rounded-full bg-white transition duration-150`}
        />
      </Switch>
    </Switch.Group>
  )
}
