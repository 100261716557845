import Image from "@tiptap/extension-image"
import { mergeAttributes } from "@tiptap/core"

export default Image.extend({
  name: "customImage",

  addAttributes() {
    return {
      ...Image.config.addAttributes(),
      "data-size": {
        default: "intrinsic",
        rendered: false,
      },
      "data-href": {
        default: null,
        rendered: false,
      },
    }
  },

  addCommands() {
    return {
      setImage:
        (options) =>
        ({ tr, commands }) => {
          if (tr.selection?.node?.type?.name == "customImage") {
            return commands.updateAttributes("customImage", options)
          } else {
            return commands.insertContent({
              type: this.name,
              attrs: options,
            })
          }
        },
      setImageLink:
        (options) =>
        ({ tr, commands }) => {
          if (tr.selection?.node?.type?.name == "customImage") {
            const href = { ...(options.href && { ["data-href"]: options.href }) }
            return commands.updateAttributes("customImage", href)
          } else {
            return commands.insertContent({
              type: this.name,
              attrs: options,
            })
          }
        },
      unsetImageLink:
        (options) =>
        ({ tr, commands }) => {
          if (tr.selection?.node?.type?.name == "customImage") {
            return commands.resetAttributes("customImage", ["data-href"])
          } else {
            return commands.insertContent({
              type: this.name,
              attrs: options,
            })
          }
        },
    }
  },

  renderHTML({ node, HTMLAttributes }) {
    HTMLAttributes["data-size"] = node.attrs["data-size"]
    let styles = ""
    if (node.attrs["data-size"] === "fullWidth") styles += "width: 100%;"
    else if (node.attrs["data-size"] === "intrinsic")
      styles += "margin-right: 16px; display: inline-block;"
    HTMLAttributes["style"] = styles
    HTMLAttributes["data-href"] = node.attrs["data-href"]

    return ["img", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },
})
