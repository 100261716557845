import "choices.js/public/assets/styles/choices.css"
import Choices from "choices.js/public/assets/scripts/choices"

const choices = []

const defaults = {
  removeItemButton: true,
  delimiter: ";",
  placeholderValue: "Type to select some options...",
}

export const init = (node: HTMLInputElement) => {
  const config = { ...defaults, ...JSON.parse(node.dataset?.config || "{}") }
  const input = new Choices(node, config)

  if (node.dataset.autocompletePath) {
    const name = node.dataset.autocompleteName || "name"
    node.addEventListener("search", (event: any) => {
      if (event.detail.value) {
        const current_url = new URL(node.dataset.autocompletePath, window.location.origin)
        const base_search_url = new URL(current_url.pathname, current_url.origin)
        let url_params = new URLSearchParams(current_url.search)
        url_params.append("q", event.detail.value)

        fetch(`${base_search_url}?${url_params.toString()}`)
          .then((response) => {
            response.json().then((data) => {
              input.setChoices(data, "id", name, true)
            })
          })
          .catch((e) => console.error("Failed to fetch data!", e))
      }
    })
  }
  choices.push({ instance: input, original: node.value })
}

export const setup = () => {
  document.querySelectorAll("[data-taggable]").forEach((node: HTMLInputElement) => {
    init(node)
  })
}

export const teardown = () => {
  while (choices.length) {
    const o = choices.pop()
    o.instance.destroy()
    o.instance.passedElement.element.value = o.original
  }
}

export default { setup, teardown }
