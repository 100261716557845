import React from "react"
import { Popover } from "@headlessui/react"
import startCase from "lodash/startCase"

export default function SlidePanel({ inputId, label, description = "" }) {
  label = label || startCase(inputId)

  return (
    <Popover className="relative">
      <Popover.Button>
        <svg className="inline-block w-5 h-5 fill-current" viewBox="0 0 24 24">
          <path d="M12 6a2 2 0 110-4 2 2 0 010 4zm0 8a2 2 0 110-4 2 2 0 010 4zm-2 6a2 2 0 104 0 2 2 0 00-4 0z"></path>
        </svg>
        <span className="sr-only">Quick actions for {label}</span>
      </Popover.Button>

      <Popover.Panel className="absolute z-10 p-2 w-screen max-w-xl text-gray-700 bg-picton-blue rounded">
        <strong></strong>
        <p></p>
      </Popover.Panel>
    </Popover>
  )
}
