import React, { useState } from "react"
import { useCombobox, useMultipleSelection } from "downshift"
import { motion } from "framer-motion"
import uniqBy from "lodash/uniqBy"

export default ({
  label = "Choose one",
  initialItem,
  searchPath,
  searchParams = "?",
  onChange = () => {},
}) => {
  const [items, setItems] = useState(initialItem ? [initialItem] : [])
  const [inputValue, setInputValue] = useState(initialItem?.name || "")

  const getFilteredItems = () =>
    items.filter((item) => item.name.toLowerCase().startsWith(inputValue.toLowerCase()))

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    inputValue,
    initialItem,
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    items: getFilteredItems(),
    onSelectedItemChange: ({ selectedItem }) => onChange(selectedItem),
    // stateReducer: (state, actionAndChanges) => {
    //   const { changes, type } = actionAndChanges
    //   switch (type) {
    //     case useCombobox.stateChangeTypes.InputKeyDownEnter:
    //     case useCombobox.stateChangeTypes.ItemClick:
    //       return {
    //         ...changes,
    //       }
    //   }
    //   return changes
    // },
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          fetch(`/${searchPath}${searchParams}&q=${inputValue}`)
            .then((res) => res.json())
            .then((data) => {
              setItems(uniqBy([...items, ...data], "id"))
            })
          setInputValue(inputValue)
          break
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            setInputValue(selectedItem.name)
          }
          break
        default:
          break
      }
    },
  })

  return (
    <div className="block w-full">
      <label {...getLabelProps()}>{label}</label>
      <div className="p-2 dark:text-gray-100 dark:bg-gray-800 border focus-within:border-gray-500 dark:border-gray-600 rounded shadow-inner">
        <div {...getComboboxProps()} className="flex">
          <input
            {...getInputProps()}
            className="flex-grow dark:text-gray-100 dark:bg-gray-800 outline-none"
          />
          <span {...getToggleButtonProps()} aria-label={"toggle menu"}>
            &#8595;
          </span>
        </div>
      </div>

      <motion.ul
        {...getMenuProps()}
        animate={{ scaleY: isOpen ? 1 : 0 }}
        className={`${
          isOpen && "border h-32"
        } border-gray-500 divide-y divide-gray-500 rounded shadow-lg overflow-y-scroll`}
      >
        {isOpen &&
          getFilteredItems(items).map((item, index) => (
            <li
              className={`p-4 ${highlightedIndex === index ? "bg-blue-500 text-white" : ""}`}
              key={item.id}
              {...getItemProps({ item, index })}
            >
              {item.name}
            </li>
          ))}
      </motion.ul>
    </div>
  )
}
