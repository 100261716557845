import classNames from "classnames"
import { motion } from "framer-motion"
import React, { useEffect, useState } from "react"

export default function Yo({ greeting = "Yo", name = "" }) {
  console.log("Yo....")
  return (
    <motion.div
      whileHover={{ scale: 1.1 }}
      className={classNames([
        "text-3xl",
        "text-center",
        //
      ])}
    >
      {greeting}
      {name && `, ${name}`}
    </motion.div>
  )
}
