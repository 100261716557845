import React, { Fragment, useState } from "react"
import { Combobox } from "@headlessui/react"
import classNames from "classnames"
import ReactOnRails from "react-on-rails"

export default function ScopedMallSelector({
  path,
  availableMalls = [],
  onChange,
  method,
  buttonText,
}: {
  path: string
  availableMalls?: Mall[]
  onChange?: (malls: Mall[]) => void
  method: string
  buttonText: string
}) {
  const urlParams = new URLSearchParams(location.search)

  const [q, setQ] = useState("")
  const [selectedMalls, setSelectedMalls] = useState(availableMalls)
  urlParams.append("mall_ids", selectedMalls.map((mall) => mall.id).join(","))
  const url = `${path}?${urlParams.toString()}`

  const filteredMalls = availableMalls.filter(
    (mall) =>
      mall.name.toLowerCase().includes(q.toLowerCase()) &&
      !selectedMalls.find((t) => t.id === mall.id),
  )

  return (
    <>
      <Combobox<Mall>
        value={selectedMalls}
        onChange={(malls) => {
          setSelectedMalls(malls)
          setQ("")
          onChange?.(malls)
        }}
        multiple
      >
        <div
          className={classNames([
            availableMalls.length == 1 ? "hidden" : "",
            "focus-within:ring-2 focus-within:ring-picton-blue/70",
            "rounded border border-gray-400 p-2 shadow-inner",
            "dark:border-gray-600 dark:bg-gray-800 dark:text-gray-300",
            "relative",
          ])}
        >
          <ul className="flex flex-wrap items-center gap-2 px-2">
            {selectedMalls.map((mall) => (
              <li
                key={mall.id}
                className="rounded-full bg-picton-blue px-2 py-1 text-sm text-white"
              >
                {mall.name}
                <button
                  type="button"
                  className="ml-2"
                  onClick={() => setSelectedMalls(selectedMalls.filter((t) => t.id !== mall.id))}
                >
                  <span className="sr-only">Remove {mall.name}</span>
                  <i className="fa fa-times opacity-50" />
                </button>
              </li>
            ))}
            <li className="inline-flex grow">
              <Combobox.Input
                className={classNames([
                  "w-full bg-transparent focus:outline-none",
                  "disabled:bg-gray-300 disabled:italic disabled:text-gray-600",
                  "dark:disabled:bg-gray-600 dark:disabled:text-gray-400",
                  "placeholder-gray-500 placeholder-opacity-60 focus:placeholder-opacity-0",
                ])}
                value={q}
                onChange={(e) => setQ(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Backspace" && !q) {
                    setSelectedMalls(selectedMalls.slice(0, -1))
                  }
                }}
                placeholder="Type to search..."
              />
            </li>
          </ul>
          <Combobox.Options
            className={classNames([
              "scrollbars-hidden z-50 max-h-64 overflow-y-scroll",
              "rounded border border-gray-400 py-2 shadow-inner",
              "dark:border-gray-60 bg-white dark:bg-gray-800 dark:text-gray-300",
              "absolute inset-x-0 top-full",
            ])}
          >
            {filteredMalls.map((mall) => (
              <Combobox.Option key={mall.id} value={mall} as={Fragment}>
                {({ active, selected }) => (
                  <li
                    className={classNames([
                      "cursor-pointer py-2 px-4",
                      active && "bg-picton-blue/30",
                      selected && "bg-picton-blue/70",
                    ])}
                  >
                    {mall.name}
                    {selected && <i className="fa fa-check" />}
                  </li>
                )}
              </Combobox.Option>
            ))}
            {q && !filteredMalls.length && (
              <li className="p-2">
                No results for <strong>{q}</strong>.
              </li>
            )}
            {!q && !filteredMalls.length && <li className="p-2">Type to search...</li>}
          </Combobox.Options>
        </div>
      </Combobox>
      {selectedMalls.length > 0 &&
        (method == "destroy" ? (
          <a
            data-confirm="Are you sure?"
            title="Destroy"
            className="button mt-6 pt-3"
            rel="nofollow"
            data-method="delete"
            href={url}
          >
            {buttonText}
          </a>
        ) : (
          <form method="post" action={url}>
            <br />
            <input type="submit" className="button pt-3" value={buttonText} />
            <input
              type="hidden"
              name="authenticity_token"
              value={ReactOnRails.authenticityHeaders({})["X-CSRF-Token"]}
            />
          </form>
        ))}
    </>
  )
}

type Mall = {
  id: number | string
  name: string
}
