import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  HtmlHTMLAttributes,
  useRef,
} from "react"
import { Reorder } from "framer-motion"
import { v4 as uuid } from "uuid"
import Modal, { TextInput, Select, HorizontalGroup, LabeledInput, Details, FieldSet } from "./Modal"
import dayjs from "dayjs"
import { DatePicker, LocalDateTime } from "./DatePicker"
import ImageLibrary from "./ImageLibrary"
import { isEqual } from "lodash-es"
import classNames from "classnames"

const DeckContext = createContext({})
export const useDeckContext = () => useContext(DeckContext)

export default function ({ cards = [], imageTags = [] }) {
  const [data, setData] = useState([])
  const onChange = (cardsState) => {
    setData(cardsState)
  }
  return (
    <>
      {data
        .filter((s) => (s._destroy && s.id) || !s._destroy)
        .map(({ key, status, images = [], ...c }) => (
          <React.Fragment key={key}>
            <input type="hidden" name={`deck[cards_attributes][]`} value={JSON.stringify(c)} />
          </React.Fragment>
        ))}
      <CardEditor cards={cards} imageTags={imageTags} onChange={onChange} />
    </>
  )
}

export function CardEditor({ cards = [], imageTags = [], onChange = (x) => {} }) {
  const [cardsState, setCardsState] = useState(cards.map((card) => ({ ...card, key: uuid() })))
  const [editingCard, setEditingCard] = useState(null)
  const editCard = (card) => setEditingCard(card)

  useEffect(() => {
    onChange(
      cardsState.map((c, i) => {
        return { ...c, position: i + 1 }
      }),
    )
  }, [cardsState])

  return (
    <DeckContext.Provider
      value={{
        cards: cardsState,
        setCards: setCardsState,
        imageTags,
      }}
    >
      <div className="flex flex-col items-stretch space-y-2">
        <button
          className="self-start px-2 font-semibold"
          onClick={(e) => {
            e.preventDefault()
            const newCard = {
              key: uuid(),
              status: "draft",
              base_status: 0,
              display_at: null,
              expired_at: null,
              title: null,
              subtitle: null,
              body: null,
              url: null,
              image_ids: [],
            }
            setCardsState([newCard, ...cardsState])
            // setEditingCard(newCard)
          }}
        >
          Add new card +
        </button>
        <div>
          <Reorder.Group
            axis="x"
            values={cardsState}
            onReorder={setCardsState}
            className="flex gap-2 overflow-x-auto pb-4"
          >
            {cardsState.map((card) => (
              <Reorder.Item
                key={card.key}
                value={card}
                className={classNames([
                  "flex-none",
                  "w-[66.6%] sm:w-[40%] md:w-[28%] lg:w-[22%]",
                  "min-w-[250px]",
                  ,
                ])}
              >
                <CardPlaceholder
                  key={card.key}
                  card={card}
                  editCard={editCard}
                  cards={cardsState}
                  setCards={setCardsState}
                />
              </Reorder.Item>
            ))}
          </Reorder.Group>
        </div>
      </div>
      {editingCard && <EditorDialog card={editingCard} setEditingCard={setEditingCard} />}
    </DeckContext.Provider>
  )
}

const CardPlaceholder = ({ card, editCard, cards, setCards }) => {
  const [status, setStatus] = useState(card.status)
  useEffect(() => {
    let newStatus = card.status
    if (card.base_status.toString() === "0") newStatus = "draft"
    if (card.base_status.toString() === "1") newStatus = "live"
    if (card.base_status.toString() === "1" && dayjs(card.display_at).isAfter(dayjs()))
      newStatus = "scheduled"
    if (
      card.base_status.toString() === "1" &&
      card.expired_at &&
      dayjs(card.expired_at).isBefore(dayjs())
    )
      newStatus = "expired"

    setStatus(newStatus)
  }, [card])

  if (card._destroy) return null

  return (
    <div className="relative border border-blue-500 p-2">
      <div className="flex flex-col justify-between gap-2">
        <div className="flex items-center justify-between gap-3">
          <button
            onClick={(e) => {
              e.preventDefault()
              editCard(card)
            }}
            className="self-grow truncate capitalize"
          >
            {card.title || `card ${card.id || "new"}`}
          </button>
          <div className="flex items-center justify-between gap-3">
            <button
              onClick={(e) => {
                e.preventDefault()
                editCard(card)
              }}
            >
              <i className="fa fa-edit" />
            </button>
            <button
              onClick={(e) => {
                e.preventDefault()
                if (confirm("you sure?")) {
                  let temp = [...cards]
                  temp.push(temp.splice(temp.indexOf(temp.find((c) => c.key === card.key)), 1)[0])
                  setCards(temp.map((s) => (s.key === card.key ? { ...s, _destroy: "1" } : s)))
                }
              }}
            >
              <i className="fa fa-trash" />
            </button>
          </div>
        </div>
        <div className="relative bg-slate-300 pb-[100%]">
          <div
            className="absolute inset-0 flex w-full items-center justify-center"
            style={{
              background: `url(
                "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 50 50'><line x1='0' y1='0' x2='50' y2='50' stroke='white' stroke-width='1' fill='none'/><line x1='0' y1='50' x2='50' y2='0' stroke='white' stroke-width='1' fill='none'/></svg>"
              )`,
            }}
          >
            {card.images?.length > 0 && (
              <img
                src={card.images[0].url}
                draggable="false"
                className="h-full max-h-full w-full max-w-full object-cover"
              />
            )}
          </div>
        </div>
        <button
          onClick={(e) => {
            e.preventDefault()
            editCard(card)
          }}
          className="flex flex-col items-start"
        >
          <span className={`status-badge ${status}`}>{status}</span>
          <div className="flex flex-col items-start">
            <div>
              <span>Display: </span>
              <span className="font-normal">
                {card.display_at ? <LocalDateTime date={card.display_at} /> : "unpublished"}
              </span>
            </div>
            <div>
              <span>Expires: </span>
              <span className="font-normal">
                {card.expired_at ? <LocalDateTime date={card.expired_at} /> : "never"}
              </span>
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}

const EditorDialog = ({ card, setEditingCard }) => {
  const [internalCard, setInternalCard] = useState(card)
  const { cards, setCards } = useDeckContext()
  const [closeWarning, setCloseWarning] = useState(null)
  const warning = "Discard changes?"

  useEffect(() => {
    isEqual(card, internalCard) ? setCloseWarning(null) : setCloseWarning(warning)
  }, [internalCard])

  const [status, setStatus] = useState(null)
  useEffect(() => {
    console.log("card", card)
    if (!card) return
    let newStatus = card.status
    if (card.base_status.toString() === "0") newStatus = "draft"
    if (card.base_status.toString() === "1") newStatus = "live"
    if (card.base_status.toString() === "1" && dayjs(card.display_at).isAfter(dayjs()))
      newStatus = "scheduled"
    if (
      card.base_status.toString() === "1" &&
      card.expired_at &&
      dayjs(card.expired_at).isBefore(dayjs())
    )
      newStatus = "expired"

    setStatus(newStatus)
    setInternalCard(card)
    setCloseWarning(null)
  }, [card])

  if (!internalCard) return null

  return (
    <Modal
      title="Edit Card"
      open={!!card}
      onClose={() => setEditingCard(null)}
      warnOnClose={closeWarning}
      onConfirmClick={() => {
        const errors = validate(internalCard)

        if (Object.keys(errors).length) {
          alert(
            `Please fill in all required fields.\n${Object.values(errors)
              .map((e) => `• ${e}`)
              .join("\n")}`,
          )
          return false
        } else {
          setCards(cards.map((s) => (s.key === internalCard.key ? internalCard : s)))
          setEditingCard(null)
          return true
        }
      }}
    >
      <div className="flex h-full flex-col items-stretch gap-2">
        <div className="flex-none">
          <div className="flex flex-col overflow-y-scroll">
            <div className="flex items-center justify-between gap-4 py-2"></div>
            <div className="flex flex-col overflow-y-scroll">
              <div className="flex items-center justify-between gap-4 py-2">
                <LabeledInput label="Status">
                  <Select
                    options={[
                      { value: "0", label: "Draft" },
                      { value: "1", label: "Published" },
                    ]}
                    value={internalCard.base_status}
                    onChange={(e) => {
                      const display_at =
                        e.currentTarget.value === "1"
                          ? new Date().toISOString()
                          : internalCard.display_at
                      setInternalCard({
                        ...internalCard,
                        base_status: e.currentTarget.value,
                        display_at,
                      })
                    }}
                  />
                </LabeledInput>
              </div>
              <HorizontalGroup>
                <LabeledInput label="Display At">
                  <DatePicker
                    selected={internalCard?.display_at}
                    onChange={(date) =>
                      setInternalCard({
                        ...internalCard,
                        display_at: date,
                        base_status: date ? "1" : "0",
                      })
                    }
                  />
                </LabeledInput>
                <LabeledInput label="Expires At">
                  <DatePicker
                    selected={internalCard?.expired_at}
                    onChange={(date) =>
                      setInternalCard({
                        ...internalCard,
                        expired_at: date,
                      })
                    }
                    placeholder="Never expire"
                  />
                </LabeledInput>
              </HorizontalGroup>
            </div>
          </div>
        </div>
        <div className="min-h-0 flex-auto overflow-y-scroll">
          <CardForm card={internalCard} setCard={setInternalCard} />
        </div>
      </div>
    </Modal>
  )
}

const CardForm = ({ card, setCard }) => {
  return (
    <div className="flex flex-col gap-4">
      <ImageLibrary
        images={card?.images}
        onChange={(images) => {
          setCard((prev) => ({ ...prev, images, image_ids: images.map((i) => i.id) }))
        }}
        ownerType="card"
        thumbnailSize="lg"
        limit={1}
      />
      <FieldSet legend="Messaging">
        <LabeledInput label="Title">
          <TextInput
            defaultValue={card?.title}
            onChange={(e) => setCard({ ...card, title: e.currentTarget.value })}
            className="w-full rounded border border-gray-400 p-2 placeholder-gray-500 placeholder-opacity-60 shadow-inner focus:border-gray-800 focus:placeholder-opacity-0 focus:outline-none disabled:bg-gray-300 disabled:italic disabled:text-gray-600 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-300 dark:disabled:bg-gray-600 dark:disabled:text-gray-400"
          />
        </LabeledInput>
        <LabeledInput label="supertitle">
          <TextInput
            defaultValue={card?.supertitle}
            onChange={(e) => setCard({ ...card, supertitle: e.currentTarget.value })}
            className="w-full rounded border border-gray-400 p-2 placeholder-gray-500 placeholder-opacity-60 shadow-inner focus:border-gray-800 focus:placeholder-opacity-0 focus:outline-none disabled:bg-gray-300 disabled:italic disabled:text-gray-600 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-300 dark:disabled:bg-gray-600 dark:disabled:text-gray-400"
          />
        </LabeledInput>
        <LabeledInput label="Subtitle">
          <TextInput
            defaultValue={card?.subtitle}
            onChange={(e) => setCard({ ...card, subtitle: e.currentTarget.value })}
            className="w-full rounded border border-gray-400 p-2 placeholder-gray-500 placeholder-opacity-60 shadow-inner focus:border-gray-800 focus:placeholder-opacity-0 focus:outline-none disabled:bg-gray-300 disabled:italic disabled:text-gray-600 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-300 dark:disabled:bg-gray-600 dark:disabled:text-gray-400"
          />
        </LabeledInput>
        <LabeledInput label="body">
          <TextArea
            defaultValue={card?.body}
            onChange={(e) => setCard({ ...card, body: e.currentTarget.value })}
            className="w-full rounded border border-gray-400 p-2 placeholder-gray-500 placeholder-opacity-60 shadow-inner focus:border-gray-800 focus:placeholder-opacity-0 focus:outline-none disabled:bg-gray-300 disabled:italic disabled:text-gray-600 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-300 dark:disabled:bg-gray-600 dark:disabled:text-gray-400"
          />
        </LabeledInput>
      </FieldSet>
      <FieldSet legend="navigation">
        <LabeledInput label="call to action">
          <TextInput
            defaultValue={card?.call_to_action}
            onChange={(e) => setCard({ ...card, call_to_action: e.currentTarget.value })}
            className="w-full rounded border border-gray-400 p-2 placeholder-gray-500 placeholder-opacity-60 shadow-inner focus:border-gray-800 focus:placeholder-opacity-0 focus:outline-none disabled:bg-gray-300 disabled:italic disabled:text-gray-600 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-300 dark:disabled:bg-gray-600 dark:disabled:text-gray-400"
          />
        </LabeledInput>
        <LabeledInput label="url">
          <TextInput
            defaultValue={card?.url}
            onChange={(e) => setCard({ ...card, url: e.currentTarget.value })}
            className="w-full rounded border border-gray-400 p-2 placeholder-gray-500 placeholder-opacity-60 shadow-inner focus:border-gray-800 focus:placeholder-opacity-0 focus:outline-none disabled:bg-gray-300 disabled:italic disabled:text-gray-600 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-300 dark:disabled:bg-gray-600 dark:disabled:text-gray-400"
          />
        </LabeledInput>
      </FieldSet>
    </div>
  )
}

const TextArea = ({
  onChange,
  maxRows = 3,
  ...props
}: { maxRows?: number } & HtmlHTMLAttributes<HTMLTextAreaElement>) => {
  const [rows, setRows] = useState(1)
  const updateRows = (value: string = "") => {
    setRows(Math.max(1, Math.min(maxRows, value.split("\n").length)))
  }
  useEffect(() => {
    updateRows(String(props.defaultValue))
  }, [props.defaultValue])

  return (
    <textarea
      onChange={(e) => {
        updateRows(e.target.value)
        onChange?.(e)
      }}
      rows={rows}
      {...props}
    />
  )
}

const validate = (card) => {
  const errors = {}

  if (!card?.title) {
    errors["title"] = "Title is required."
  }
  if (!card?.images?.length) {
    errors["images"] = "Image is required."
  }
  if (!card?.supertitle) {
    errors["supertitle"] = "Supertitle is required."
  }
  if (!card?.subtitle) {
    errors["subtitle"] = "Subtitle is required."
  }
  if (!card?.body) {
    errors["body"] = "Body is required."
  }
  if (!card?.url) {
    errors["url"] = "Url is required."
  }
  if (!card?.call_to_action) {
    errors["call_to_action"] = "Call To Action is required."
  }

  return errors
}
